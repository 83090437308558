import { apiList } from './../../../actionNames/index';
import { createDefaultAction } from './../../../helper/default-action';
import { defaultState } from './../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer'

const obj = defaultState
const apiDetail = apiList.internal.admin.voucher.lockUnlockVoucher;
export default function LockUnlocVoucherkReducer(store={...obj}, action) {
    const state = Object.assign({},store)
    const actionName = apiDetail.actionName
    return defaultReducer(actionName,action,state)
}

export const lockUnlockVoucherData = (reqData:any) => async dispatch =>  {
    return createDefaultAction(apiDetail,dispatch,reqData, "POST");
}