import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.distributionLog.saveDistributionLog;

export interface SaveDistributionLog {
  id?: any
  publishDateBs: string
  totalVacancy: number
  localBodyId?: number;
  ministryOfficeId?: number;
  distributionType: string
  fiscalYear: string
  masterCurriId: number
  registrationNo: string
  percentageDistributionCountDtoList: PercentageDistributionCountDtoList[]
}

export interface PercentageDistributionCountDtoList {
  id: any
  quotaMapperId: number
  allocatedVacancy: number
  allocatedVacancyFromPrevious: number
}

export default function saveDistributionLogReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const saveDistributionLogCard =
  (requestData: SaveDistributionLog) => async (dispatch) => {
    return createDefaultAction(apiDetails, dispatch, requestData, "POST");
  };
