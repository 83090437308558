import { apiList } from "../../../../../actionNames";
import { createDefaultAction } from "../../../../../helper/default-action";
import { defaultReducer } from "../../../../../helper/default-reducer";
import { defaultState } from "../../../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.deleteCategoryIdPhase4;

export interface ISaveCategory {
    id?: number
    categoryNameEn: string,
    categoryNameNp: string
}

export default function deleteCategoryIdPhase4Reducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const deleteCategoryIdPhase4 = (categoryId: string
) => async dispatch => {
    const newApiDetails = { ...apiDetails }
    newApiDetails.controllerName = newApiDetails.controllerName.replace("{id}", categoryId)
    return createDefaultAction(newApiDetails, dispatch, null, "DELETE");
};