import { formDataPhase3 } from "core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase3/ConfigurationPhase3DFormula";
import { apiList } from "../../../../../actionNames";
import { createDefaultAction } from "../../../../../helper/default-action";
import { defaultReducer } from "../../../../../helper/default-reducer";
import { defaultState } from "../../../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.saveFormulaPhase3;



export default function saveFormulaPhase3Reducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const saveFormulaPhase3 = (data: formDataPhase3
) => async dispatch => {
  return createDefaultAction(apiDetails, dispatch, data, "POST");
};