import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { Dispatch } from "redux";

const obj = defaultState;
const apiDetails = apiList.external.vacancy.checkpayment;
export default function checkPaymentReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;
  return defaultReducer(actionName, action, state);
}

export const checkPaymentAction =
  (advtCode: string): any =>
    async (dispatch: Dispatch) => {
      const newApiDetails = { ...apiDetails };
      newApiDetails.controllerName = newApiDetails.controllerName.replace(
        "{advtcode}",
        advtCode
      );
      const resData = await createDefaultAction(
        newApiDetails,
        dispatch,
        null,
        "GET"
      );
      return resData;
    };
