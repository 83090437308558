export const apiList = {
  local: {
    i18n: {
      controllerName: "",
      actionName: "I18N",
    },
    changePasswordModal: {
      controllerName: "",
      actionName: "CHANGE_PASSWORD_MODAL",
    },
    myActiveTab: {
      controllerName: "",
      actionName: "MY_PROFILE_TAB_MODAL",
    },
    informationModal: {
      controllerName: "",
      actionName: "INFORMANTION_MODAL",
    },
  },
  oauth: {
    login: {
      controllerName: "login",
      actionName: "LOGIN",
    },
    init: {
      controllerName: "/oauth/privileges/get/init",
      actionName: "GET_PRIVILEGES",
    },
    refreshToken: {
      controllerName: "refresh",
      actionName: "REFRESH_TOKEN",
    },
  },
  external: {
    vacancy: {
      getVacancyList: {
        controllerName: "psc/user/get/all/vacancy",
        actionName: "GET_VACANCY_LIST",
      },
      filterVacancyList: {
        controllerName: "psc/user/search/vacancy",
        actionName: "FILTER_VACANCY_LIST",
      },
      getVacancyDetail: {
        controllerName: "psc/user/get/advertisement",
        actionName: "GET_VACANCY_DETAIL",
      },
      applyVaccancy: {
        controllerName: "psc/saveCandidateApplicationInfo",
        actionName: "APPLY_VACCANCY",
      },
      getQualification: {
        controllerName: "psc/getQualificationData/{id}",
        actionName: "GET_QUALIFICATION",
      },
      getPaymentValue: {
        controllerName: "psc/user/get/payment",
        actionName: "GET_PAYMENT_VALUE",
      },
      paymentLogin: {
        controllerName: "https://newrmistest.fcgo.gov.np:9443/rmis/api/login",
        actionName: "PAYMENT_LOGIN",
      },
      bankList: {
        controllerName: "/psc/user/rmis/get/bank/list",
        actionName: "BANK_LIST",
      },
      getEbpCode: {
        controllerName: "/psc/user/rmis/get/Ebp-code",
        actionName: "GET_EBP_CODE",
      },
      checkpayment: {
        controllerName:
          "/psc/user/rmis/check-remaining/amount?advertisementCode={advtcode}",
        actionName: "CHECK_PAYMENT",
      },
      onlinePayment: {
        controllerName: "https://rajaswa.fcgo.gov.np/govpay/start-payment",
        actionName: "ONLINE_PAYMENT",
      },
      getVoucherwithEbpCode: {
        controllerName: "/psc/user/rmis/get/voucher",
        actionName: "GET_VOUCHER_WITH_EBP_CODE",
      },
      getApplication: {
        controllerName: "psc/user/get/application",
        actionName: "GET_APPLICATION",
      },
      uploadVoucher: {
        controllerName: "psc/user/upload/voucher",
        actionName: "UPLOAD_VOUCHER",
      },
      getExamCenter: {
        controllerName: "psc/getExamCenterList/{masterId}/{reqOfficeId}",
        actionName: "GET_EXAM_CENTER",
      },
      filterMyApplication: {
        controllerName: "psc/user/filter/application",
        actionName: "FILTER_MY_APPLICATION",
      },
      getApplicationDetail: {
        controllerName: "psc/user/get/application/details",
        actionName: "GET_APPLICATION_DETAIL",
      },
      reApplyVaccancy: {
        controllerName: "/psc/user/double/payment",
        actionName: "REAPPLY_VACCANCY",
      },
      mandatoryCheck: {
        controllerName: "/psc/user/check-docs",
        actionName: "MANDATORY_CHECK",
      },
      bigyapanAgreement: {
        controllerName: "/psc/user/bigyapan-agreement/get?bigyapanCodeId={bigyapanCodeId}",
        actionName: "BIGYAPAN_AGREEMENT"
      },
      getApprovedCardData: {
        controllerName: "/psc/user/get-approved/application",
        actionName: "GET_APPROVEDCARD_DATA",
      },
      getVoucherDownloadDetail: {
        controllerName: "/psc/user/get-voucher-details?advtCode={advtCode}",
        actionName: "GET_VOUCHER_DOWNLOAD_DETAIL",
      },
      getAllNotice: {
        controllerName: "/psc/notice-token/getAll",
        actionName: "GET_ALL_NOTICE",
      },
      getAdmitCardDetailByAdvtCode: {
        controllerName: "/psc/admit-card/generate",
        actionName: "GET_ADMIT_CARD_DETAIL_BY_ADVT_CODE",
      },
      downloadBase64ForAdmitCard: {
        controllerName: "psc/user/download/base64?masterId={masterId}",
        actionName: "DOWNLOAD_BASE64_TYPE_ID",
      },
    },
    admitcardDetails: {
      controllerName: "/psc/admit-card/generate",
      actionName: "ADMIT_CARD_DETAILS",
    },
    getNoticeWithoutLogin: {
      controllerName: "/psc/notice/getAll",
      actionName: "GET_ALL_NOTICE_WITHOUT_LOGIN",
    },
  },
  internal: {
    admin: {
      applicationStatus: {
        controllerName: "/internal/appliedCandidate/countCandidate",
        actionName: "GET_APPLIED_CANDIDATE",
      },
      getTotalRegisterUserList: {
        controllerName: "/internal/get/register-users",
        actionName: "GET_TOTAL_REGISTER_USER_LIST",
      },
      addAdminClass: {
        controllerName: "/internal/class/save",
        actionName: "ADD_CLASS",
      },
      getClass: {
        controllerName: "/internal/class/findAll",
        actionName: "GET_CLASS",
      },
      getClassById: {
        controllerName: "/internal/class/findById/:{id}",
        actionName: "GET_CLASS_BY_ID",
      },
      addPost: {
        controllerName: "/internal/post/save",
        actionName: "ADD_POST",
      },
      getPost: {
        controllerName: "/internal/post/findAll",
        actionName: "GET_POST",
      },
      getPostById: {
        controllerName: "/internal/post/findById/{id}",
        actionName: "GET_POST_BY_ID",
      },
      getGroupSubGroupById: {
        controllerName:
          "/internal/masterDataCurriculum/filterPostByClassAndMasterDivId?classId={classId}&masterDivId={masterId}",
        actionName: "GET_GROUP_SUBGROUP_BY_ID",
      },
      addSubject: {
        controllerName: "/internal/subject/save",
        actionName: "ADD_SUBJECT",
      },
      getSubject: {
        controllerName: "/internal/subject/findAll",
        actionName: "GET_SUBJECT",
      },
      addMasterData: {
        controllerName: "/internal/masterDivision/save",
        actionName: "ADD_MASTER_DIVISION",
      },
      getMasterData: {
        controllerName: "/internal/masterDivision/findByDivisionType/{id}",
        actionName: "GET_MASTER_DATA_BY_ID",
      },
      saverequisition: {
        controllerName: "/internal/requisition/save",
        actionName: "SAVE-REQUISITION",
      },
      autoTotalVacancy: {
        controllerName: "/internal/requisition/get-total-vacancy/v2",
        actionName: "AUTO_TOTAL_VACANCY",
      },
      listrequisition: {
        controllerName: "/internal/requisition/findAllForReqApproval",
        actionName: "LIST-REQUISITION",
      },
      listrequisitiondetails: {
        controllerName:
          "/internal/requisition/findDetailsByReqNo/{requisitionid}",
        actionName: "LIST-REQUISITION-DETAILS",
      },
      approverequisition: {
        controllerName: "internal/requisition/approveRequisition",
        actionName: "APPROVE-REQUISITION",
      },
      ageLimit: {
        findAllClassAndService: {
          controllerName: "/internal/agelimit/find/all/class/service",
          actionName: "FIND_ALL_AGE",
        },
        findById: {
          controllerName:
            "/internal/agelimit/find/by?classId={classId}&serviceId={serviceId}",
          actionName: "FIND_BY_ID",
        },
        saveAgeLimit: {
          controllerName: "/internal/agelimit/save",
          actionName: "SAVE_AGE_LIMIT",
        },
        deleteAge: {
          controllerName: "/internal/agelimit/delete/{id}",
          actionName: "DELETE_AGE",
        },
      },
      percentageDistribution: {
        getAllPercentageDistribution: {
          controllerName: "internal/percentageDistribution/findAll",
          actionName: "GET_ALL_PERCENTAGE_DISTRIBUTION",
        },
        generateAdvNumber: {
          controllerName: "internal/percentageDistribution/generate",
          actionName: "GENERATE_ADVERTISEMENT_NUMBER",
        },
        savePercentageDistribution: {
          controllerName: "internal/percentageDistribution/save",
          actionName: "SAVE_PERCENTAGE_DISTRIBUTION",
        },
        findDistributionByRequisitionId: {
          controllerName:
            "internal/percentageDistribution/findByRequisitionId/{requisitionId}",
          actionName: "FIND_DISTRI_BY_REQUISITION_ID",
        },
        findDistributionForApproval: {
          controllerName: "internal/percentageDistribution/findAllForApproval",
          actionName: "FIND_REQUISITION_FOR_DISTRI_APPROVAL",
        },
        approveOrReject: {
          controllerName: "internal/percentageDistribution/validate",
          actionName: "APPROVE_OR_REJECT_DISTRIBUTION",
        },
        autoGenerateDistributionQuota: {
          controllerName:
            "internal/percentageDistribution/auto-generate-quota/v2/{requisitionId}",
          actionName: "AUTO_GENERATE_QUOTA_BY_REQUISITION_ID",
        },
      },
      userBlock: {
        getBlockedList: {
          controllerName: "oauth/block-user/list",
          actionName: "GET_BLOCKED_LIST",
        },
        saveBlockUser: {
          controllerName: "oauth/block-user/save",
          actionName: "SAVE_BLOCK_USER",
        },
        getUserDetail: {
          controllerName: "oauth/block-user/users-list",
          actionName: "GET_USER_DETAIL",
        },
        unBlock: {
          controllerName: "oauth/block-user/unblock/{name}",
          actionName: "UN_BLOCK_USER",
        },
      },
      examScheduling: {
        getRequisitionForExamScheduling: {
          controllerName: "internal/examScheduling/findAll",
          actionName: "GET_REQUISITION_FOR_EXAMSCHEDULING",
        },
        skipExamScheduling: {
          controllerName: "internal/examScheduling/skip/{id}",
          actionName: "SKIP_EXAM_SCHEDULING",
        },
        examScheduling: {
          controllerName:
            "internal/examScheduling/getPaperDetails?requisitionId={id}&sameOrDifferentDay={numId}",
          actionName: "EXAM_SCHEDULING",
        },
        saveExamScheduling: {
          controllerName: "internal/examScheduling/save",
          actionName: "SAVE_EXAM_SCHEDULING",
        },
        examReviewForAllApproval: {
          controllerName: "internal/examScheduling/findAllForApproval",
          actionName: "FIND_ALL_APPROVAL",
        },
        findByRequisitionId: {
          controllerName: "internal/examScheduling/findByRequisitionId/{id}",
          actionName: "FIND_BY_REQUISITION_ID",
        },
        reviewExamScheduling: {
          controllerName: "internal/examScheduling/reviewExamScheduling",
          actionName: "REVIEW_EXAM_SCHEDULING",
        },
      },
      room: {
        getRoom: {
          controllerName: "internal/room/find-all",
          actionName: "GET_ROOM",
        },
        deleteRoom: {
          controllerName: "internal/room/delete/{id}",
          actionName: "DELETE_ROOM",
        },
        saveRoom: {
          controllerName: "internal/room/save",
          actionName: "SAVE_ROOM",
        },
        downloadSampleRoomExcel: {
          controllerName: "/internal/room/download-room-allocation-excel",
          actionName: "DOWNLOAD_SAMPLE_ROOM_EXCEL",
        },
        uploadRoomExcel: {
          controllerName: "/internal/room/upload-room-allocation-excel",
          actionName: "UPLOAD_DOCUMENT",
        },
      },
      assignExamCenter: {
        getAdvertisementList: {
          controllerName: "internal/examCenterAllocation/getAdvtList",
          actionName: "GET_ADVERTISEMENT_LIST",
        },
        getAssignedExamCenterById: {
          controllerName:
            "internal/examCenterAllocation/getExamCenterAllocationDetailByAdvtCodeId/{id}",
          actionName: "GET_ASSIGNED_EXAM_CENTER_BY_ID",
        },
        saveTemporarly: {
          controllerName: "internal/examCenterAllocation/temp/save",
          actionName: "SAVE_TEMPORARLY",
        },
        finalSave: {
          controllerName: "internal/examCenterAllocation/final/save",
          actionName: "FINAL_SAVE",
        },
        reverseAllExamCenterById: {
          controllerName: "internal/examCenterAllocation/revert/{id}",
          actionName: "REVERSE_ALL_EXAM_CENTER_BY_ID",
        },
        getCandidateRoom: {
          controllerName: "internal/candidate-room/allocation/get/list",
          actionName: "GET_CANDIDATE_ROOM",
        },
        getAllocatedCandidateList: {
          controllerName:
            "internal/candidate-room/allocation/get/allocated/candidate",
          actionName: "GET_ALLOCATED_CANDIDATE_LIST",
        },
        deleteAllocatedCandidate: {
          controllerName:
            "/internal/candidate-room/allocation/delete/allocated/candidate?candidateAllocationId={candidateAllocationId}&paperNumber={paperNumber}",
          actionName: "DELETE_ALLOCATED_CANDIDATE",
        },
        getGeneratedAllocateList: {
          controllerName: "internal/candidate-room/allocation/get/list",
          actionName: "GET_GENERATED_ALLOCATED_LIST",
        },
        saveCandidateRoom: {
          controllerName: "internal/candidate-room/allocation/save",
          actionName: "SAVE_CANDIDATE_ROOM",
        },
        archiveCandidate: {
          controllerName:
            "internal/candidate-room/allocation/delete/bulk/allocated-candidate",
          actionName: "ARCHIVE_CANDIDATE",
        },
        saveAllocatedCandidate: {
          controllerName: "internal/candidate-room/allocation/save",
          actionName: "SAVE_ALLOCATED_CANDIDATE",
        },
      },
      selectiveSubject: {
        getSelectivePaperList: {
          controllerName:
            "/internal/publishAdvertisement/get-selective-paper/{id}",
          actionName: "GET_SELECTIVE_PAPER_LIST",
        },
        saveSelectivePaperDetail: {
          controllerName:
            "internal/publishAdvertisement/save-all-selective-paper",
          actionName: "SAVE_SELECTIVE_PAPER_DETAIL",
        },
      },
      addDistrict: {
        controllerName: "/internal/district/save",
        actionName: "ADD_DISTRICT",
      },
      addMotherTongue: {
        controllerName: "/internal/motherTongue/save",
        actionName: "ADD_MOTHER_TONGUE",
      },
      getgroupListById: {
        controllerName: "/internal/masterDivision/findByParentId/{id}",
        actionName: "GET_GROUP_LIST_ID",
      },
      allQuota: {
        controllerName: "/internal/quota/findAll",
        actionName: "GET_ALL_QUOTA",
      },
      saveQuota: {
        controllerName: "/internal/quota/save",
        actionName: "SAVE_QUOTA",
      },
      allExamCenter: {
        controllerName: "/internal/examCenter/findAll",
        actionName: "ALL_EXAM_CENTER",
      },
      filterOrGetAllExamCenter: {
        controllerName:
          "/internal/room/get?examCenterId={examCenterId}&subExamCenterId={subExamCenter}&status={status}",
        actionName: "FILTER_OR_GET_ALL_EXAM_CENTER",
      },
      allInvigilator: {
        controllerName: "/internal/invigilator/findAll",
        actionName: "ALL_INVIGILATOR",
      },
      allInvigilatorPost: {
        controllerName: "internal/invigilatorPost/findAll",
        actionName: "ALL_INVIGILATOR_POST",
      },
      allocateInvigilator: {
        controllerName: "/internal/invigilator/allocate/exam-center",
        actionName: "ALLOCATE_INVIGILATOR",
      },
      ExamCenterById: {
        controllerName: "/internal/examCenter/findById/{id}",
        actionName: "EXAM_CENTER_BY_ID",
      },
      InvigilatorById: {
        controllerName: "/internal/invigilatorPost/findById/{id}",
        actionName: "INVIGILATOR_BY_ID",
      },
      InvigilatorPostById: {
        controllerName: "internal/invigilatorPost/findById/{id}",
        actionName: "INVIGILATOR_POST_BY_ID",
      },
      saveExamCenter: {
        controllerName: "/internal/examCenter/save",
        actionName: "SAVE_EXAM_CENTER",
      },
      findAndDeleteExamCenter: {
        controllerName: "/internal/examCenter/removeAll/backup",
        actionName: "DELETE_EXAM_CENTER_ALL",
      },
      fetchChildExamCenterList: {
        controllerName:
          "/internal/examCenter/get/exam-center/by/parent-id/{id}",
        actionName: "FETCH_CHILD_EXAM_CENTER_LIST",
      },
      removeExamCenter: {
        controllerName: "/internal/examCenterAllocation/revert/single/{id}",
        actionName: "REMOVE_EXAM_CENTER",
      },
      downloadSampleExamCenter: {
        controllerName: "/internal/examCenter/download-excel",
        actionName: "DOWNLOAD_SAMPLE_EXAM_CENTER",
      },
      uploadExcelExamCenter: {
        controllerName: "/internal/examCenter/excel-exam-center-upload",
        actionName: "UPLOAD_DOCUMENT",
      },
      saveInvigilator: {
        controllerName: "/internal/invigilator/save",
        actionName: "SAVE_INVIGILATOR",
      },
      disableInvigilator: {
        controllerName: "/internal/invigilator/archive-all",
        actionName: "DISABLE_INVIGILATOR",
      },
      archiveInvigilator: {
        controllerName: "/internal/invigilator/archive",
        actionName: "ARCHIVE_INVIGILATOR",
      },
      filterInvigilator: {
        controllerName:
          "/internal/invigilator/get/filter/list?status={status}&type={type}&governmentPost={governmentPost}",
        actionName: "FILTER_INVIGILATOR",
      },
      deletAllocatedInvigilator: {
        controllerName: "/internal/invigilator/allocated/archive/{id}",
        actionName: "DELETE_ALLOCATED_INVIGILATOR",
      },
      deleteAllInvigilator: {
        controllerName: "/internal/invigilator/remove-all",
        actionName: "DELETE_ALL_INVIGILATOR",
      },
      fetchInvigilatorList: {
        controllerName:
          "/internal/invigilator/get/list?advetisementCodeId={advetisementCodeId}&codeName={codeName}",
        actionName: "FETCH_INVIGILATOR_LIST",
      },
      saveInvigilatorPost: {
        controllerName: "internal/invigilatorPost/save",
        actionName: "SAVE_INVIGILATOR_POST",
      },
      autoGenerate: {
        controllerName: "internal/invigilator/get-auto-allocated",
        actionName: "GET_AUTO_ALLOCATED",
      },
      getLanguage: {
        controllerName: "/internal/motherTongue/findAll",
        actionName: "GET_LANGUAGE",
      },
      getMasterDivision: {
        controllerName: "/internal/masterDivision/findAll",
        actionName: "GET_MASTER_DIVISION",
      },
      addMasterConfig: {
        controllerName: "/internal/masterDataCurriculum/save",
        actionName: "ADD_MASTER_DATA",
      },
      downloadMasterData: {
        controllerName: "/internal/masterDataCurriculum/download-master-data",
        actionName: "AUTHFILE",
      },
      getMasterConfig: {
        controllerName: "/internal/masterDataCurriculum/findAll",
        actionName: "GET_MASTER_DATA",
      },
      combinePost: {
        getListByClassAndPostType: {
          controllerName:
            "/internal/masterDataCurriculum/get/list?classId={classId}&postType={postType}&isCombined={isCombined}",
          actionName: "GET_LIST_BY_CLASS_AND_POST_TYPE",
        },
        generateCombination: {
          controllerName:
            "/internal/masterdata-curriculum/combination/generate",
          actionName: "GENERATE_COMBINATION",
        },
        saveCombination: {
          controllerName: "/internal/masterdata-curriculum/combination/save",
          actionName: "SAVE_COMBINATION",
        },
      },
      getDistrict: {
        controllerName: "/internal/district/findAll",
        actionName: "GET_DISTRICT",
      },
      getDistrictById: {
        controllerName: "/internal/district/findBy/:{id}",
        actionName: "GET_DISTRICT_BY_ID",
      },
      getCaste: {
        controllerName: "/internal/caste/findAll",
        actionName: "GET_CASTE",
      },
      addCaste: {
        controllerName: "/internal/caste/save",
        actionName: "ADD_CASTE",
      },
      saveSingleSamuhabarga: {
        controllerName: "/internal/castegroup-doc-exception/save",
        actionName: "SAVE_SINGLE_SAMUHABARGA",
      },
      getAllSamuhaBarga: {
        controllerName:
          "/internal/castegroup-doc-exception/find-all?casteGroupId&lastNameNp",
        actionName: "GET_ALL_SAMUHA_BARGA_BY_CASTEGROUP_AND_LASTNAME",
      },
      saveMultipleSamuhabarga: {
        controllerName: "/internal/castegroup-doc-exception/save-multiple",
        actionName: "SAVE_MULTIPLE_SAMUHABARGA",
      },
      getCasteGroup: {
        controllerName: "/internal/casteGroup/findAll",
        actionName: "GET_CASTE",
      },
      addCasteGroup: {
        controllerName: "/internal/casteGroup/save",
        actionName: "ADD_CASTE",
      },
      addReligion: {
        controllerName: "/internal/save/religion",
        actionName: "ADD_RELIGION",
      },
      getReligion: {
        controllerName: "/internal/get/all/religion",
        actionName: "GET_RELIGION",
      },
      addDisability: {
        controllerName: "/internal/save/physically/disabled",
        actionName: "ADD_DISABILITY",
      },
      getDisability: {
        controllerName: "/internal/get/physically/disabled",
        actionName: "GET_DISABILITY",
      },
      addLocalBodyType: {
        controllerName: "/internal/save/localbody/type",
        actionName: "ADD_LOCALBODY_TYPE",
      },
      getLocalBodyType: {
        controllerName: "/internal/get/all/local/body",
        actionName: "GET_ALL_LOCALBODY_TYPE",
      },
      addLocalBody: {
        controllerName: "/internal/save/localbody",
        actionName: "ADD_LOCALBODY",
      },
      getLocalBodyById: {
        controllerName: "/internal/local/body/find/{id}",
        actionName: "GET_LOCALBODY_ID",
      },
      deleteData: {
        controllerName: "/internal/generic/delete/{type}/{id}",
        actionName: "DELETE_DATA",
      },
      getProvince: {
        controllerName: "/internal/province/findAll",
        actionName: "GET_PROVINCE",
      },
      listForAdvertisement: {
        controllerName: "/internal/publishAdvertisement/findAll",
        actionName: "LIST_FOR_ADVERTISEMENT",
      },
      advertisementDataById: {
        controllerName:
          "/internal/publishAdvertisement/advtNoDetailsById/{adId}",
        actionName: "ADVERTISEMENT_DATA_BY_ID",
      },
      publishAdvertisement: {
        controllerName: "/internal/publishAdvertisement/publish",
        actionName: "PUBLISH_ADVERTISEMENT",
      },
      rejectAdvertisement: {
        controllerName: "/internal/publishAdvertisement/remove/pending",
        actionName: "REJECT_ADVERTISEMENT",
      },
      updateAdvertisementNumber: {
        controllerName: "/internal/publishAdvertisement/update/advtNumber",
        actionName: "UPDATE_ADVERTISEMENT_NUMBER",
      },
      getAdvtCodeForEbpCode: {
        controllerName:
          "internal/payment/get/advertisementcode?masterId={masterId}",
        actionName: "GET_ADVT_CODE_FOR_EBP_CODE",
      },
      updatePayment: {
        controllerName: "/internal/payment/rmis/daily-transaction/log/{date}",
        actionName: "UPDATE_PAYMENT",
      },
      replaceEbpCode: {
        controllerName:
          "internal/payment/update/ebp-code?oldEbpCode={oldEbpCode}&newEbpCode={newEbpCode}",
        actionName: "REPLACE_EBP_CODE",
      },
      verifyPayment: {
        controllerName: "/internal/payment/check/ebp-code/payment/{ebpCode}",
        actionName: "VERIFY_PAYMENT",
      },
      verifyEbpCode: {
        controllerName: "/internal/payment/check/ebp-code/generated/{ebpCode}",
        actionName: "VERIFY_EBP_CODE",
      },
      getEbpCodeByMasterIdAndAdvtcode: {
        controllerName:
          "internal/payment/get/ebpCode?masterId={masterId}&advtCode={advtCode}",
        actionName: "GET_EBP_CODE_BY_MASTER_ID_AND_ADVT_CODE",
      },
      addPayment: {
        controllerName: "/internal/payment/save",
        actionName: "ADD_PAYMENT",
      },
      getPayment: {
        controllerName: "/internal/payment/get",
        actionName: "GET_PAYMENT",
      },
      listPublishedAdvertisement: {
        controllerName: "/internal/publishAdvertisement/getActiveAdvts",
        actionName: "GET_PUBLISHED_ADVERTISEMENT_LIST",
      },
      schedulePublishedAdvertisement: {
        controllerName: "/internal/publishAdvertisement/find/scheduled",
        actionName: "GET_PUBLISHED_ADVERTISEMENT_SCHEDULE",
      },
      saveEducationBoard: {
        controllerName: "/internal/saveBoard",
        actionName: "SAVE_EDUCATION_BOARD",
      },
      saveEducationFaculty: {
        controllerName: "/internal/saveFaculty",
        actionName: "SAVE_EDUCATION_FACULTY",
      },
      organization: {
        saveOrganization: {
          controllerName: "/internal/organization/add",
          actionName: "SAVE_ORGANIZATION",
        },
        getOrganization: {
          controllerName: "/internal/organization/findAll",
          actionName: "GET_ORGANIZATION",
        },
      },
      ministryConfiguration: {
        getAllMinistry: {
          controllerName: "/internal/ministry-office/get/{status}",
          actionName: "GET_ALL_MINISTRY",
        },
        saveMinistryByDistrict: {
          controllerName: "/internal/ministry-office/save",
          actionName: "SAVE_MINISTRY_BY_DISTRICT",
        },
        getMinistryByDistrict: {
          controllerName:
            "/internal/ministry-office/get/districtid/{districtId}",
          actionName: "GET_MINISTRY_BY_DISTRICT",
        },
      },
      notice: {
        postNotice: {
          controllerName: "/internal/notice-token/save",
          actionName: "SAVE_NOTICE",
        },
        getAllNotice: {
          controllerName: "/internal/notice-token/getAll",
          actionName: "GET_ALL_NOTICE",
        },
        deleteNotice: {
          controllerName: "/internal/generic/delete/notice/{id}",
          actionName: "DELETE_NOTICE",
        },
      },
      saveEducationLevel: {
        controllerName: "/internal/saveLevel",
        actionName: "SAVE_EDUCATION_LEVEL",
      },
      searchByMasterId: {
        controllerName: "/internal/search/by/{masterId}",
        actionName: "SEARCH_BY_MASTER_ID",
      },
      unlockProfileByMasterId: {
        controllerName: "/internal/edit/unlock",
        actionName: "UNLOCK_PROFILE_BY_MASTER_ID",
      },
      listLockedProfile: {
        controllerName: "/internal/find/all/unlocked",
        actionName: "LIST_LOCKED_PROFILE",
      },
      lockUserProfile: {
        controllerName: "/internal/edit/lock",
        actionName: "LOCK_USER_PROFILE",
      },
      profileSearch: {
        controllerName: "/internal/search-candidate",
        actionName: "USER_PROFILE_SEARCH",
      },
      voucher: {
        getVoucher: {
          controllerName: "/internal/get-voucher-details/{masterId}",
          actionName: "GET_VOUCHER_DATA",
        },
        lockUnlockVoucher: {
          controllerName: "/internal/lock-unlock-voucher",
          actionName: "LOCK_UNLOCK_VOUCHER",
        },
        getVoucherEditData: {
          controllerName: "/internal/get-voucher-edit-data",
          actionName: "GET_VOUCHER_EDIT_DATA",
        },
        lockUserVoucher: {
          controllerName: "/internal/edit/lock",
          actionName: "LOCK_USER_VOUCHER",
        },
      },
      postExaminationConfigure: {
        getCandidateList: {
          controllerName: "/internal/candidate-exam/get-candidate-list",
          actionName: "GET_CANDIDATE_LIST",
        },
        postExaminationConfiguration: {
          controllerName: "/internal/post-exam/configuration",
          actionName: "POST_EXAMINATION_CONFIGURATION",
        },
        getExamCenterList: {
          controllerName: "/internal/candidate-attendance/exam-center",
          actionName: "GET_CANDIDATE_EXAM_CENTER",
        },
        saveCandidateExam: {
          controllerName: "/internal/candidate-exam/save",
          actionName: "SAVE_CANDIDATE_EXAM",
        },
        getCandidateAttendance: {
          controllerName: "/internal/candidate-attendance/get-candidate-list",
          actionName: "GET_CANDIDATE_ATTENDANCE",
        },
        saveCandidateAttendance: {
          controllerName: "/internal/candidate-attendance/save",
          actionName: "SAVE_CANDIDATE_ATTENDANCE",
        },
        saveExcelCandidateAttendance: {
          controllerName: "/internal/candidate-attendance/save-excel",
          actionName: "UPLOAD_DOCUMENT",
        },
        saveCandidateExamExcel: {
          controllerName: "/internal/candidate-exam/save-excel",
          actionName: "UPLOAD_DOCUMENT",
        },
        viewReport: {
          controllerName: "/internal/candidate-exam/get-report",
          actionName: "VIEW_REPORT",
        },
        downloadReport: {
          controllerName: "/internal/candidate-exam/export-report",
          actionName: "AUTHFILE",
        },
        downloadAttendanceReport: {
          controllerName: "/internal/candidate-attendance/export-report",
          actionName: "AUTHFILE",
        },
        getAttendenceList: {
          controllerName: "/internal/candidate-attendance/get-report",
          actionName: "GET_ATTENDANCE_LIST",
        },
      },
      scrutiny: {
        getIndividualScrutinyData: {
          controllerName: "internal/scrutinyAllocation/allocate",
          actionName: "POST_INDIVIDUAL_SCRUTINY",
        },
        getscrutiny: {
          // controllerName: "/internal/scrutiny/getAllocatedAdvertisements/",
          controllerName:
            "/internal/scrutiny/getAllocatedAdvertisements/?pageLength={pageLength}&pageNumber={pageNumber}",
          actionName: "GET_ALL_SCRUTINY",
        },
        getActiveUser: {
          controllerName: "/internal/scrutiny/get-available-users",
          actionName: "GET_AVAILABLE_USERS",
        },
        getIndividualUserDetails: {
          controllerName: "/internal/view-individual-report/{id}",
          actionName: "GET_INDIVIDUAL_USER_LIST",
        },
        getFiscalYear: {
          controllerName: "/internal/get-fiscalyear-list",
          actionName: "GET_FISCAL_YEAR_LIST",
        },
        reportByPromotion: {
          controllerName:
            "/internal/report/promotion/percentage-distribution/{fiscalYear}",
          actionName: "REPORT_BY_PROMOTION",
        },
        downloadPromotionReport: {
          controllerName:
            "/internal/report/download/percentage-distribution/promotion-report/{fiscalYear}",
          actionName: "DOWNLOAD_PROMOTION_REPORT",
        },
        percentageDistributionByLocalLevel: {
          controllerName:
            "/internal/report/percentage-distribution/local-level/{fiscalYear}",
          actionName: "PERCENTAGE_DISTRIBUTION_BY_LOCAL_LEVEL",
        },
        PercentageDistributionbyLocalLevelDownload: {
          controllerName:
            "/internal/report/download/percentage-distribution/local-level/report/{fiscalYear}",
          actionName: "PERCENTAGE_DISTRIBUTION_BY_LOCAL_LEVEL_DOWNLOAD",
        },
        reportBasedOnAdvtAndfiscalyear: {
          controllerName:
            "/internal/report/percentage-distribution/advt-number/{fiscalYear}",
          actionName: "REPORT_BASED_ON_ADVT_AND_FISCAL_YEAR",
        },
        downloadReportBasedOnAdvtAndfiscalyear: {
          controllerName:
            "/internal/report/download/percentage-distribution/advertisement-number/report/{fiscalYear}",
          actionName: "DOWNLOAD_REPORT_BASED_ON_ADVT_AND_FISCAL_YEAR",
        },
        getscrutinyAllocation: {
          controllerName: "/internal/scrutinyAllocation/findAllAdvt",
          actionName: "GET_SCRUTINY_ALLOCATION_LIST",
        },
        scrutinyAllocationDetails: {
          controllerName:
            "/internal/scrutinyAllocation/findScrutinyAllocationDetail",
          actionName: "SCRUTINY_ALLOCATION_Details",
        },
        getScrutinyData: {
          controllerName: "internal/get-scrutiny-data",
          actionName: "GET_SCRUTINY_DATA",
        },
        downloadScrutinyData: {
          controllerName: "/internal/download-scrutiny-data",
          actionName: "DOWNLOAD_SCRUTINY_DATA",
        },
        getAdvertisementCodeList: {
          controllerName: "/internal/get-advtcode-list",
          actionName: "GET_ADVERTISEMENT_LIST",
        },
        allocateForScrutiny: {
          controllerName: "/internal/scrutinyAllocation/allocateMultiple",
          actionName: "ALLOCATION_FOR_SCRUTINY",
        },
        allocateCandiateByAdvertisementCode: {
          controllerName: "/internal/scrutiny/getAllocatedCandidates",
          actionName: "ALLOCATE_CANDIATE_BY_ADVERTISEMET_CODE",
        },
        getUserDetilsByAdvertisement: {
          controllerName: "/internal/scrutiny/getStatByAdvertisementCode",
          actionName: "USE_DETAILS_BY_ADVERTISEMET_CODE",
        },
        allocatedCandiateDetails: {
          controllerName: "/internal/scrutiny/getCandidateDetails",
          actionName: "ALLOCATE_CANDIATE_Details",
        },
        formValidateStatus: {
          controllerName: "/internal/scrutiny/validateCandidate",
          actionName: "FORM_VALIDATION_STATUS",
        },
        getAllocatedCandidatesWithPagination: {
          controllerName:
            "/internal/scrutiny/getAllocatedCandidatesWithPagination",
          actionName: "GET_ALLOCATED_CANDIDATE_WITH_PAGINATION",
        },
        removeOrRestoreAdvtNo: {
          controllerName: "/internal/scrutiny/removeOrRestoreAdvtNo",
          actionName: "REMOVE_OR_RESTORE_ADV_NO",
        },
        reallocatingUser: {
          controllerName: "/internal/scrutinyAllocation/reallocate",
          actionName: "REALLOCATING_USER",
        },
        scrutinyAllcoationFilter: {
          controllerName:
            "/internal/scrutinyAllocation/findAllAdvt?advtCodeFilter={advtCode}&pageSize={pageLength}&pageNumber={pageNumber}",
          actionName: "SCRUTINY_ALLOCATION_FILTER",
        },
        scrutinyAllcoationWithoutFilter: {
          controllerName:
            "/internal/scrutinyAllocation/findAllAdvt?pageSize={pageLength}&pageNumber={pageNumber}",
          actionName: "SCRUTINY_ALLOCATION_FILTER",
        },
        scrutinyAllcoationWithFiscalYear: {
          controllerName:
            "/internal/scrutinyAllocation/findAllAdvt?pageSize={pageLength}&pageNumber={pageNumber}&fiscalYear={fiscalYear}",
          actionName: "SCRUTINY_ALLOCATION_FISCAL_YEAR_FILTER",
        },
        scrutinyFilter: {
          controllerName:
            "/internal/scrutiny/getAllocatedAdvertisements/?pageLength={pageLength}&pageNumber={pageNumber}&advtFilter={advtCode}",
          actionName: "SCRUTINY_FILTER",
        },
        downloadAdvertisementData: {
          controllerName:
            "/internal/report/download/bigyapan-application/count",
          actionName: "AUTHFILE",
        },
        countAdvertisementApplication: {
          controllerName: "/internal/report/bigyapan-application/count",
          actionName: "COUNT_ADVERTISEMENT_APPLICATION",
        },
        getAdvertisementCodeParamsList: {
          controllerName: "/internal/get-advtcode-list?fisyear={fiscal}",
          actionName: "GET_ADVERTISEMENT_LIST",
        },
        getAdvtCodeByFiscalYear: {
          controllerName:
            "/internal/invigilator/get/bigwapan-code?fiscalYear={fiscalYear}",
          actionName: "GET_ADVT_CODE_BY_FISCAL_YEAR",
        },
        reportByGender: {
          genderOnly: {
            getGenderOnlyReport: {
              controllerName:
                "/internal/scrutiny-application-report/gender?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_GENDER_ONLY-REPORT",
            },
            getDownloadGenderOnlyReport: {
              controllerName: "/internal/scrutiny-application-report/gender/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_GENDER_ONLY_REPORT"
            }
          },
          areaReport: {
            getAreaReport: {
              controllerName: "/internal/scrutiny-application-report/area?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_AREA_REPORT"
            },
            getDownloadAreaReport: {
              controllerName: "/internal/scrutiny-application-report/area/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_AREA_REPORT"
            }
          },
          provinceReport: {
            getProvinceReport: {
              controllerName: "/internal/scrutiny-application-report/province?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_PROVINCE_REPORT"
            },
            getDownloadProvinceReport: {
              controllerName: "/internal/scrutiny-application-report/province/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_PROVINCE_REPORT"
            }
          },
          religionReport: {
            getReligionReport: {
              controllerName: "/internal/scrutiny-application-report/religion?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_RELIGION_REPORT"
            },
            getDownloadReligionReport: {
              controllerName: "/internal/scrutiny-application-report/religion/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_RELIGION_REPORT"
            }
          },
          districtReport: {
            getDistrictReport: {
              controllerName: "/internal/scrutiny-application-report/district?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DISTRICT_REPORT"
            },
            getDownloadDistrictReport: {
              controllerName: "/internal/scrutiny-application-report/district/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_DISTRICT_REPORT"
            }
          },
          employmentStatusReport: {
            getEmploymentStatusReport: {
              controllerName: "/internal/scrutiny-application-report/employment-status?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_EMPLOYEMENT_STATUS_REPORT"
            },
            getDownloadEmploymentStatusReport: {
              controllerName: "/internal/scrutiny-application-report/employment-status/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_EMPLOYMENT_STATUS_REPORT"
            }
          },
          physicallyChallengedReport: {
            getPhysicallyChallengedReport: {
              controllerName: "/internal/scrutiny-application-report/physically-challenged?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_PHYSICALLY_CHALLENGED_REPORT"
            },
            getDownloadPhysicallyChallengedReport: {
              controllerName: "/internal/scrutiny-application-report/physically-challenged/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_PHYSICALLY_CHALLENGED_REPORT"
            }
          },
          casteReport: {
            getCasteReport: {
              controllerName: "/internal/scrutiny-application-report/caste?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_CASTE_REPORT"
            },
            getDownloadCasteReport: {
              controllerName: "/internal/scrutiny-application-report/caste/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_CASTE_REPORT"
            }
          },
          quotaReport: {
            getQuotaReport: {
              controllerName: "/internal/scrutiny-application-report/quota?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_QUOTA_REPORT"
            },
            getDownloadQuotaReport: {
              controllerName: "/internal/scrutiny-application-report/quota/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_QUOTA_REPORT"
            }
          },
          maritalStatusReport: {
            getMaritalStatusReport: {
              controllerName: "/internal/scrutiny-application-report/marital-status?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_MARITAL_STATUS_REPORT"
            },
            getDownloadMaritalStatusReport: {
              controllerName: "/internal/scrutiny-application-report/marital-status/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_MARITAL_STATUS_REPORT"
            }
          },
          motherTongueReport: {
            getMotherTongueReport: {
              controllerName: "/internal/scrutiny-application-report/mother-tongue?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_MOTHER_TONGUE_REPORT"
            },
            getDownloadMotherTongueReport: {
              controllerName: "/internal/scrutiny-application-report/mother-tongue/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_MOTHER_TONGUE_REPORT"
            }
          },
          educationQualificationReport: {
            getEducationQualificationReport: {
              controllerName: "/internal/scrutiny-application-report/education-qualification?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_EDUCATIONAL_QUALIFICATION_REPORT"
            },
            getDownloadEducationQualificationReport: {
              controllerName: "/internal/scrutiny-application-report/education-qualification/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_EDUCATION_QUALIFICATION_REPORT"
            }
          },
          ClassReport: {
            getClassReport: {
              controllerName: "/internal/scrutiny-application-report/class?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_CLASS_REPORT"
            },
            getDownloadClassReport: {
              controllerName: "/internal/scrutiny-application-report/class/download?advtCode={advtCode}&status={status}&fiscalYear={fiscalYear}",
              actionName: "GET_DOWNLOAD_CLASS_REPORT"
            }
          }
        },
      },
      vacancyDetails: {
        controllerName: "/internal/dashboard/get/all/vacancy",
        actionName: "ADMIN_VACANCY_LIST",
      },
      dashboardCard: {
        controllerName: "/internal/dashboard/get/stats",
        actionName: "ADMIN_DASHBOARD_CARD",
      },
      generateExcelSheet: {
        controllerName: "/internal/generate-format",
        actionName: "GENERATE_EXCEEL_SHEET",
      },
      finilizeScrutiny: {
        controllerName: "/internal/scrutinyAllocation/finalise",
        actionName: "FINILIZE_SCRUTINY",
      },
      percentageDistributionConfig: {
        saveDistributionPhaseOne: {
          controllerName: "/internal/distribution-{phasetype}/formula/save",
          actionName: "SAVE_DISTRIBUTION_PHASE_ONE",
        },
        saveDistributionPhaseOneAll: {
          controllerName: "/internal/distribution-{phasetype}/formula/save-all",
          actionName: "SAVE_DISTRIBUTION_PHASE_ONE_ALL",
        },
        getAllFormulaHeaders: {
          controllerName: "/internal/distribution-{phasetype}/formula/headers",
          actionName: "GET_ALL_FORMULA_HEADERS",
        },
        findAllFormulaByClassIdAndServiceId: {
          controllerName: "/internal/distribution-{phasetype}/formula/find-all",
          actionName: "FIND_ALL_FORMULA_BY_CLASSID_AND_SERVICEID",
        },
        deletePhaseOneFormulaById: {
          controllerName:
            "/internal/distribution-{phasetype}/formula/delete/{formulaId}",
          actionName: "DELETE_PHASE_ONE_FORMULA_BY_ID",
        },
        getPhaseOneSavedClassAndService: {
          controllerName:
            "/internal/distribution-phase-one/formula/get-saved-class-and-service?distributionType={distributionType}",
          actionName: "GET_PHASE_ONE_SAVED_CLASS_AND_SERVICES",
        },
        getAllAsignedHeadersPhaseOne: {
          controllerName:
            "/internal/distribution-{phasetype}/table/assigned-headers",
          actionName: "GET_ALL_ASSIGNED_HEADERS_PHASEONE",
        },
        saveDistributionPhaseOneTable: {
          controllerName: "/internal/distribution-{phasetype}/table/save",
          actionName: "SAVE_DISTRIBUTION_PHASE_ONE_TABLE",
        },
        findAllSavedTableDataByClassIdAndServiceId: {
          controllerName: "/internal/distribution-{phasetype}/table/find-all",
          actionName:
            "FIND_ALL_SAVED_TABLE_DATA_BY_CLASSID_AND_SERVICEID_PHASE_ONE",
        },
        deletePhaseOneTableDataById: {
          controllerName:
            "/internal/distribution-{phasetype}/table/delete/{tableId}",
          actionName: "DELETE_PHASE_ONE_FORMULA_BY_ID",
        },
        fetchAssignHeaderForPhases: {
          controllerName: "/internal/distribution-{phasetype}/table/assigned-headers",
          actionName: "FETCH_ASSIGN_HEADER_FOR_PHASES"
        },
        getVacancyLimitPhase1ByClassService: {
          controllerName:
            "/internal/distribution-phase-one/vacancy-limit/get?classId={classId}&serviceId={serviceId}&postId={postId}&distributionType={distributionType}",
          actionName:
            "GET_VACANCY_LIMIT_PHASE1_BY_CLASSID_AND_SERVICEID_PHASE_ONE",
        },
        saveVacancyLimitPhaseOne: {
          controllerName: "/internal/distribution-phase-one/vacancy-limit/save",
          actionName: "SAVE_VACANCY_LIMIT_PHASE_ONE",
        },
        phaseAutoGenerateTableValue: {
          controllerName: "/internal/distribution-{phasetype}/auto-generate",
          actionName: "PHASE_AUTO_GENERATE_TABLE_VALUE",
        },
        findallFormulaPhase3: {
          controllerName: "/internal/distribution-phase-three/formula/find-all?distributionType={distributionType}",
          actionName: "FIND_ALL_FORMULA_PHASE_THREE",
        },
        getAllHeaderFormulaPhase3: {
          controllerName: "/internal/distribution-phase-three/formula/headers",
          actionName: "GET_ALL_HEADER_FORMULA_PHASE_THREE",
        },
        saveFormulaPhase3: {
          controllerName: "/internal/distribution-phase-three/formula/save",
          actionName: "SAVE_FORMULA_PHASE_THREE",
        },
        saveCategoryPhase3: {
          controllerName: "/internal/distribution-phase-three/category/save",
          actionName: "SAVE_CATEGORY_PHASE_THREE"
        },
        fetchCategoryListPhase3: {
          controllerName: "/internal/distribution-phase-three/fetch/category",
          actionName: "FETCH_CATEGORY_PHASE_THREE"
        },
        deleteCategoryIdPhase3: {
          controllerName: "/internal/distribution-phase-three/delete/{id}",
          actionName: "DELETE_CATEGORY_ID_PHASE_THREE"
        },
        getAllHeaderFormulaPhase4: {
          controllerName: "/internal/distribution-phase-four/formula/headers",
          actionName: "GET_ALL_HEADER_FORMULA_PHASE_FOUR",
        },
        findallFormulaPhase4: {
          controllerName: "/internal/distribution-phase-four/formula/find-all?distributionType={distributionType}",
          actionName: "FIND_ALL_FORMULA_PHASE_FOUR",
        },
        saveCategoryPhase4: {
          controllerName: "/internal/distribution-phase-four/category/save",
          actionName: "SAVE_CATEGORY_PHASE_FOUR"
        },
        fetchCategoryListPhase4: {
          controllerName: "/internal/distribution-phase-four/fetch/category",
          actionName: "FETCH_CATEGORY_PHASE_FOUR"
        },
        deleteCategoryIdPhase4: {
          controllerName: "/internal/distribution-phase-four/delete/{id}",
          actionName: "DELETE_CATEGORY_ID_PHASE_FOUR"
        },
        saveFormulaPhase4: {
          controllerName: "/internal/distribution-phase-four/formula/save",
          actionName: "SAVE_FORMULA_PHASE_FOUR",
        },
        deleteFormulaPhase4: {
          controllerName:
            "/internal/distribution-phase-four/formula/delete/{id}",
          actionName: "DELETE_FORMULA_PHASE_FOUR",
        },
        saveTableValuePhase4: {
          controllerName: "/internal/distribution-phase-four/table/save",
          actionName: "SAVE_TABLE_VALUE_PHASE_FOUR",
        },
        findallTableValuePhase4: {
          controllerName: "/internal/distribution-phase-four/table/find-all?distributionType={distributionType}",
          actionName: "FIND_ALL_TABLE_VALUE_PHASE_FOUR",
        },
        deleteTableValuePhase4: {
          controllerName:
            "/internal/distribution-phase-four/table/delete/{id}",
          actionName: "DELETE_TABLE_VALUE_PHASE_FOUR",
        },
        deleteFormulaPhase3: {
          controllerName:
            "/internal/distribution-phase-three/formula/delete/{id}",
          actionName: "DELETE_FORMULA_PHASE_THREE",
        },

        findallTableValuePhase3: {
          controllerName: "/internal/distribution-phase-three/table/find-all?distributionType={distributionType}",
          actionName: "FIND_ALL_TABLE_VALUE_PHASE_THREE",
        },
        saveTableValuePhase3: {
          controllerName: "/internal/distribution-phase-three/table/save",
          actionName: "SAVE_TABLE_VALUE_PHASE_THREE",
        },
        deleteTableValuePhase3: {
          controllerName:
            "/internal/distribution-phase-three/table/delete/{id}",
          actionName: "DELETE_TABLE_VALUE_PHASE_THREE",
        },
        findAllPhaseSavedTableDataAlternate: {
          controllerName:
            "/internal/distribution-{phasetype}/table/find-all/alternate",
          actionName: "FIND_ALL_PHASE_SAVED_TABLE_DATA_ALTERNATE",
        },
      },
    },
    finalSelection: {
      uploadFinalSelection: {
        controllerName: "/internal/final-selection/excel-upload",
        actionName: "UPLOAD_DOCUMENT",
      },
      getFinalSelection: {
        controllerName: "/internal/final-selection?advtCode={advtCode}",
        actionName: "GET_FINAL_SELECTION",
      },
    },
    distributionLog: {
      saveDistributionLog: {
        controllerName: "/internal/percentage-distribution-log/v2/save",
        actionName: "SAVE_PERCENTAGE_DISTRIBUTION",
      },
      getDistributionLog: {
        controllerName:
          "/internal/percentage-distribution-log/v2/get?curriculumId={id}",
        actionName: "GET_PERCENTAGE_DISTRIBUTION",
      },
      deleteDistributionLog: {
        controllerName: "/internal/percentage-distribution-log/v2/delete?percentageDistributionLogId={percentageDistributionLogId}",
        actionName: "DELETE_DISTRIBUTION_LOG",
      },
      findAllPercentageDistribution: {
        controllerName: "/internal/percentage/distribution/findAll",
        actionName: "FIND_ALL_PERCENTAGE_DISTRIBUTION",
      },
      autoGenerateDistributionLog: {
        controllerName: "/internal/percentage-distribution-log/v2/auto-generate",
        actionName: "AUTO_GENERATE_DISTRIBUTION_LOG",
      },
    },
    downloadAdmitCard: {
      controllerName: "/internal/generate",
      actionName: "DOWNLOAD_ADMIT_CARD",
    },
    DownloadAdmitCardInternal: {
      controllerName: "/psc/user/download/base64?masterId={masterId}",
      actionName: "DOWNLOAD_ADMIT_CARD_INTERNAL",
    },
    adminEbpCode: {
      controllerName:
        "/internal/payment/open/ebp-code?studentMasterId={masterId}&advertisementCode={advtCode}",
      actionName: "ADMIN_EBP_CODE",
    },
    getStudentAdvertisementCodeList: {
      controllerName:
        "/internal/payment/get/advertisement-code?studentMasterId={masterId}",
      actionName: "GET_STUDENT_ADVERTISEMENT_CODE_LIST",
    },
    advertisementList: {
      controllerName: "/internal/bigyapan/list",
      actionName: "ADVERTISEMENT_LIST",
    },
    setStatus: {
      controllerName: "/internal/bigyapan/set-status",
      actionName: "SET_ADVERTISEMENT_LIST",
    },
    allDistrict: {
      controllerName: "internal/district/findAll",
      actionName: "GET_ALL_DISTRICTS",
    },
    allProvince: {
      controllerName: "internal/province/findAll",
      actionName: "GET_ALL_PROVINCE",
    },
    getDistrictByProvinceId: {
      controllerName: "internal/district/findByProvinceId/{provinceId}",
      actionName: "GET_DISTRICT_BY_PROVINCE_ID",
    },

    allCastes: {
      controllerName: "internal/caste/findAll",
      actionName: "GET_ALL_CASTES",
    },
    allMotherTongues: {
      controllerName: "internal/motherTongue/findAll",
      actionName: "GET_ALL_MOTHERTONGUE",
    },
    allReligion: {
      controllerName: "internal/get/all/religion",
      actionName: "GET_ALL_RELIGION",
    },
    allLocalBodyType: {
      controllerName: "internal/get/all/local/body",
      actionName: "GET_ALL_LOCAL_BODY_TYPE",
    },
    getLocalBodyByDistrictId: {
      controllerName: "internal/local/body/find/",
      actionName: "FIND_LOCALBODY_BY_DISTRICTID",
    },
    allPhysicallyDisabledClass: {
      controllerName: "internal/get/physically/disabled",
      actionName: "GET_ALL_PHYCALLYDISABLED_CLASS",
    },
    allSamuhaBarga: {
      controllerName: "internal/casteGroup/findAll",
      actionName: "GET_ALL_SAMUHA_BARGA",
    },
    academicQualification: {
      getAllMasterDataCurriculum: {
        controllerName: "internal/masterDataCurriculum/findAll",
        actionName: "GET_ALL_MASTER_CURRICULUM",
      },
      saveQualification: {
        controllerName: "internal/masterQualification/save",
        actionName: "SAVE_ACADEMIC_QUALIFICATION",
      },
      deleteQualification: {
        controllerName: "internal/masterQualification/deleteById/{id}",
        actionName: "DELETE_QUALIFICATION",
      },
      getQualificationForView: {
        controllerName:
          "internal/masterQualification/findByMasterId/{masterId}",
        actionName: "GET_QUALIFICATION",
      },
      filterByClassId: {
        controllerName: "internal/masterDataCurriculum/findByClassId/{classId}",
        actionName: "FILTER_CURRICULUM_BY_CLASS_ID",
      },
      filterByPostId: {
        controllerName: "internal/masterDataCurriculum/findByPostId/{postId}",
        actionName: "FILTER_CURRICULUM_BY_POST_ID",
      },
      filterByParams: {
        controllerName:
          "internal/masterDataCurriculum/findByClassMasterDivAndPostId/{params}",
        actionName: "FILTER_CURRICULM_BY_PARAMS",
      },
      getQualificationByCategoryAndMasterId: {
        controllerName:
          "internal/masterQualification/findByMasterIdAndCategory/?masterId={masterId}&category={catId}",
        actionName: "GET_QUALIFICATION_BY_CATEGORY_MASTERID",
      },
    },

    curriculumConfiguration: {
      addPaper: {
        controllerName: "internal/paperForCurriculum/save",
        actionName: "ADD_PAPER",
      },
      filterPaper: {
        controllerName:
          "internal/masterDataCurriculum/findByClassMasterDivAndPostId/?classId={classId}&postId={postId}&masterDivId={masterDivId}",
        actionName: "FILTER_PAPER",
      },
      findPaperConfigById: {
        controllerName: "internal/paperForCurriculum/findByCurriculumId/{id}",
        actionName: "FIND_PAPERCONFIG_BY_ID",
      },
      deletePaper: {
        controllerName: "/internal/paperForCurriculum/deleteById/{id}",
        actionName: "DETETE_PAPER",
      },
      vacancyDistributionGenerate: {
        controllerName:
          "/internal/paperForCurriculum/get/vacancy-distribution/report?advtCode={advtCode}&distributionType={distributionType}",
        actionName: "VACANCY_DISTRIBUTION_GENERATE",
      },
      advtCodeByPostId: {
        controllerName: "/internal/paperForCurriculum/advtcode/{id}",
        actionName: "ADVT_CODE_BY_POST_ID",
      },
    },

    userManagement: {
      getAllInternalUser: {
        controllerName: "/oauth/find/all",
        actionName: "GET_ALL_INTERNAL_USER",
      },
      changeUserListPassword: {
        controllerName: "/oauth/change-password",
        actionName: "CHANGE_USERLIST_PASSWORD",
      },
      addInternalUser: {
        controllerName: "/oauth/add/user",
        actionName: "ADD_INTERNAL_USER",
      },
      getAllRoles: {
        controllerName: "/oauth/roles/get/all",
        actionName: "GET_ALL_ROLE",
      },
      getAllModules: {
        controllerName: "/oauth/modules/find/all/",
        actionName: "GET_ALL_MODULTS",
      },
      addModules: {
        controllerName: "/oauth/modules/create",
        actionName: "ADD_MODULES",
      },
      searchModules: {
        controllerName: "/oauth/modules/find/by/{name}",
        actionName: "SEARCH_MODULES",
      },
      getAllRolesForListing: {
        controllerName: "/oauth/roles/get/all",
        actionName: "GET_ALL_ROLES_FOR_LISTING",
      },
      addRoles: {
        controllerName: "/oauth/roles/add",
        actionName: "ADD_ROLES",
      },
      getRolesById: {
        controllerName: "/oauth/get/roles/{id}",
        actionName: "GET_ROLES_BY_ID",
      },
      addRoleModules: {
        controllerName: "/oauth/modules/roles/add/permission",
        actionName: "ADD_ROLE_MODULES",
      },
      getModulesByRole: {
        controllerName: "/oauth/modules/roles/findby/{id}",
        actionName: "GET_MODULES_BY_ROLE",
      },
      searchUserList: {
        controllerName: "/oauth/find/all?nameFilter={}",
        actionName: "SEARCH_USER_LIST",
      },
      searchUserByRole: {
        controllerName: "/oauth/roles/get/all?roleFilter={}",
        actionName: "SEARCH_USER_ROLE_LIST",
      },
      userDetails: {
        controllerName: "/oauth/get-public-user-details/{masterId}",
        actionName: "USER_DETAILS",
      },
      fullUserDetails: {
        controllerName: "/internal/view-profile/{masterId}",
        actionName: "FULL_USER_DETAILS",
      },
    },
    preExaminationConfig: {
      roomAllocation: {
        finalSave: {
          controllerName: "internal/roomAllocation/final/save",
          actionName: "FINAL_SAVE",
        },
        revert: {
          controllerName: "/internal/roomAllocation/revert/12",
          actionName: "REVERT",
        },
        temporarySave: {
          controllerName: "/internal/roomAllocation/temp/save",
          actionName: "TEMPORARY_SAVE",
        },
        forwardAdvertisement: {
          controllerName: "/internal/roomAllocation/forward/{id}",
          actionName: "FORWARD_ADVERTISEMENT",
        },
        getExistingAllocation: {
          controllerName: "/internal/roomAllocation/getExistingAllocation/{id}",
          actionName: "GET_EXISTING_ALLOCATION",
        },
        getExamCenters: {
          controllerName: "/internal/roomAllocation/getExamCenters/{id}",
          actionName: "GET_EXAM_CENTERS",
        },
        getExamCenterByAdvtCode: {
          controllerName:
            "/internal/candidate-room/allocation/get/exam-center?advertisementCodeId={advtCode}",
          actionName: "GET_EXAM_CENTER_BY_ADVT_CODE",
        },
        seatAllocation: {
          controllerName:
            "/internal/candidate-room/allocation/get/seat-allocation/report",
          actionName: "AUTHFILE",
        },
        seatAllocationByAdvtCode: {
          controllerName:
            "/internal/candidate-room/allocation/get/seat-allocation/report?advertisementCode={advtCode}&examCenterId={examCenter}&roomId",
          actionName: "SEAT_ALLOCATION_REPORT_BY_ADVTCODE_EXAMCENTER",
        },
        generateReportByGateOrRoom: {
          controllerName:
            "/internal/candidate-room/allocation/get/gate-room/prati/report",
          actionName: "AUTHFILE",
        },

        invigilatorAllocationReport: {
          controllerName:
            "/internal/invigilator/allocation/report/generate?advertisementCodeId={advertisementCodeId}&paperNumber={paperNumber}",
          actionName: "AUTHFILE",
        },

        getAdvertisementList: {
          controllerName: "/internal/roomAllocation/getAdvtCodeList",
          actionName: "GET_ADVERTISEMENT_LIST",
        },
        deleteAllocation: {
          controllerName: "/internal/roomAllocation/revert/single/{id}",
          actionName: "DELETE_ALLOCATED_INVIGILATOR",
        },
      },
      invigilatorAllocation: {
        getAdvtsInfo: {
          controllerName: "/internal/invigilator/assignment/get-advts-info",
          actionName: "GET_ADVTS_INFO",
        },
        uploadExcel: {
          controllerName: "/internal/invigilator/upload/excel-sheet",
          actionName: "UPLOAD_DOCUMENT",
        },
        fetchActiveList: {
          controllerName: "/internal/invigilator/get/list/active",
          actionName: "FETCH_ACTIVE_LIST",
        },
        fetchAllInvigilatorPost: {
          controllerName: "/internal/invigilator/post/fetch-all",
          actionName: "FETCH_ALL_INVIGILATOR",
        },
        fetchEmployeeFromPost: {
          controllerName:
            "/internal/invigilator/fetch-max-empcode?postName={postName}",
          actionName: "FETCH_EMPLOYEE_FROM_POST",
        },
        editInvigilator: {
          controllerName: "/internal/invigilator/allocation/edit",
          actionName: "EDIT_INVIGILATOR",
        },
        fetchAllocatedInvilatorList: {
          controllerName:
            "/internal/invigilator/get/allocated-invigilator/list?advetisementCodeId={advetisementCodeId}&examCenterId={examCenterId}&invigilatorId={invigilatorId}",
          actionName: "FETCH_ALLCOATED_INVILATOR_LIST",
        },
        getInvigilatorByAdvtAndExamCenterId: {
          controllerName:
            "/internal/invigilator/get-requested-list?advtCodeId={advetisementCodeId}&examCenterId={examCenterId}",
          actionName: "FILTER_INVILATOR_LIST",
        },
        getAllocatedExamCenter: {
          controllerName:
            "/internal/invigilator/assignment/get-allocated-exam-center/{id}",
          actionName: "GET_ALLOCATED_EXAM_CENTER",
        },
        temporarySave: {
          controllerName: "/internal/invigilator/assignment/temp/save",
          actionName: "TEMPORARY_SAVE",
        },
        revert: {
          controllerName: "/internal/invigilator/assignment/revert/allocation",
          actionName: "REVERT",
        },
        finalSave: {
          controllerName: "/internal/invigilator/assignment/forward/{id}",
          actionName: "FINAL_SAVE",
        },
        getAllocatedInvigilator: {
          controllerName:
            "/internal/invigilator/assignment/get-allocated-invigilator/{id}",
          actionName: "GET_ALLOCATED_INVIGILATOR",
        },
        deleteAllocation: {
          controllerName: "/internalroomAllocation/revert/single/{id}",
          actionName: "DELETE_ALLOCATED_INVIGILATOR",
        },
      },
      report: {
        getAdvtCodeList: {
          controllerName: "/internal/preExamReport/getAdvtCodeList",
          actionName: "GET_ADVT_CODE_LIST",
        },
        getExamCenters: {
          controllerName: "/internal/preExamReport/getExamCenters/{id}",
          actionName: "GET_EXAM_CENTER_DETAILS",
        },
        getGateListValues: {
          controllerName: "/internal/preExamReport/getGateListValues",
          actionName: "GET_GATE_LIST_VALUES",
        },
        getRoomListValues: {
          controllerName: "/internal/preExamReport/getRoomListValues",
          actionName: "GET_ROOM_LIST_VALUES",
        },
        getAttendanceListValues: {
          controllerName: "/internal/preExamReport/getAttendanceListValues",
          actionName: "GET_ATTENDANCE_LIST_VALUES",
        },
        getDetailFromAdvtCode: {
          controllerName:
            "/internal/bigyapan-code/get-detail?advtCode={advtCode}",
          actionName: "GET_DETAIL_FROM_ADVT_CODE",
        },
      },
    },
  },
  psc: {
    requestNewPassword: {
      controllerName: "/psc/user/check/details",
      actionName: "REQUEST_NEW_PASSWORD",
    },
    changeUserNoTokenPassword: {
      controllerName: "/psc/user/password/change",
      actionName: "CHANGE_PASSWORD_NO_TOKEN",
    },
    requestResetPassword: {
      controllerName: "/psc/user/forgot/password",
      actionName: "FORGOT_PASSWORD",
    },
    resetPassword: {
      controllerName: "/psc/user/change/password",
      actionName: "RESET_PASSWORD",
    },
    register: {
      controllerName: "psc/user/save",
      actionName: "REGISTER",
    },
    changePassword: {
      controllerName: "psc/user/reset/password",
      actionName: "CHANGE_PASSWORD",
    },
    getVoucherImage: {
      controllerName: "/psc/file/downloadPublic",
      actionName: "GET_VOUCHER_IMAGE",
    },
    updatePaymentStatus: {
      controllerName: "/psc/user/update-payment-status",
      actionName: "UPDATE_PAYMENT_STATUS",
    },
    // candidate Details
    myProfile: {
      // get if the my profile is editable or not
      getUserCanEditCheck: {
        controllerName: "psc/user/check/edit/option",
        actionName: "CHECK_IF_USER_CAN_EDIT_PROFILE",
      },
      getBasicInfo: {
        controllerName: "psc/user/get/basicinfo",
        actionName: "GET_BASIC_INFO",
      },
      savePersonalDetails: {
        controllerName: "psc/saveCandidateDetails",
        actionName: "SAVE_PERSONAL_DETAILS",
      },
      getPersonalDetails: {
        controllerName: "psc/getCandidateDetails",
        actionName: "GET_PERSONAL_DETAILS",
      },
      saveContact: {
        controllerName: "psc/saveCandidateAddressDetails",
        actionName: "SAVE_CONTACT_DETAILS",
      },
      getContactDetails: {
        controllerName: "psc/getCandidateAddressDetails",
        actionName: "GET_CONTACT_DETAILS",
      },
      saveExtraDetails: {
        controllerName: "psc/saveCandidateExtraDetails",
        actionName: "SAVE_EXTRA_DETAILS",
      },
      getExtraDetails: {
        controllerName: "psc/getCandidateExtraDetails",
        actionName: "GET_EXTRA_DETAILS",
      },
      getSamuhaBarga: {
        controllerName: "psc/getCandidateSamuhaBarga",
        actionName: "GET_SAMUHA_BARGA",
      },
      saveSamuhaBarga: {
        controllerName: "psc/saveCandidateSamuhaBarga",
        actionName: "SAVE_SAMUHA_BARGA",
      },
      deleteSamuhaBarga: {
        controllerName: "psc/user/generic/delete/samuhabarga/{id}",
        actionName: "DELETE_USER_SAMUHABARGA",
      },
    },
  },
  usermgmt: {},
  education: {
    getEducationList: {
      controllerName: "psc/user/get/education",
      actionName: "GET_EDUCATION_LIST",
    },
    saveEduacationlist: {
      controllerName: "psc/user/save/education",
      actionName: "SAVE_EDUCATION_LIST",
    },
    editEducationList: {
      controllerName: "psc/user/save/education",
      actionName: "EDIT_EDUCATION_LIST",
    },
    deleteEducationList: {
      controllerName: "psc/user/generic/delete/education/{id}",
      actionName: "DELETE_EDUCATION_LIST",
    },
    getBoardList: {
      controllerName: "internal/getBoards",
      actionName: "GET_BOARD_LIST",
    },
    getLevelList: {
      controllerName: "internal/getLevels",
      actionName: "GET_LEVEL_LIST",
    },
    getFacultyList: {
      controllerName: "internal/getFaculties",
      actionName: "GET_FACULTY_LIST",
    },
  },
  training: {
    getTrainingList: {
      controllerName: "psc/user/training/get",
      actionName: "GET_TRAINNING_LIST",
    },
    saveTrainingList: {
      controllerName: "psc/user/training/save",
      actionName: "SAVE_TRAINNING_LIST",
    },
    editTrainingList: {
      controllerName: "psc/user/training/save",
      actionName: "EDIT_TRAINING_LIST",
    },
    deleteTrainingList: {
      controllerName: "psc/user/generic/delete/training/{id}",
      actionName: "DELETE_TRAINING_LIST",
    },
  },
  professional: {
    getProfessionalList: {
      controllerName: "psc/user/get/professional/council",
      actionName: "GET_PROFESSIONAL_LIST",
    },
    saveProfessionalList: {
      controllerName: "psc/user/save/professional/council",
      actionName: "SAVE_PROFESSIONAL_LIST",
    },
    professionalCouncilType: {
      controllerName: "internal/get/council/type",
      actionName: "GET_PROFESSIONAL_TYPE",
    },
    editProfessionalList: {
      controllerName: "psc/user/save/professional/council",
      actionName: "EDIT_PROFESSIONAL_LIST",
    },
    deleteProfessionalList: {
      controllerName: "psc/user/generic/delete/professional/{id}",
      actionName: "DELETE_PROFESSIONAL_LIST",
    },
  },
  experience: {
    government: {
      getGovernmentList: {
        controllerName: "psc/user/get/government/experience",
        actionName: "GET_GOVERNMENT_LIST",
      },
      saveGovernmentList: {
        controllerName: "psc/user/save/government/experience",
        actionName: "SAVE_GOVERNMENT_LIST",
      },
      editGovernmentList: {
        controllerName: "psc/user/save/government/experience",
        actionName: "EDIT_GOVERNMENT_LIST",
      },
      deleteGovernmentList: {
        controllerName: "psc/user/generic/delete/government/{id}",
        actionName: "DELETE_GOVERNMENT_LIST",
      },
    },
    nonGovernment: {
      getNonGovernmentList: {
        controllerName: "psc/user/get/non/government/experience",
        actionName: "GET_NON_GOVERNMENT_LIST",
      },
      saveNonGovernmentList: {
        controllerName: "psc/user/save/non/government/experience",
        actionName: "SAVE_NON_GOVERNMENT_LIST",
      },
      editNonGovernmentList: {
        controllerName: "psc/user/save/non/government/experience",
        actionName: "EDIT_NON_GOVERNMENT_LIST",
      },
      deleteNonGovernmentList: {
        controllerName: "psc/user/generic/delete/nongovernment/{id}",
        actionName: "DELETE_NON_GOVERNMENT_LIST",
      },
    },
  },
  documents: {
    documentsUpload: {
      controllerName: "/psc/saveCandidateDoc",
      actionName: "DOCUMENTS_UPLOAD",
    },
    getCandidateDetails: {
      controllerName: "psc/user/download/{type}/{ImageName}",
      actionName: "GET_CANDIDATE_DETAILS",
    },
    getCandidateDocs: {
      controllerName: "/psc/getCandidateDocs",
      actionName: "GET_CANDIDATE_DOCS",
    },
  },
  application: {
    saveApplication: {
      controllerName: "psc/saveCandidateApplicationInfo",
      actionName: "SAVE_CANDIDATE_APPLICATION",
    },
  },
  dashboard: {
    getDashboardDetails: {
      controllerName: "psc/getLiveAdvtData",
      actionName: "GET_DASHBOARD_INFORMATION",
    },
  },
};
