import { combineReducers } from "redux";
import getDistributionLogReducer from './getPercentageDistributionLog'
import saveDistributionLogReducer from './savePercentageDistributionLog'

 const percentageDistributionLog=combineReducers({
  getDistributionLogReducer,
  saveDistributionLogReducer
})


export default  percentageDistributionLog;