import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.preExaminationConfig.invigilatorAllocation.uploadExcel;

export default function uploadExcelInvigilatorReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const uploadExcelInvigilatorList = (document) => (dispatch) => {
    const newApidetails = { ...apiDetails }
    return createDefaultAction(newApidetails, dispatch, document, "POST");
};
