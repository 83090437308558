import { combineReducers } from 'redux';
import getAllMinistryReducer from './getAllMinistry';
import saveMinistryByDistrictReducer from './saveMinistryByDistrict';
import getMinistryByDistrictReducer from './get-ministry-by-district';

const ministryConfigurationReducer = combineReducers({
  getAllMinistryData: getAllMinistryReducer,
  saveMinistryByDistrictData: saveMinistryByDistrictReducer,
  getMinistryByDistrictData: getMinistryByDistrictReducer
})
export default ministryConfigurationReducer