import { combineReducers } from "redux";
import physicallyDisabledClassListReducer from "./allPhycalDisabledClass";
import religionListReducer from "./allReligion";
import allSamuhaBargaListReducer from "./allsamuhaBarga";
import casteListReducer from "./getAllCastes";
import motherTongueListReducer from "./getAllMotherTongues";

const otherReducer = combineReducers({
    casteList: casteListReducer,
    motherTongueList: motherTongueListReducer,
    religionList: religionListReducer,
    physicallyDisabledClassList: physicallyDisabledClassListReducer,
    allSamuhaBargaList: allSamuhaBargaListReducer,
})

export default otherReducer;