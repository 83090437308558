import { apiList } from "store/actionNames";
import { createDefaultAction } from "store/helper/default-action";
import { defaultReducer } from "store/helper/default-reducer";
import { defaultState } from "store/helper/default-state";
import { phaseTypes } from "utils/constants";
import { FindFormulaParams } from "./find-all-formula-by-classId-and-serviceId";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.fetchAssignHeaderForPhases;


export default function fetchAssignHeaderForPhasesReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const fetchAssignHeaderForPhasesAction = (phase: phaseTypes, params?: FindFormulaParams,
) => async dispatch => {
    const newApiDetails = { ...apiDetails };
    return createDefaultAction(newApiDetails, dispatch, null, "GET", false, undefined, params, { phasetype: phase });
};