import { combineReducers } from 'redux';
import getExamCenterReducer from './getExamCenter';
import getFinalSaveReducer from './finalSave';
import getForwardAdvertisementReducer from './forwardAdvertisement';
import getAdvertisementReducer from './getAdvertisement';
import getExistingAllocationReducer from './getExistingAllocation';
import getRevertReducer from './revert';
import getTemporarySaveReducer from './temporarySave';
import getRoomReducer from './getRoom';


const roomAllocationReducer = combineReducers({
    getRevertData: getRevertReducer,
    getFinalSaveData: getFinalSaveReducer,
    getForwardAdvertisementData: getForwardAdvertisementReducer,
    getAdvertisementData: getAdvertisementReducer,
    getExamCenterData: getExamCenterReducer,
    getExistingAllocationData: getExistingAllocationReducer,
    getTemporarySaveData: getTemporarySaveReducer,
    getRoomData: getRoomReducer

})
export default roomAllocationReducer