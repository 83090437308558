import { lazy } from "react";
import { PrivilegeModule } from "store/modules/init/init";
import { route } from "./route.model";
const BasicConfiguration = lazy(
    () => import("../../core/Protected/Admin/BasicConfiguration")
);
const Service = lazy(() => import("core/Protected/Admin/MasterTabs/Service"));
const Caste = lazy(() => import('core/Protected/Admin/MasterTabs/Caste'));
const CasteType = lazy(() => import("core/Protected/Admin/MasterTabs/CasteType"))
const Class = lazy(() => import("core/Protected/Admin/MasterTabs/Class"))
const District = lazy(() => import("core/Protected/Admin/MasterTabs/District"))
const EducationBoard = lazy(() => import("core/Protected/Admin/MasterTabs/Education/EducationBoard"));
const EducationFaculty = lazy(() => import("core/Protected/Admin/MasterTabs/Education/EducationFaculty"))
const EducationLevel = lazy(() => import("core/Protected/Admin/MasterTabs/Education/EducationLevel"))
const ExamCenter = lazy(() => import("core/Protected/Admin/MasterTabs/ExamCenter"))
const Group = lazy(() => import("core/Protected/Admin/MasterTabs/Group"))
const Invigilator = lazy(() => import("core/Protected/Admin/MasterTabs/invigilator"))
const InvigilatorType = lazy(() => import("core/Protected/Admin/MasterTabs/invigilatorPost"))
const LocalBody = lazy(() => import("core/Protected/Admin/MasterTabs/LocalBody"))
const MasterConfiguration = lazy(() => import("core/Protected/Admin/MasterTabs/MasterConfiguration"))
const MinistryConfiguration = lazy(() => import("core/Protected/Admin/MasterTabs/MinistryConfiguration"))
const MotherLanguage = lazy(() => import("core/Protected/Admin/MasterTabs/MotherLanguage"))
const Organization = lazy(() => import("core/Protected/Admin/MasterTabs/Organization"))
const Payment = lazy(() => import("core/Protected/Admin/MasterTabs/PhysicallyDisabled"))
const PhysicallyDisabled = lazy(() => import("core/Protected/Admin/MasterTabs/PhysicallyDisabled"))
const Post = lazy(() => import("core/Protected/Admin/MasterTabs/Post"))
const Quota = lazy(() => import("core/Protected/Admin/MasterTabs/Quota"))
const Religion = lazy(() => import("core/Protected/Admin/MasterTabs/Religion"))
const Room = lazy(() => import("core/Protected/Admin/MasterTabs/Room/Room"))
const Samuhabarga = lazy(() => import("core/Protected/Admin/MasterTabs/Samuhabarga/SamuhaBarga"))
const SubGroup = lazy(() => import("core/Protected/Admin/MasterTabs/SubGroup"))
const Subject = lazy(() => import("core/Protected/Admin/MasterTabs/Subject"))

export const basicConfigurationConfigRoute: route[] = [
    {
        path: "/admin/basic-configuration",
        component: BasicConfiguration,
        privilege: [PrivilegeModule.MC],
        children: [
            {
                path: "/admin/basic-configuration/service",
                component: Service
            },
            {
                path: "/admin/basic-configuration/group",
                component: Group
            },
            {
                path: "/admin/basic-configuration/caste",
                component: Caste
            },
            {
                path: "/admin/basic-configuration/sub-group",
                component: SubGroup
            },
            {
                path: "/admin/basic-configuration/class",
                component: Class
            },
            {
                path: "/admin/basic-configuration/post",
                component: Post
            },
            {
                path: "/admin/basic-configuration/subject",
                component: Subject
            },
            {
                path: "/admin/basic-configuration/master-configuration",
                component: MasterConfiguration,
            },
            {
                path: "/admin/basic-configuration/district",
                component: District,
            },
            {
                path: "/admin/basic-configuration/caste",
                component: Caste
            },
            {
                path: "/admin/basic-configuration/caste-type",
                component: CasteType,
            },
            {
                path: "/admin/basic-configuration/mother-language",
                component: MotherLanguage,
            },
            {
                component: Religion, path: "/admin/basic-configuration/religion"
            },
            {
                component: PhysicallyDisabled, path: "/admin/basic-configuration/physically-disabled"
            },
            {
                component: LocalBody, path: "/admin/basic-configuration/local-body"
            },
            {
                component: EducationBoard, path: "/admin/basic-configuration/education-board"
            },
            {
                component: EducationFaculty, path: "/admin/basic-configuration/education-faculty"
            },
            {
                component: EducationLevel, path: "/admin/basic-configuration/education-level"
            },
            {
                component: Payment, path: "/admin/basic-configuration/payment"
            },
            {
                component: Organization, path: "/admin/basic-configuration/organization"
            },
            {
                component: Quota, path: "/admin/basic-configuration/quota"
            },
            {
                component: ExamCenter, path: "/admin/basic-configuration/exam-center"
            },
            {
                component: Invigilator, path: "/admin/basic-configuration/invigilator"
            },
            {
                component: InvigilatorType, path: "/admin/basic-configuration/invigilator-type"
            },
            {
                component: Room, path: "/admin/basic-configuration/room"
            },
            {
                component: MinistryConfiguration, path: "/admin/basic-configuration/ministry-configuration"
            },
            {
                component: Samuhabarga, path: "/admin/basic-configuration/samuhabarga"
            }
        ]
    }

]