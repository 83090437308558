import { combineReducers } from "redux";
import deleteQualificationReducer from "./deleteQualification";
import filterByClassIdReducer from "./filterByClassId";
import filterByParamsReducer from "./filterByParams";
import filterByPostIdReducer from "./filterByPostId";
import allMasterDataCurriculumReducer from "./getAllMasterCurriculum";
import qualificationByCatIdAndMasterIdReducer from "./getByMasterIdAndCategory";
import getQualificationReducer from "./getQualification";
import saveQualificationReducer from "./saveQualification";


const academicQualificationReducer = combineReducers({
  allMasterDataCurriculum: allMasterDataCurriculumReducer,
  saveQualificationData: saveQualificationReducer,
  deleteQualificationData: deleteQualificationReducer,
  getQualificationData: getQualificationReducer,
  filterByPostIdData: filterByPostIdReducer,
  filterByClassIdData: filterByClassIdReducer,
  filterByParamsData: filterByParamsReducer,
  qualificationByCatIdAndMasterIdData: qualificationByCatIdAndMasterIdReducer,
})

export default academicQualificationReducer;