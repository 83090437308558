import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiFiscalDetails = apiList.internal.admin.scrutiny.getAdvtCodeByFiscalYear;
export function getAdvtCodeByFiscalYearReducer(store = { ...obj }, action) {
    let state = Object.assign({}, store);
    let actionName = apiFiscalDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const getAdvtCodeByFiscalYearAction = (fiscalYear) => async (dispatch) => {
    const newApidetails = { ...apiFiscalDetails }
    newApidetails.controllerName = newApidetails.controllerName.replace("{fiscalYear}", fiscalYear)
    return createDefaultAction(newApidetails, dispatch, null, "GET");
}
