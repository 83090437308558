import { apiList } from "store/actionNames";
import { createDefaultAction } from "store/helper/default-action";
import { defaultReducer } from "store/helper/default-reducer";
import { defaultState } from "store/helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.scrutiny.reportByGender.quotaReport.getQuotaReport

export interface IQuotaData {
  advtCode: string;
  status: string;
  fiscalYear: string;
}

export default function getQuotaReportReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getQuotaReportAction = (params: IQuotaData
) => async dispatch => {
  const newApiDetails = { ...apiDetails };
  newApiDetails.controllerName = newApiDetails.controllerName.replace("{advtCode}", params.advtCode.toString()).replace("{status}", params.status?.toString()).replace("{fiscalYear}", params.fiscalYear.toString())
  return createDefaultAction(newApiDetails, dispatch, null, "GET");
};