import {defaultState} from '../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer';
import {createDefaultAction} from '../../../helper/default-action';
import {apiList} from '../../../actionNames';

const obj = defaultState;
const apiDetails = apiList.external.vacancy.mandatoryCheck;
export default function mandatoryDocsReducer(store={...obj}, action){
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const mandatoryDocsAction = ():any=> async (dispatch)=>{
    const newApiDetails = {...apiDetails};
    // newApiDetails.controllerName = newApiDetails.controllerName.replace('{id}',id)
    const resData = await createDefaultAction(newApiDetails, dispatch,null,"GET",true)
    return resData;
}
