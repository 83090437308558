import { combineReducers } from "redux";
import getProfessionalListReducer from "./getProfessional";
import getProfessionalTypeReducer from "./getProfessionalType";
import saveProfessionalDetailsReducer from "./saveProfessional";
import editProfessionalListReducer from "./editProfessional";
import deleteProfessionalListReducer from "./deleteProfessional";

const professionalReducer = combineReducers({
    professionalData: getProfessionalListReducer,
    getProfessionalType:getProfessionalTypeReducer,
    saveProfessionalDetails:saveProfessionalDetailsReducer,
    editProfessionalList:editProfessionalListReducer,
    deleteProfessionalList:deleteProfessionalListReducer
})

export default professionalReducer;