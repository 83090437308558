import {defaultState} from '../../../helper/default-state';
import {apiList} from '../../../actionNames/index';
import {createDefaultAction} from '../../../helper/default-action';
import {defaultReducer} from '../../../helper/default-reducer';

const obj = defaultState;
const apiDetails = apiList.external.vacancy.filterMyApplication;

export default function filterMyApplicationReducer(store={...obj}, action){
    const state = Object.assign({},store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state)
}

export const filterMyApplicationAction = (reqData:Object):any => async (dispatch) =>   {
    const resData = await createDefaultAction(apiDetails, dispatch,reqData, "POST");
    return resData
}
