import { phaseTypes } from "utils/constants";
import { apiList } from "../../../actionNames";
import { createDefaultAction } from "../../../helper/default-action";
import { defaultReducer } from "../../../helper/default-reducer";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.findAllFormulaByClassIdAndServiceId

export interface FindFormulaParams {
  classId?: string,
  serviceId?: string,
  postId?: string
  distributionType: string
}

export default function findAllFormulaByClassIdAndServiceIdReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const findAllFormulaByClassIdAndServiceId = (phase: phaseTypes, params?: FindFormulaParams,
) => async dispatch => {
  return createDefaultAction(apiDetails, dispatch, null, "GET", false, undefined, params, { phasetype: phase });
};