import { UpdateAdData } from '../../../../../core/Protected/Admin/PublishAdvertisement/PublishAdvertisement';
import { apiList } from '../../../../actionNames';
import { createDefaultAction } from '../../../../helper/default-action';
import { defaultReducer } from '../../../../helper/default-reducer';
import { defaultState } from '../../../../helper/default-state';

const obj = defaultState;
const apiDetails = apiList.internal.admin.updateAdvertisementNumber;

export default function updateAdvertisementNumberReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const updateAdvertisementNumber = (reqData: UpdateAdData) => async (dispatch) => {
    const resData = await createDefaultAction(apiDetails, dispatch, reqData, "POST")
    return resData;
}
