import { defaultState } from '../../../helper/default-state';
import { defaultReducer } from '../../../helper/default-reducer';
import { createDefaultAction } from '../../../helper/default-action';
import { apiList } from '../../../actionNames';
import { AppThunk } from 'store';

const obj = defaultState;
const apiDetails = apiList.internal.admin.getLocalBodyType;

export default function getLocalBodyTypeReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const getLocalBodyTypeAction = (): AppThunk<Promise<any>> => async (dispatch) => {
    const resData = await createDefaultAction(apiDetails, dispatch, null, "GET")
    return resData;
}
