import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.scrutiny.scrutinyFilter;

export default function getScrutinyFilterReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getScrutinyFilterList = (params) => async (dispatch) => {
    const newApilist={...apiDetails}
    newApilist.controllerName=newApilist.controllerName.replace("{pageLength}",params.pageLength)
                                                      .replace("{pageNumber}",params.pageNumber)
                                                      .replace("{advtCode}",params.advtCode)
  return createDefaultAction(newApilist, dispatch, null, "GET");
};
