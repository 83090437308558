import {defaultState} from '../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer';
import {createDefaultAction} from '../../../helper/default-action';
import {apiList} from '../../../actionNames';

const obj = defaultState;
const apiDetails = apiList.internal.admin.searchByMasterId;

export default function searchByMasterIdReducer(store={...obj}, action){
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const searchByMasterId = (reqData: string) => async (dispatch) => {
    const newApiDetails = { ...apiDetails, controllerName: apiDetails.controllerName.replace("{masterId}", reqData) }
    const resData = await createDefaultAction(newApiDetails,dispatch, reqData, "GET");
    return resData;
}

