import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState
let apiDetails = apiList.internal.admin.assignExamCenter.getGeneratedAllocateList

export default function getGeneratedAllocateListReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store)
  const actionName = apiDetails.actionName
  return defaultReducer(actionName, action, state)
}

export const getGeneratedAllocateListAction = (reqData: any) => async (dispatch) => {
  return createDefaultAction(apiDetails, dispatch, reqData, "POST")
}