import { defaultState } from "../../helper/default-state";
import { defaultReducer } from "../../helper/default-reducer";
import { createDefaultAction } from "../../helper/default-action";
import { apiList } from "../../actionNames";

export interface ChangePasswordDataNoToken {
    newPassword: string
    conformPassword: string
    email: string
    username: string
}

const obj = defaultState;
const apiDetails = apiList.psc.changeUserNoTokenPassword;

export default function changeUserNoTokenPasswordReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const changeUserNoTokenPassword = (requestData: ChangePasswordDataNoToken) => async dispatch => {
    return createDefaultAction(apiDetails, dispatch, requestData, "POST");
};