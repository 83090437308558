import {combineReducers} from 'redux';
import listLockedProfileReducer from './list-locked-profile';
import lockUserProfileReducer from './lock-user-profile';
import searchUserProfileReducer from './profileSearch';
import searchByMasterIdReducer from './search-by-master-id';
import unlockProfileByMasterIdReducer from './unlock-profile-by-master-id';


const lockUnlockReducer = combineReducers({
    searchByMasterIdData: searchByMasterIdReducer,
    unlockProfileByMasterIdData: unlockProfileByMasterIdReducer,
    listLockedProfileData: listLockedProfileReducer,
    lockUserProfileData: lockUserProfileReducer,
    searchUserProfileReducer:searchUserProfileReducer
});

export default lockUnlockReducer;