import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.preExaminationConfig.invigilatorAllocation.deleteAllocation;

export default function getDeleteInvigilatorAllocationReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getDeleteInvigilatorAllocationList = (id:any) => (dispatch) => {
  
    const newApilList={...apiDetails}
    newApilList.controllerName=newApilList.controllerName.replace("{id}",id)
  return createDefaultAction(newApilList, dispatch, null, "DELETE");
};
