import { combineReducers } from "redux";
import getEducationListReducer from "./getEducationList";
import deleteEducationDetailsReducer from './deleteEducation'
import editEducationDetailsReducer from "./editEducationList";
import saveEducationDetailsReducer from "./saveEdcuationlist";
import getFacultyListReducer from "./getFacultylist";
import getLevelListReducer from "./getLevelList";
import getUniversityBoardListReducer from "./getBoardList";
import saveEducationBoardReducer from "./saveEducationBoard";
import saveEducationFacultyReducer from "./saveEducationFaculty";
import saveEducationLevelReducer from "./saveEducationLevel";

const EdcuationReducer = combineReducers({
    educationList: getEducationListReducer,
    deleteEducationDetails:deleteEducationDetailsReducer,
    editEducationDetails:editEducationDetailsReducer,
    saveEducationDetails:saveEducationDetailsReducer,
    getFacultyList:getFacultyListReducer,
    getLevelList:getLevelListReducer,
    getAllUniversityBoardList:getUniversityBoardListReducer,
    saveEducationBoardData: saveEducationBoardReducer,
    saveEducationFacultyData: saveEducationFacultyReducer,
    saveEducationLevelData: saveEducationLevelReducer
})

export default EdcuationReducer;