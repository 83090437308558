import { apiList } from '../../../../actionNames';
import { createDefaultAction } from '../../../../helper/default-action';
import { defaultReducer } from '../../../../helper/default-reducer';
import { defaultState } from '../../../../helper/default-state';

const obj = defaultState;
const apiDetails = apiList.internal.admin.advertisementDataById;

export default function getAdvertisementDataByIdReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const getAdvertisementDataById = (reqData: { adId: number }) => async (dispatch) => {
    const newApiDetails = { ...apiDetails, controllerName: apiDetails.controllerName.replace("{adId}", reqData.adId.toString()) }
    const resData = await createDefaultAction(newApiDetails, dispatch, null, "GET")
    return resData;
}
