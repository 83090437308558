import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.scrutiny.scrutinyAllcoationFilter;
const withOutSearchDetails = apiList.internal.admin.scrutiny.scrutinyAllcoationWithoutFilter
const scrutinyAllcoationWithFiscalYear = apiList.internal.admin.scrutiny.scrutinyAllcoationWithFiscalYear

export default function getScrutinyAllocationFilterReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getScrutinyAllocationFilterList = (params) => async (dispatch) => {
  const newApilist = { ...apiDetails }
  const withoutAdvt = { ...withOutSearchDetails }
  const withFiscalYearFilter = { ...scrutinyAllcoationWithFiscalYear }

  params.fiscalYear ? withFiscalYearFilter.controllerName = withFiscalYearFilter.controllerName.replace("{pageLength}", params.pageLength)
    .replace("{pageNumber}", params.pageNumber).replace("{fiscalYear}", params.fiscalYear)
    :
    params.advtCode ? newApilist.controllerName = newApilist.controllerName.replace("{pageLength}", params.pageLength)
      .replace("{pageNumber}", params.pageNumber)
      .replace("{advtCode}", params.advtCode) :
      withoutAdvt.controllerName = withoutAdvt.controllerName.replace("{pageLength}", params.pageLength)
        .replace("{pageNumber}", params.pageNumber)
  return createDefaultAction(params.advtCode ? newApilist : params.fiscalYear ? withFiscalYearFilter : withoutAdvt, dispatch, null, "GET");
};
