import { defaultState } from "../../helper/default-state";
import { defaultReducer } from "../../helper/default-reducer";
import { createDefaultAction } from "../../helper/default-action";
import { apiList } from "../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.getDistrictByProvinceId

export interface District {
  districtnameen: string
  districtnamenp: string
  id: number
  provinceid: number;
}

export default function districtByProvinceIdReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getDistrictByProvinceId = (provinceId: string) => async dispatch => {
  const newApiDetails = { ...apiDetails }
  newApiDetails.controllerName = newApiDetails.controllerName.replace("{provinceId}", provinceId)
  return createDefaultAction(newApiDetails, dispatch, null, "GET");
};

export const resetDistrictByProvince = () => {
  return {
    type: apiDetails.actionName + "_RESET",
  };
}