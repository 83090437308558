import { combineReducers } from 'redux';
import getFinalSaveReducer from './finalAssignment';
import getAdvertisementReducer from './getAdvertisementInfo';
import getExamCenterReducer from './getAllocatedExamCenter';
import getAllocatedInvigilatorReducer from './getAllocatedInvigilator';
import getDeleteInvigilatorAllocationReducer from './invigilatorDelete';
import getRevertReducer from './revert';
import getTemporarySaveReducer from './temporaryAssignment';
import uploadExcelInvigilatorReducer from './uploadExcel';


const invigilatorAllocationReducer = combineReducers({
    getAdvertisementData: getAdvertisementReducer,
    getExamCenterData: getExamCenterReducer,
    getFinalSaveData: getFinalSaveReducer,
    getRevertData: getRevertReducer,
    getTemporarySaveData: getTemporarySaveReducer,
    uploadExcelInvigilatorData: uploadExcelInvigilatorReducer,
    getAllocatedInvigilatorData: getAllocatedInvigilatorReducer,
    getDeleteInvigilatorAllocationReducer: getDeleteInvigilatorAllocationReducer
})

export default invigilatorAllocationReducer