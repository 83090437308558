import { createDefaultAction } from '../../../helper/default-action';
import { apiList } from '../../../actionNames/index';
import { defaultState } from '../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer'

const obj = defaultState
const apiDetail = apiList.internal.userManagement.fullUserDetails
export default function fullUserDetailsReducer(store = {...obj}, action) {
    const state = Object.assign({},store)
    const actionName = apiDetail.actionName
    return defaultReducer(actionName,action,state)
}

export const fullUserDetails = (masterId:any) => async dispatch => {
    const newApiDetails = {...apiDetail}
    newApiDetails.controllerName = newApiDetails.controllerName.replace("{masterId}",masterId)
    return createDefaultAction(newApiDetails,dispatch,null,"GET")
}
