import { apiList } from "../../actionNames";
import { createDispatchTypes } from "../../helper/default-action-type";

export interface  myProfileTabReduxState{
    activeTab:string
}

const myProfileTabAction={
    INIT: 'MY_PROFILE_TAB_MODAL_INIT',
    SUCCESS: 'MY_PROFILE_TAB_MODAL_SUCCESS',
    FAIL: 'MY_PROFILE_TAB_MODAL_FAIL'
}

const myProfileTabObject:myProfileTabReduxState = {
    activeTab:""
}

export const myProfileTab = (payload: boolean) => async dispatch => {
    const apiDetails = apiList.local.myActiveTab;

    // Init Dispatch Types
    const dispatchTypes = createDispatchTypes(apiDetails.actionName);
    
    return dispatch({ type: dispatchTypes.successDispatch, payload: payload});
}

export default function myProfileTabReducer(store: myProfileTabReduxState = { ...myProfileTabObject }, action): myProfileTabReduxState {
    const state = Object.assign({}, store);
    switch (action.type) {
        case myProfileTabAction.SUCCESS: {
            return {
                ...state,
                activeTab: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}