import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;

const apiDetails = apiList.internal.preExaminationConfig.report.getExamCenters;
export default function getExamCenterDetailsReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getExamCenterList = (centerId:string) => (dispatch) => {
    const newDetails={...apiDetails}
    newDetails.controllerName=newDetails.controllerName.replace("{id}",centerId)
  return createDefaultAction(newDetails, dispatch, null, "GET");
};