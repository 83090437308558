import { Dispatch } from "redux";
import { apiList } from "../../actionNames";
import { createDispatchTypes } from "../../helper/default-action-type";


export interface InformationModalState {
  login: string;
}

const informantionModalActions = {
  INFORMANTION_MODAL_INIT: "INFORMANTION_MODAL_INIT",
  INFORMANTION_MODAL_SUCCESS: "INFORMANTION_MODAL_SUCCESS",
  INFORMANTION_MODAL_FAIL: "INFORMANTION_MODAL_FAIL",
};

const modalState: InformationModalState = {
  login: "",
};

export const informationModal =
  (payload: string) =>
    async (dispatch: Dispatch) => {
      const apiDetails = apiList.local.informationModal;
      localStorage.setItem("login", `${payload}`)
      // Init dispatch type
      const dispatchTypes = createDispatchTypes(apiDetails.actionName);
      dispatch({ type: dispatchTypes.progressDispatch, payload: payload });

      try {
        dispatch({ type: dispatchTypes.successDispatch, payload: payload });
      } catch (e) {
        dispatch({ type: dispatchTypes.failureDispatch, payload: payload });
      }
    };

export default function informationModalStateReducer(
  store: InformationModalState = { ...modalState },
  action
): InformationModalState {
  const state = Object.assign({}, store);
  switch (action.type) {
    case informantionModalActions.INFORMANTION_MODAL_SUCCESS: {
      return {
        ...state,
        login: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
