import { apiList } from "store/actionNames";
import { createDefaultAction } from "store/helper/default-action";
import { defaultReducer } from "store/helper/default-reducer";
import { defaultState } from "store/helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.saveVacancyLimitPhaseOne

export default function saveVacancyLimitPhaseOneReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export interface saveVacancyLimitPhaseOneType {
  id: string | number,
  classId: number,
  serviceId: number | null,
  vacancyLimit: number
}

export const saveVacancyLimitPhaseOne = (
  reqData: saveVacancyLimitPhaseOneType
) => async dispatch => {
  return createDefaultAction(apiDetails, dispatch, reqData, "POST");
};