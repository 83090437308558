
import { lazy } from "react";
import { PrivilegeModule } from 'store/modules/init/init';
const PercentageDistributionLog = lazy(() => import("core/Protected/Admin/VacancyDistributionHistory/PercentageDistributionLog"))
const VacancyDistributionHistory = lazy(() => import("core/Protected/Admin/VacancyDistributionHistory/VacancyDistributionHistory"))
const VacancyHistoryRouteIndex = lazy(() => import("core/Protected/Admin/VacancyDistributionHistory"))


export const percentageDistributeHistoryRoute = [
  {
    path: "/admin/vacancy-distribution-history",
    component: VacancyHistoryRouteIndex,
    privilege: [PrivilegeModule.VDH],
    children: [
      {
        path: "/admin/vacancy-distribution-history/log/:id/:distributionType",
        component: PercentageDistributionLog,
        privilege: [PrivilegeModule.VDH],
      },
      {
        path: "/admin/vacancy-distribution-history",
        component: VacancyDistributionHistory,
        privilege: [PrivilegeModule.VDH],
      },
    ],
  }
];


