import { createDefaultAction } from "./../../../helper/default-action";
import { apiList } from "./../../../actionNames/index";
import { defaultState } from "./../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";

const obj = defaultState;
const apiDetail = apiList.psc.getVoucherImage;

export default function getVoucherImageReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetail.actionName;
  return defaultReducer(actionName, action, state);
}

export const getVoucherImage = (data:any) => async (dispatch) => {
  return createDefaultAction(apiDetail, dispatch, data, "POST");
};
