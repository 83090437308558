import {combineReducers} from 'redux';
import getAdvertismentListReducer from './advertisementList';
import getAdvertisementDataByIdReducer from './get-advertisement-data-by-id';
import getSchedulePublishedAdvertisementReducer from './get-advertisement-data-by-schedule';
import getListForAdvertisementReducer from './get-list-for-advertisement';
import getPublishedAdvertisementListReducer from './get-list-published-advertisement';
import publishAdvertisementReducer from './publish-advertisement';
import rejectAdvertisementReducer from './reject-advertisement';
import updateAdvertisementNumberReducer from './update-advertisement-number';


const advertisementReducer = combineReducers({
    getListForAdvertisementData: getListForAdvertisementReducer,
    getAdvertisementDataByIdData: getAdvertisementDataByIdReducer,
    publishAdvertisementData: publishAdvertisementReducer,
    updateAdvertisementNumberData: updateAdvertisementNumberReducer,
    getPublishedAdvertisementListData: getPublishedAdvertisementListReducer,
    getSchedulePublishedAdvertisements:getSchedulePublishedAdvertisementReducer,
    rejectAdvertisementData:rejectAdvertisementReducer,
    getAdvertismentList: getAdvertismentListReducer
});

export default advertisementReducer;
