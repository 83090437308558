import { ApproveRequisitionFormData } from "../../../../core/Protected/Admin/ApprovalTask/ApprovalRequisition/ApprovalRequisition";
import { apiList } from "../../../actionNames";
import { createDefaultAction } from "../../../helper/default-action";
import { defaultReducer } from "../../../helper/default-reducer";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.approverequisition;

export default function approveRequisitionReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const approveRequisition = (requestData: ApproveRequisitionFormData) => async dispatch => {
    return createDefaultAction(apiDetails, dispatch, requestData, "POST");
};