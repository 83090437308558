import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.scrutiny.getAdvertisementCodeList;
const apiFiscalDetails = apiList.internal.admin.scrutiny.getAdvertisementCodeParamsList;

export default function getAdvertisementCodeReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getAdvertisementCodeList = () => async (dispatch) => {
  return createDefaultAction(apiDetails, dispatch, null, "GET");
};

export function getAdvertisementCodeWithFiscalYearListReducer(store = { ...obj }, action) {
  let state = Object.assign({}, store);
  let actionName = apiFiscalDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getAdvertisementCodeWithFiscalYearList = (fiscalYear) => async (dispatch) => {

  const newApidetails = { ...apiFiscalDetails }
  newApidetails.controllerName = newApidetails.controllerName.replace("{fiscal}", fiscalYear)
  return createDefaultAction(newApidetails, dispatch, null, "GET");
}
