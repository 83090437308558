import { defaultReducer } from "../../helper/default-reducer";
import { createDefaultAction } from "../../helper/default-action";
import { apiList } from "../../actionNames";
import { defaultState } from "../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.education.deleteEducationList

// Save Candidate Details
export default function deleteEducationDetailsReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const deleteEducationDetail = (
  requestBody: any
) => async dispatch => {
  
  const newApiDetails = { ...apiDetails }
    newApiDetails.controllerName = apiDetails.controllerName.replace(`{id}`,requestBody)
    
    return createDefaultAction(newApiDetails, dispatch, null, "DELETE");
};