import { lazy } from "react";
import { PrivilegeModule } from "../../store/modules/init/init";
import { basicConfigurationConfigRoute } from "./basic-configuration-route";
import { percentageDistributeConfigRoute, provinceLevelDistributionConfigRoute } from "./percentage-distribution-config-route";
import { percentageDistributeHistoryRoute } from "./percentage-distribution-history";
import { reportByGenderConfigurationConfigRoute } from "./reportByGender-Configuration-route";
import { route } from "./route.model";

const Login = lazy(() => import("../../core/Public/Login/Login"));
const ModuleLists = lazy(
  () =>
    import(
      "../../core/Protected/Admin/UserManagement/Internal User Management/ModuleLists"
    )
);
const Faq = lazy(() => import("../../core/Public/faq/Faq"));
const Register = lazy(() => import("../../core/Public/Register/Register"));
const ResetPassword = lazy(
  () => import("../../core/Public/ResetPassword/ResetPassword")
);

const ApplicationSuccess = lazy(
  () => import("../../core/Protected/Vacancy/Vacancy Apply/ApplicationSuccess")
);
const ApplicationFailure = lazy(
  () => import("../../core/Protected/Vacancy/Vacancy Apply/ApplicationFailure")
);

const Boundary = lazy(() => import("../../core/Protected/Dashboard/Boundary"));

const AdminDashboard = lazy(
  () => import("../../core/Protected/Admin/Dashboard/Dashboard")
);
const PercentageDistributionActivity = lazy(
  () =>
    import(
      "../../core/Protected/Admin/DistributionLog/PercentageDistributionLog"
    )
);
const RequisitionForm = lazy(
  () => import("../../core/Protected/Admin/Requisition/RequisitionForm")
);
const Dashboard = lazy(
  () => import("../../core/Protected/Dashboard/Dashboard")
);
const MyProfile = lazy(
  () => import("../../core/Protected/Vacancy/MyProfile/MyProfile")
);
const List = lazy(
  () => import("../../core/Protected/Vacancy/Vacancy Apply/List/List")
);
const Detail = lazy(
  () => import("../../core/Protected/Vacancy/Vacancy Apply/Detail")
);

const IndividualApplication = lazy(
  () =>
    import("../../core/Protected/Vacancy/Vacancy Apply/IndividualApplication")
);
const VoucherUpload = lazy(
  () => import("../../core/Protected/Vacancy/VoucherUpload")
);
const ApprovedAdmitCard = lazy(
  () => import("../../core/Protected/Vacancy/Vacancy Apply/ApprovedAdmitCard")
);

const ScrutinyAllocation = lazy(
  () =>
    import("../../core/Protected/Admin/ScrutinyAllocation/scrutinyAllocation")
);

const Allocation = lazy(
  () => import("../../core/Protected/Admin/ScrutinyAllocation/formAllocation")
);
const AgeLimit = lazy(
  () => import("../../core/Protected/Admin/AgeLimit/AgeLimit")
);
const AgeLimitConfigure = lazy(
  () => import("../../core/Protected/Admin/AgeLimit/AgeLimitConfigure")
);
const CombinePostConfiguration = lazy(
  () =>
    import(
      "../../core/Protected/Admin/CombinePostConfiguration/CombinePostConfiguration"
    )
);
const Scrutiny = lazy(
  () => import("../../core/Protected/Admin/Scrutiny/Scrutiny")
);
const ScrutinyCandidates = lazy(
  () => import("../../core/Protected/Admin/Scrutiny/ScrutinyCandidateList")
);

const CandidateDetails = lazy(
  () => import("../../core/Protected/Admin/Scrutiny/candiateDetails")
);
const Report = lazy(() => import("../../core/Protected/Admin/Scrutiny/Report"));
const IndividualAllocationReport = lazy(
  () => import("../../core/Protected/Admin/Scrutiny/IndividualAllocationReport")
);
const AdvertisementApplicationReport = lazy(
  () =>
    import("../../core/Protected/Admin/Scrutiny/AdvertisementApplicationReport")
);
const AllocationReport = lazy(
  () => import("../../core/Protected/Admin/Scrutiny/allocationReport")
);

const ApprovalTask = lazy(
  () => import("../../core/Protected/Admin/ApprovalTask/ApprovalTask")
);
const ApprovalRequisition = lazy(
  () =>
    import(
      "../../core/Protected/Admin/ApprovalTask/ApprovalRequisition/ApprovalRequisition"
    )
);
const PercentageDistributionRequest = lazy(
  () =>
    import(
      "../../core/Protected/Admin/PercentageDistribution/PercentageDistribution"
    )
);
const PercentageDistributionApproval = lazy(
  () =>
    import(
      "../../core/Protected/Admin/ApprovalTask/PercentageDIstributionApproval/PercentageDistributionApproval"
    )
);


const ScheduleExam = lazy(
  () => import("../../core/Protected/Admin/ScheduleExam/ScheduleExam")
);

const PaperConfiguration = lazy(
  () =>
    import("../../core/Protected/Admin/PaperConfiguration/PaperConfiguration")
);
const PaperDetail = lazy(
  () =>
    import(
      "../../core/Protected/Admin/PaperConfiguration/PaperDetail/PaperDetail"
    )
);

const AcademicQualification = lazy(
  () =>
    import(
      "../../core/Protected/Admin/AcademicQualification/AcademicQualification"
    )
);
const CreateQualification = lazy(
  () =>
    import(
      "../../core/Protected/Admin/AcademicQualification/CreateQualification"
    )
);
const ViewQualification = lazy(
  () =>
    import("../../core/Protected/Admin/AcademicQualification/ViewQualification")
);

const ExamScheduling = lazy(
  () => import("../../core/Protected/Admin/ExamScheduling/ExamScheduling")
);
const SelectiveSubject = lazy(
  () =>
    import("../../core/Protected/Admin/PublishAdvertisement/SelectiveSubject")
);
// const ReviewExamScheduling = lazy(() => import('../../core/Protected/Admin/ExamScheduling/ReviewExamScheduling'))
const PublishAdvertisement = lazy(
  () =>
    import(
      "../../core/Protected/Admin/PublishAdvertisement/PublishAdvertisement"
    )
);
const PublishedAdvertisement = lazy(
  () =>
    import(
      "../../core/Protected/Admin/PublishedAdvertisement/PublishedAdvertisement"
    )
);

const UserManagement = lazy(
  () => import("../../core/Protected/Admin/UserManagement/UserManagement")
);
const BanOrRestoreUser = lazy(
  () =>
    import("../../core/Protected/Admin/UserManagement/UserBan/BanOrRestoreUser")
);
const UnBanUser = lazy(
  () => import("../../core/Protected/Admin/UserManagement/UserBan/UnBanUser")
);
const BanUser = lazy(
  () => import("../../core/Protected/Admin/UserManagement/UserBan/UserBan")
);

const LockUnlock = lazy(
  () =>
    import("../../core/Protected/Admin/UserManagement/LockUnlock/LockUnlock")
);
const LockProfile = lazy(
  () =>
    import("../../core/Protected/Admin/UserManagement/LockUnlock/LockProfile")
);
const UnlockProfile = lazy(
  () =>
    import("../../core/Protected/Admin/UserManagement/LockUnlock/UnlockProfile")
);

const PersonalDetails = lazy(
  () =>
    import(
      "../../core/Protected/Vacancy/MyProfile/PersonalDetails/PersonalDetails"
    )
);
const Extra = lazy(
  () => import("../../core/Protected/Vacancy/MyProfile/Extra/Extra")
);
const Contact = lazy(
  () => import("../../core/Protected/Vacancy/MyProfile/Contact/Contact")
);
const Education = lazy(
  () => import("../../core/Protected/Vacancy/MyProfile/Education/Education")
);
const Training = lazy(
  () => import("../../core/Protected/Vacancy/MyProfile/Training/Training")
);
const Professional = lazy(
  () =>
    import("../../core/Protected/Vacancy/MyProfile/Professional/Professional")
);
const Experience = lazy(
  () => import("../../core/Protected/Vacancy/MyProfile/Experience/Experience")
);
const Documents = lazy(
  () => import("../../core/Protected/Vacancy/MyProfile/Documents/Documents")
);
const Preview = lazy(
  () => import("../../core/Protected/Vacancy/MyProfile/Preview/Preview")
);

const InternalUserAdd = lazy(
  () =>
    import(
      "../../core/Protected/Admin/UserManagement/Internal User Management/InternalUserAdd"
    )
);
const InternalUserList = lazy(
  () =>
    import(
      "../../core/Protected/Admin/UserManagement/Internal User Management/InternalUserList"
    )
);
const NewRoleModuleCreation = lazy(
  () =>
    import(
      "../../core/Protected/Admin/UserManagement/Internal User Management/NewRoleModuleCreation"
    )
);
const RoleLists = lazy(
  () =>
    import(
      "../../core/Protected/Admin/UserManagement/Internal User Management/RoleLists"
    )
);
const VoucherManagement = lazy(
  () => import("../../core/Protected/Admin/Voucher")
);
const PasswordReset = lazy(
  () => import("../../core/Protected/Admin/PasswordReset/PasswordReset")
);

const GenerateFormate = lazy(
  () => import("../../core/Protected/Admin/ExamSheetFormat/generateExamFormat")
);
const ProfileManagement = lazy(
  () =>
    import(
      "../../core/Protected/Admin/UserManagement/Internal User Management/userProfileSearch"
    )
);
const ApproveExamSchedule = lazy(
  () =>
    import("../../core/Protected/Admin/ApproveExamSchedule/ApproveExamSchedule")
);
const ApprovalExamSchedule = lazy(
  () => import("../../core/Protected/Admin/ApproveExamSchedule/Approval")
);
const ExamCenter = lazy(
  () => import("../../core/Protected/Dashboard/ExamCenter/ExamCenter")
);
const CenterList = lazy(
  () => import("../../core/Protected/Dashboard/ExamCenter/CenterList")
);
const InvigilatorPost = lazy(
  () =>
    import(
      "../../core/Protected/Dashboard/ExamCenter/InvigilatorPost/InvigilatorPost"
    )
);
const InvigilatorPostList = lazy(
  () =>
    import(
      "../../core/Protected/Dashboard/ExamCenter/InvigilatorPost/InvigilatorPostList"
    )
);
const Invigilator = lazy(
  () => import("../../core/Protected/Dashboard/ExamCenter/Invigilator")
);
const ExamCenterAllocation = lazy(
  () => import("../../core/Protected/Dashboard/ExamCenter/ExamCenterAllocation")
);
const AllocatedCandidateList = lazy(
  () =>
    import("../../core/Protected/Dashboard/ExamCenter/AllocatedCandidateList")
);
const ArchivedCandidateList = lazy(
  () =>
    import("../../core/Protected/Dashboard/ExamCenter/ArchivedCandidateList")
);
const AllocateNewExamCenter = lazy(
  () =>
    import(
      "../../core/Protected/Dashboard/ExamCenter/AllocateNewExamCenter/AllocateNewExamCenter"
    )
);
const ExamCenterAllocationIndex = lazy(
  () =>
    import(
      "../../core/Protected/Dashboard/ExamCenter/ExamCenterAllocationIndex"
    )
);
const InvigilatorAllocation = lazy(
  () =>
    import(
      "../../core/Protected/Admin/InvigilatorAllocation/invigilatorAllocation"
    )
);
const UpdateDocument = lazy(
  () =>
    import("../../core/Protected/Admin/InvigilatorAllocation/UpdateDocument")
);
const ActiveInvigilator = lazy(
  () =>
    import("../../core/Protected/Admin/InvigilatorAllocation/ActiveInvigilator")
);
const InvigilatorDocument = lazy(
  () =>
    import("../../core/Protected/Admin/InvigilatorAllocation/InvigilatorList")
);
const InvigilatorAllocatedList = lazy(
  () =>
    import("../../core/Protected/Admin/InvigilatorAllocation/InvigilatorAllocatedList")
);
const ExamCenterList = lazy(
  () =>
    import("../../core/Protected/Admin/InvigilatorAllocation/ExamCenterList")
);
const AssignInvigilator = lazy(
  () =>
    import("../../core/Protected/Admin/InvigilatorAllocation/AssignExamCenter")
);
const AssignExamCenter = lazy(
  () => import("../../core/Protected/Dashboard/ExamCenter/AssignExamCenter")
);
const InvigilatorList = lazy(
  () => import("../../core/Protected/Dashboard/ExamCenter/InvigilatorList")
);
const AssignedExamCenterList = lazy(
  () =>
    import("../../core/Protected/Dashboard/ExamCenter/AssignedExamCenterList")
);
const RoomAllocation = lazy(
  () => import("../../core/Protected/Admin/Room/RoomAllocation")
);
const ExamCenterRooms = lazy(
  () => import("../../core/Protected/Admin/Room/ExamCenterRooms")
);
const AssignRoom = lazy(
  () => import("../../core/Protected/Admin/Room/AssignRoom")
);
const ExamCenterAllocationReport = lazy(
  () => import("../../core/Protected/Admin/report/ExamCenterAllocationReport")
);

const GenerateExamCenter = lazy(
  () => import("../../core/Protected/Admin/report/GenerateExamCenter")
);
const RoomGatePratiReport = lazy(
  () => import("../../core/Protected/Admin/report/roomGatePratiReport")
);
const InvigilatorReport = lazy(
  () => import("../../core/Protected/Admin/report/invigilatorReport")
);
const SeatAllocationReport = lazy(
  () => import("core/Protected/Admin/report/SeatAllocationReport")
);
const FrontPageGeneration = lazy(
  () =>
    import(
      "../../core/Protected/Admin/FrontPaperGeneration/FrontPageGeneration"
    )
);
const ExamCenterReportsList = lazy(
  () => import("../../core/Protected/Admin/report/ExamCenterReportsList")
);
const DownloadAdmitCard = lazy(
  () => import("../../core/Protected/Admin/DownloadAdmitCard/DownloadAdmitCard")
);
const ApplicationStatus = lazy(
  () => import("../../core/Protected/Admin/ApplicationStatus")
);
const Notice = lazy(() => import("../../core/Protected/Admin/Notice/Notice"));
const FullUserDetails = lazy(
  () =>
    import(
      "../../core/Protected/Admin/UserManagement/Internal User Management/FullUserDetails"
    )
);
const UserSearch = lazy(
  () => import("../../core/Protected/Admin/SearchApplicant/UserSearch")
);
const TotalRegisterUser = lazy(
  () => import("../../core/Protected/Admin/TotalUserList/TotalUserList")
);
const UpdatePayment = lazy(
  () => import("../../core/Protected/Admin/AdminEdbpCode/UpdatePayment")
);
const Payment = lazy(
  () => import("../../core/Protected/Admin/AdminEdbpCode/Payment")
);
const VacancyDistributionReportList = lazy(
  () =>
    import(
      "../../core/Protected/Admin/vacancyDistributionHistoryReport/VacancyDistributionReportList"
    )
);
const VacancyDistributionReportReport = lazy(
  () =>
    import(
      "../../core/Protected/Admin/vacancyDistributionHistoryReport/vacancyDistributionHistoryReport"
    )
);
const VacancyDistributionReportReportPromotion = lazy(
  () =>
    import(
      "../../core/Protected/Admin/vacancyDistributionHistoryReport/Promotion"
    )
);
const VacancyDetailReport = lazy(
  () =>
    import(
      "../../core/Protected/Admin/vacancyDistributionHistoryReport/VacancyDetails"
    )
);
const LocalLevel = lazy(
  () =>
    import(
      "../../core/Protected/Admin/vacancyDistributionHistoryReport/LocalLevel"
    )
);
const AdminEdbpCode = lazy(
  () => import("../../core/Protected/Admin/AdminEdbpCode/AdminEbpCode")
);
const RenameEbpCode = lazy(
  () => import("../../core/Protected/Admin/AdminEdbpCode/ReplaceEbpCode")
);
const VerifyEbpCode = lazy(
  () => import("../../core/Protected/Admin/AdminEdbpCode/VerifyEbpCode")
);
const VerifyPayment = lazy(
  () => import("../../core/Protected/Admin/AdminEdbpCode/VerifyPayment")
);

const SubExamCenter = lazy(
  () => import("../../core/Protected/Admin/MasterTabs/SubExamCenter")
);

const AttendanceUpload = lazy(
  () => import("../../core/Protected/Admin/PostExamination/AttendanceUpload")
);
// const FinalSelection = lazy(
//   () => import("../../core/Protected/Admin/FinalSelection/FinalSelection")
// );
const ResultUpload = lazy(
  () => import("../../core/Protected/Admin/PostExamination/ResultUpload")
);

const PostResultMain = lazy(() => import("../../core/Protected/Admin/PostResult/index"))
const ViewReport = lazy(() => import("../../core/Protected/Admin/PostResult/ViewResult"))
const viewAttendance = lazy(() => import("../../core/Protected/Admin/PostResult/viewAttendance"))

const reportByGenderindex = lazy(() => import("../../core/Protected/Admin/Scrutiny/ReportByGender/index"))

const UserRoute: route[] = [
  {
    path: "/home",
    component: Dashboard,
    type: "public",
  },
  {
    path: "/user/profile",
    component: MyProfile,
    type: "public",
    children: [
      {
        path: "/user/profile/personal",
        component: PersonalDetails,
      },
      {
        path: "/user/profile/contact",
        component: Contact,
      },
      {
        path: "/user/profile/education",
        component: Education,
      },
      {
        path: "/user/profile/training",
        component: Training,
      },
      {
        path: "/user/profile/professional",
        component: Professional,
      },
      {
        path: "/user/profile/extra",
        component: Extra,
      },
      {
        path: "/user/profile/experience",
        component: Experience,
      },
      {
        path: "/user/profile/documents",
        component: Documents,
      },
      {
        path: "/user/profile/preview",
        component: Preview,
      },
    ],
  },
  {
    path: "/vacancy-applied/approved",
    component: ApprovedAdmitCard,
    type: "public",
  },
  {
    path: "/vacancy-applied",
    component: IndividualApplication,
    type: "public",
  },
  {
    path: "/voucher-upload/:id/:advtCode/:applydate/:masterid/:bankCode",
    component: VoucherUpload,
    type: "public",
  },
  {
    path: "/vacancy/:id/:masterid/:reqid/:values",
    component: Detail,
    type: "public",
  },
  // {
  //   path: "/rmis/payment/logo",
  //   component: ApplicationLogo,
  //   type: "public",
  // },
  {
    path: "/rmis/payment/success",
    component: ApplicationSuccess,
    type: "public",
  },
  {
    path: "/rmis/payment/failure",
    component: ApplicationFailure,
    type: "public",
  },
  {
    path: "/vacancy",
    component: List,
    type: "public",
  },
];

const AdminRoute: route[] = [
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/admin/user-search",
    component: UserSearch,
  },
  {
    path: "/admin/total-register-user",
    component: TotalRegisterUser,
  },
  {
    path: "/admin/application-status",
    component: ApplicationStatus,
  },
  {
    path: "/admin/requisition",
    component: RequisitionForm,
    privilege: [PrivilegeModule.RQ],
  },
  {
    path: "/admin/approval",
    component: ApprovalTask,
    privilege: [PrivilegeModule.RQ, PrivilegeModule.PD],
    children: [
      {
        path: "/admin/approval/requisition",
        component: ApprovalRequisition,
        privilege: [PrivilegeModule.RQ],
      },
      {
        path: "/admin/approval/percentage-distribution",
        component: PercentageDistributionApproval,
        privilege: [PrivilegeModule.PD],
      },
    ],
  },
  {
    path: "/admin/percentage-distribution",
    component: PercentageDistributionRequest,
    privilege: [PrivilegeModule.PD],
  },
  ...basicConfigurationConfigRoute,
  ...reportByGenderConfigurationConfigRoute,
  {
    path: "/admin/examCenterConfigure/sub-examCenter-config/:id/:parent",
    component: SubExamCenter,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/exam-center-list-report/:id",
    component: ExamCenterReportsList,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/exam-center",
    component: ExamCenter,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/assigned-exam-center/:id/:advtCode",
    component: AssignedExamCenterList,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/generate-exam-center-report",
    component: GenerateExamCenter,
    privilege: [PrivilegeModule.PEC],
    children: [
      {
        path: "/admin/generate-exam-center-report/prati-report",
        component: RoomGatePratiReport,
        privilege: [PrivilegeModule.PEC],
      },
      {
        path: "/admin/generate-exam-center-report/invigilator-allocation-report",
        component: InvigilatorReport,
        privilege: [PrivilegeModule.PEC],
      },
      {
        path: "/admin/generate-exam-center-report/seat-allocation-report",
        component: SeatAllocationReport,
        privilege: [PrivilegeModule.PEC],
      },
    ],
  },
  {
    path: "/admin/front-paper-generation",
    component: FrontPageGeneration,
    privilege: [PrivilegeModule.MC],
  },

  {
    path: "/admin/exam-center-report/:id",
    component: ExamCenterAllocationReport,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/room-allocation",
    component: RoomAllocation,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/exam-center-rooms/:id/:approvedCount",
    component: ExamCenterRooms,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/assign-rooms/:id/:examStatus",
    component: AssignRoom,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/invigilator-list",
    component: InvigilatorList,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/invigilator-post-list",
    component: InvigilatorPostList,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/invigilator-post",
    component: InvigilatorPost,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/exam-center-allocation",
    component: ExamCenterAllocationIndex,
    privilege: [PrivilegeModule.ES],
    children: [
      {
        path: "/admin/exam-center-allocation/allocate",
        component: AllocateNewExamCenter,
        privilege: [PrivilegeModule.ES],
      },
      // {
      //   path: "/admin/exam-center-allocation/list",
      //   component: ExamCenterAllocation,
      //   privilege: [PrivilegeModule.ES],
      // },
      {
        path: "/admin/exam-center-allocation/allocatedCandidate",
        component: AllocatedCandidateList,
        privilege: [PrivilegeModule.ES],
      },
      {
        path: "/admin/exam-center-allocation/archiveUser",
        component: ArchivedCandidateList,
        privilege: [PrivilegeModule.ES],
      },
    ],
  },
  {
    path: "/admin/invigilator-allocation",
    component: InvigilatorAllocation,
    privilege: [PrivilegeModule.PEC],
    children: [
      {
        path: "/admin/invigilator-allocation/upload-document",
        component: UpdateDocument,
        privilege: [PrivilegeModule.PEC],
      },
      {
        path: "/admin/invigilator-allocation/allocate-invigilator",
        component: ActiveInvigilator,
        privilege: [PrivilegeModule.PEC],
      },
      {
        path: "/admin/invigilator-allocation/invigilatorList",
        component: InvigilatorDocument,
        privilege: [PrivilegeModule.PEC],
      },
      {
        path: '/admin/invigilator-allocation/invigilator-allocated-list',
        component: InvigilatorAllocatedList,
        privilege: [PrivilegeModule.PEC]
      }
    ],
  },
  {
    path: "/admin/invigilator",
    component: Invigilator,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/exam-center-list/:id",
    component: ExamCenterList,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/center-list",
    component: CenterList,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/assign-invigilator/:id/:examStatus/:advCode",
    component: AssignInvigilator,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/assign-exam-center",
    component: AssignExamCenter,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/exam-scheduling",
    component: ExamScheduling,
    privilege: [PrivilegeModule.ES],
  },
  {
    path: "/admin/schedule-exam",
    component: ScheduleExam,
    privilege: [PrivilegeModule.ES],
  },
  {
    path: "/admin/paper-configuration",
    component: PaperConfiguration,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/generate/form",
    component: GenerateFormate,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/paper-detail/:id",
    component: PaperDetail,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/academic-qualification",
    component: AcademicQualification,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/create-qualification/:id",
    component: CreateQualification,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/view-qualification/:id",
    component: ViewQualification,
    privilege: [PrivilegeModule.MC],
  },
  // {
  //     path: '/admin/review-examscheduling',
  //     component: ReviewExamScheduling,
  // },
  {
    path: "/admin/publish-advertisement",
    component: PublishAdvertisement,
    privilege: [PrivilegeModule.PA],
  },
  {
    path: "/admin/selective-subject/:id/:advtCode",
    component: SelectiveSubject,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/download/admitCard",
    component: DownloadAdmitCard,
    privilege: [PrivilegeModule.DA],
  },
  {
    path: "/admin/ageLimit",
    component: AgeLimit,
    privilege: [PrivilegeModule.ALC],
  },
  {
    path: "/admin/ageLimitConfigure/:data",
    component: AgeLimitConfigure,
    privilege: [PrivilegeModule.ALC],
  },
  {
    path: "/admin/combinePost",
    component: CombinePostConfiguration,
    privilege: [PrivilegeModule.ALC],
  },
  {
    path: "/admin/vacancy-distribution-report",
    component: VacancyDistributionReportList,
    privilege: [PrivilegeModule.VDH],
    children: [
      {
        path: "/admin/vacancy-distribution-report/report",
        component: VacancyDistributionReportReport,
        privilege: [PrivilegeModule.VDH],
      },
      {
        path: "/admin/vacancy-distribution-report/promotion",
        component: VacancyDistributionReportReportPromotion,
        privilege: [PrivilegeModule.VDH],
      },
      {
        path: "/admin/vacancy-distribution-report/local-level",
        component: LocalLevel,
        privilege: [PrivilegeModule.VDH],
      },
      {
        path: "/admin/vacancy-distribution-report/vacancyDetailReport",
        component: VacancyDetailReport,
        privilege: [PrivilegeModule.VDH],
      },
    ],
  },
  {
    path: "/admin/advertisementlist",
    component: PublishedAdvertisement,
  },
  {
    path: "/admin/scrutiny-allocation/:id",
    component: Allocation,
    privilege: [PrivilegeModule.SA],
  },
  {
    path: "/admin/scrutiny-reallocation/:id",
    component: Allocation,
    privilege: [PrivilegeModule.SA],
  },
  {
    path: "/admin/scrutiny-allocation",
    component: ScrutinyAllocation,
    privilege: [PrivilegeModule.SA],
  },
  {
    path: "/admin/scrutiny/report",
    component: Report,
    privilege: [PrivilegeModule.SD],
  },
  {
    path: "/admin/scrutiny/advertisementReport",
    component: AdvertisementApplicationReport,
    privilege: [PrivilegeModule.SD],
  },
  {
    path: "/admin/scrutiny/report-by-gender-basic-configuration",
    component: reportByGenderindex,
    privilege: [PrivilegeModule.SD],
  },
  {
    path: "/admin/allocation/report",
    component: AllocationReport,
    privilege: [PrivilegeModule.SA],
  },
  {
    path: "/admin/allocation/report-individual/:userDetails",
    component: IndividualAllocationReport,
    privilege: [PrivilegeModule.SD],
  },
  {
    path: "/admin/scrutiny/:id/:type/:currentPage",
    // path: "/admin/scrutiny/:id/:type/:currentPage",
    component: ScrutinyCandidates,
    privilege: [PrivilegeModule.SC],
  },

  {
    path: "/admin/scrutiny/:id/:type/:currentPage",
    component: ScrutinyCandidates,
    privilege: [PrivilegeModule.SC],
  },

  {
    path: "/admin/scrutiny",
    component: Scrutiny,
    privilege: [PrivilegeModule.SC],
  },
  {
    // path: "/admin/candidate-details/:advertisementCode/:studentId/:status/:currentpage",
    path: "/admin/candidate-details/:advertisementCode/:studentId/:status",
    component: CandidateDetails,
    privilege: [PrivilegeModule.SC],
  },
  {
    path: "/admin/modulelist",
    component: ModuleLists,
    privilege: [PrivilegeModule.UM],
  },
  {
    path: "/admin/rolelist",
    component: RoleLists,
    privilege: [PrivilegeModule.UM],
  },
  {
    path: "/admin/rolecreation",
    component: NewRoleModuleCreation,
    privilege: [PrivilegeModule.UM],
  },
  {
    path: "/admin/approve-exam-schedule",
    component: ApproveExamSchedule,
    privilege: [PrivilegeModule.ES],
  },
  {
    path: "/admin/approval-exam-schedule/:id",
    component: ApprovalExamSchedule,
    privilege: [PrivilegeModule.UM],
  },
  {
    path: "/admin/usercreation/:id",
    component: InternalUserAdd,
    privilege: [PrivilegeModule.UM],
  },
  {
    path: "/admin/usercreation",
    component: InternalUserAdd,
    privilege: [PrivilegeModule.UM],
  },
  {
    path: "/admin/userlist",
    component: InternalUserList,
    privilege: [PrivilegeModule.UM],
  },
  {
    path: "/admin/internal/passwordreset",
    component: PasswordReset,
    privilege: [PrivilegeModule.PUM],
  },
  {
    path: "/admin/userDetails",
    component: ProfileManagement,
    privilege: [PrivilegeModule.PUM],
  },
  {
    path: "/admin/payment",
    component: Payment,
    privilege: [PrivilegeModule.UP],
    children: [
      {
        path: "/admin/payment/update",
        component: UpdatePayment,
        privilege: [PrivilegeModule.UP],
      },
      {
        path: "/admin/payment/ebpCode",
        component: AdminEdbpCode,
        privilege: [PrivilegeModule.UP],
      },
      {
        path: "/admin/payment/renameEbpCode",
        component: RenameEbpCode,
        privilege: [PrivilegeModule.UP],
      },
      {
        path: "/admin/payment/verifyEbp",
        component: VerifyEbpCode,
        privilege: [PrivilegeModule.UP],
      },
      {
        path: "/admin/payment/verifyPayment",
        component: VerifyPayment,
        privilege: [PrivilegeModule.UP],
      },
    ],
  },
  {
    path: "/admin/fullDetails",
    component: FullUserDetails,
    privilege: [PrivilegeModule.PUM],
  },
  {
    path: "/admin/notice",
    component: Notice,
    privilege: [PrivilegeModule.MC],
  },
  {
    path: "/admin/percentage-distribution-activity",
    component: PercentageDistributionActivity,
    privilege: [PrivilegeModule.MC],
  },

  {
    path: "/admin/usermanagement",
    component: UserManagement,
    privilege: [PrivilegeModule.PM, PrivilegeModule.LUP],
    children: [
      {
        path: "/admin/usermanagement/profile",
        component: LockUnlock,
        privilege: [PrivilegeModule.PM],
        children: [
          {
            path: "/admin/usermanagement/profile/lock",
            component: LockProfile,
            privilege: [PrivilegeModule.LUP],
          },
          {
            path: "/admin/usermanagement/profile/unlock",
            component: UnlockProfile,
            privilege: [PrivilegeModule.LUP],
          },
        ],
      },
      {
        path: "/admin/usermanagement/user",
        component: BanOrRestoreUser,
        privilege: [PrivilegeModule.UEB],
        children: [
          {
            path: "/admin/usermanagement/user/ban",
            component: BanUser,
            privilege: [PrivilegeModule.UEB],
          },
          {
            path: "/admin/usermanagement/user/unBan",
            component: UnBanUser,
            privilege: [PrivilegeModule.UEB],
          },
        ],
      },
      {
        path: "/admin/usermanagement/voucher",
        component: VoucherManagement,
        privilege: [PrivilegeModule.VLU],
      },
    ],
  },
  {
    path: "/admin/upload-attendance",
    component: AttendanceUpload,
  },
  // {
  //   path: "/admin/final-selection",
  //   component: FinalSelection
  // },

  {
    path: "/admin/upload-result",
    component: ResultUpload,
  },
  {
    path: "/admin/post-result",
    component: PostResultMain,
    children: [
      {
        path: "/admin/post-result/result",
        component: ViewReport
      },
      {
        path: "/admin/post-result/view-attendance",
        component: viewAttendance
      }
    ],
  },
  ...percentageDistributeConfigRoute,
  ...provinceLevelDistributionConfigRoute,
  ...percentageDistributeHistoryRoute,
];

export const appRoutes: route[] = [
  {
    path: "/login",
    component: Login,
    type: "unauthorized",
  },
  {
    path: "/faq",
    component: Faq,
    type: "unauthorized",
  },
  {
    path: "/register",
    component: Register,
    type: "unauthorized",
  },
  {
    path: "/change/password/:tokenid",
    component: ResetPassword,
    type: "open",
  },
  {
    path: "/",
    component: Boundary,
    children: [...UserRoute, ...AdminRoute],
  },
  // {
  //     path: '/',
  //     component: UnderConstruction,
  //     type: 'open'
  // }  
];
