import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { defaultState } from "../../../helper/default-state";

const obj  = defaultState;
const apiDetails = apiList.internal.admin.assignExamCenter.finalSave

export default function finalSaveReducer(store={...obj},action) {
    const state = Object.assign({}, store)
    const actionName = apiDetails.actionName
    return defaultReducer(actionName,action,state)
}

export const finalSaveAction = (reqData) => async (dispatch) => {
    const res = await createDefaultAction(apiDetails,dispatch,reqData,"POST")
    return res
}