import { combineReducers } from 'redux';
import approveOrRejectReducer from './approveOrRejectDistbtn';
import distributionByRequisitionIdReducer from './findDistByRequisitionId';
import distributionForApprovalReducer from './findDistributionForApproval';
import generateAdvNumberReducer from './generateAdvNo';
import allPercentageDistributionReducer from './getAllPercentDistbtn';
import savePercentageDistributionReducer from './savePercentDistbtn';
import autoGenerateDistributionQuotaReducer from './auto-generate-distribution-quota';


const percentageDistributionReducer = combineReducers({
  allPercentageDistribution: allPercentageDistributionReducer,
  generateAdvNumberData: generateAdvNumberReducer,
  savePercentageDistribution: savePercentageDistributionReducer,
  distributionByRequisitionId: distributionByRequisitionIdReducer,
  distributionForApproval: distributionForApprovalReducer,
  approveOrRejectData: approveOrRejectReducer,
  autoGenerateDistributionQuotaData: autoGenerateDistributionQuotaReducer
});

export default percentageDistributionReducer;
