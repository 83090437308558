import {defaultState} from '../../helper/default-state';
import {defaultReducer} from '../../helper/default-reducer';
import {createDefaultAction} from '../../helper/default-action';
import {apiList} from '../.././actionNames';

const obj = defaultState;
const apiDetails = apiList.internal.admin.addAdminClass;

export default function addClassReducer(store={...obj}, action){
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const addClassAction = (reqData: any):any=> async (dispatch)=>{
    const resData = await createDefaultAction(apiDetails,dispatch, reqData, "POST");
    return resData;
}
