import { apiList } from "../../../actionNames";
import { createDefaultAction } from "../../../helper/default-action";
import { defaultReducer } from "../../../helper/default-reducer";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.listrequisitiondetails;

export default function listRequisitionDetailsReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const listRequisitionDetails = (requestdata: string) => async dispatch => {
    const modifiedApiDetails = {...apiDetails, controllerName: apiDetails.controllerName.replace("{requisitionid}", requestdata)};
    return createDefaultAction(modifiedApiDetails, dispatch, null, "GET");
};