import { defaultState } from "../../helper/default-state";
import { defaultReducer } from "../../helper/default-reducer";
import { createDefaultAction } from "../../helper/default-action";
import { apiList } from "../../actionNames";
import { RequestNewPasswordData } from "../../../core/Public/Login/Login";

const obj = defaultState;
const apiDetails = apiList.psc.requestNewPassword;

export default function requestNewPasswordReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const requestNewPassword = (requestData: RequestNewPasswordData) => async dispatch => {
    return createDefaultAction(apiDetails, dispatch, requestData, "POST", true);
};