import { apiList } from "store/actionNames";
import { createDefaultAction } from "store/helper/default-action";
import { defaultReducer } from "store/helper/default-reducer";
import { defaultState } from "store/helper/default-state";


const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.getVacancyLimitPhase1ByClassService


export default function getVacancyLimitPhase1ByClassServiceReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getVacancyLimitPhase1ByClassService = (params: any
) => async dispatch => {
  const newApiDetails = { ...apiDetails };
  console.log(params, 'params');
  newApiDetails.controllerName = newApiDetails.controllerName
    .replace("{classId}", params.classId?.toString())
    .replace("{serviceId}", params.serviceId?.toString())
    .replace("{postId}", params.postId?.toString())
    .replace("{distributionType}", params.distributionType?.toString())
  return createDefaultAction(newApiDetails, dispatch, null, "GET");
};