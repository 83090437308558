import { combineReducers } from 'redux';
import saveExamCenterReducer from './addExamCenter';
import getDeleteExamCenterReducer from './examCenterDelete';
import filterOrGetAllExamCenterReducer from './filterOrGetAllExamCenter';
import getExamCenterReducer from './getExamCenter';
import getExamCenterByIdReducer from './getExamCenterById';
import fetchChildExamCenterListReducer from './subExamCenterList';


const ExamCenterReducer = combineReducers({
    saveExamCenterReducer: saveExamCenterReducer,
    getExamCenterReducer: getExamCenterReducer,
    getExamCenterByIdReducer: getExamCenterByIdReducer,
    getDeleteExamCenterReducer: getDeleteExamCenterReducer,
    fetchChildExamCenterListData: fetchChildExamCenterListReducer,
    filterOrGetAllExamCenterData: filterOrGetAllExamCenterReducer
})

export default ExamCenterReducer