import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.userManagement.changeUserListPassword;

export default function saveInternalUserPasswordReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const saveInternalUserPassword = (reqData: any): any => async (
  dispatch
) => {
  return createDefaultAction(apiDetails, dispatch, reqData, "POST");
};
