import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enAgeLimit from './locale/AgeLimit/en.json';
import neAgeLimit from './locale/AgeLimit/ne.json';
import enAllocatedExamCenter from './locale/allocatedExamCenter/en.json';
import neAllocatedExamCenter from './locale/allocatedExamCenter/ne.json';
import enApproveExamSchedule from './locale/approveExamSchedule/en.json';
import neApproveExamSchedule from './locale/approveExamSchedule/ne.json';
import enApprovePercentage from "./locale/approvePercentage/en.json";
import neApprovePercentage from "./locale/approvePercentage/ne.json";
import enApproveRequisition from './locale/approverequisition/en.json';
import neApproveRequisition from './locale/approverequisition/ne.json';
import enChangepassword from "./locale/changepassword/en.json";
import neChangepassword from "./locale/changepassword/ne.json";
import enContact from "./locale/contactDetails/en.json";
import neContact from "./locale/contactDetails/ne.json";
import enCurriculum from "./locale/curriculumConfiguration/en.json";
import neCurriculum from "./locale/curriculumConfiguration/ne.json";
import enDashboard from "./locale/dashboard/en.json";
import neDashboard from "./locale/dashboard/ne.json";
import enDocuments from "./locale/Documents/en.json";
import neDocuments from "./locale/Documents/ne.json";
import enEducation from "./locale/educationDetails/en.json";
import neEducation from "./locale/educationDetails/ne.json";
// english translation files
import translationEn from './locale/en.json';
import enExamScheduling from "./locale/examScheduling/en.json";
import neExamScheduling from "./locale/examScheduling/ne.json";
import enExperience from "./locale/experienceDetails/en.json";
import neExperience from "./locale/experienceDetails/ne.json";
import enExtra from "./locale/extraDetails/en.json";
import neExtra from "./locale/extraDetails/ne.json";
import enFAQ from "./locale/faq/en.json";
import neFAQ from "./locale/faq/ne.json";
import enGenerateExamSheet from './locale/generateExamSheet/en.json';
import neGenerateExamSheet from './locale/generateExamSheet/ne.json';
import enHeader from "./locale/header/en.json";
import neHeader from "./locale/header/ne.json";
import enLogin from "./locale/login/en.json";
import neLogin from "./locale/login/ne.json";
import enMasterTabs from "./locale/masterTabs/en.json";
import neMasterTabs from "./locale/masterTabs/ne.json";
import enModal from "./locale/Modal/en.json";
import neModal from "./locale/Modal/ne.json";
import enpostExamination from './locale/postExamination/en.json';
// nepali translation files
import translationNe from './locale/ne.json';
import enNotice from './locale/notice/en.json';
import neNotice from './locale/notice/ne.json';
import enPaperConfiguration from './locale/paperConfiguration/en.json';
import nePaperConfiguration from "./locale/paperConfiguration/ne.json";
import enPercentageDistribution from "./locale/percentageDistribution/en.json";
import nePercentageDistribution from "./locale/percentageDistribution/ne.json";
import enDistributionConfig from './locale/percentageDistributionConfig/en.json';
import neDistributionConfig from './locale/percentageDistributionConfig/ne.json';
import enPersonalDetails from "./locale/personalDetails/en.json";
import nePersonalDetails from "./locale/personalDetails/ne.json";
import nepostExamination from './locale/postExamination/ne.json';
import enPreview from "./locale/preview/en.json";
import nePreview from "./locale/preview/ne.json";
import enProfessional from "./locale/professionalDetails/en.json";
import neProfessional from "./locale/professionalDetails/ne.json";
import enQualification from "./locale/qualification/en.json";
import neQualification from "./locale/qualification/ne.json";
import enRegister from './locale/register/en.json';
import neRegister from './locale/register/ne.json';
import enRequisition from './locale/requisition/en.json';
import neRequisition from './locale/requisition/ne.json';
import enReviewAdvertisement from "./locale/reviewadvertisement/en.json";
import neReviewAdvertisement from "./locale/reviewadvertisement/ne.json";
import enSchedulingExam from './locale/scheduleExam/en.json';
import neSchedulingExam from './locale/scheduleExam/ne.json';
import enScrutiny from "./locale/scrutiny/en.json";
import neScrutiny from "./locale/scrutiny/ne.json";
import enScrutinyCanditate from "./locale/scrutinyCandiateList/en.json";
import neScrutinyCanditate from "./locale/scrutinyCandiateList/ne.json";
import enSidebar from "./locale/sidebar/en.json";
import neSidebar from "./locale/sidebar/ne.json";
import enTraining from "./locale/training/en.json";
import neTraining from "./locale/training/ne.json";
import enUpdatePayment from "./locale/UpdatePayment/en.json";
import neUpdatePayment from "./locale/UpdatePayment/ne.json";
import enUserManagement from "./locale/usermanagement/en.json";
import neUserManagement from "./locale/usermanagement/ne.json";
import enUserSearch from './locale/userSearch/en.json';
import neUserSearch from './locale/userSearch/ne.json';
import enVacancy from "./locale/vacancy/en.json";
import neVacancy from './locale/vacancy/ne.json';
import enVacancyDistributuinHistory from './locale/vacationDistributuionHistory/en.json';
import neVacancyDistributuinHistory from './locale/vacationDistributuionHistory/ne.json';
import enVoucher from './locale/voucher/en.json';
import neVoucher from './locale/voucher/ne.json';



export const i18nLanguages = ["en", "ne"];

// the translation resources
const resources = {
    en: {
        common: translationEn,
        register: enRegister,
        personalDetails: enPersonalDetails,
        requisition: enRequisition,
        approverequisition: enApproveRequisition,
        contact: enContact,
        extra: enExtra,
        percentageDistribution: enPercentageDistribution,
        examScheduling: enExamScheduling,
        scheduleExam: enSchedulingExam,
        login: enLogin,
        header: enHeader,
        sidebar: enSidebar,
        changepassword: enChangepassword,
        approvePercentage: enApprovePercentage,
        qualification: enQualification,
        curriculum: enCurriculum,
        masterTabs: enMasterTabs,
        education: enEducation,
        professional: enProfessional,
        experience: enExperience,
        training: enTraining,
        documents: enDocuments,
        preview: enPreview,
        reviewadvertisement: enReviewAdvertisement,
        vacancy: enVacancy,
        modal: enModal,
        dashboard: enDashboard,
        faq: enFAQ,
        usermgmt: enUserManagement,
        paperConfiguration: enPaperConfiguration,
        scrutiny: enScrutiny,
        scrutinyCanditate: enScrutinyCanditate,
        voucher: enVoucher,
        generateExamSheet: enGenerateExamSheet,
        allocatedExamCenter: enAllocatedExamCenter,
        approveExamSchedule: enApproveExamSchedule,
        notice: enNotice,
        userSearch: enUserSearch,
        distributionConfig: enDistributionConfig,
        vacancyDistributionHistory: enVacancyDistributuinHistory,
        updatePayment: enUpdatePayment,
        postExamination: enpostExamination,
        ageLimit: enAgeLimit
    },
    ne: {
        common: translationNe,
        register: neRegister,
        personalDetails: nePersonalDetails,
        requisition: neRequisition,
        approverequisition: neApproveRequisition,
        contact: neContact,
        extra: neExtra,
        percentageDistribution: nePercentageDistribution,
        examScheduling: neExamScheduling,
        scheduleExam: neSchedulingExam,
        login: neLogin,
        header: neHeader,
        sidebar: neSidebar,
        changepassword: neChangepassword,
        approvePercentage: neApprovePercentage,
        qualification: neQualification,
        curriculum: neCurriculum,
        masterTabs: neMasterTabs,
        education: neEducation,
        professional: neProfessional,
        experience: neExperience,
        training: neTraining,
        documents: neDocuments,
        preview: nePreview,
        reviewadvertisement: neReviewAdvertisement,
        vacancy: neVacancy,
        modal: neModal,
        dashboard: neDashboard,
        faq: neFAQ,
        usermgmt: neUserManagement,
        paperConfiguration: nePaperConfiguration,
        scrutiny: neScrutiny,
        scrutinyCanditate: neScrutinyCanditate,
        voucher: neVoucher,
        generateExamSheet: neGenerateExamSheet,
        allocatedExamCenter: neAllocatedExamCenter,
        approveExamSchedule: neApproveExamSchedule,
        notice: neNotice,
        userSearch: neUserSearch,
        distributionConfig: neDistributionConfig,
        vacancyDistributionHistory: neVacancyDistributuinHistory,
        updatePayment: neUpdatePayment,
        postExamination: nepostExamination,
        ageLimit: neAgeLimit
    }
};

i18n
    // .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: 'ne',
        fallbackLng: 'ne',
        whitelist: ['ne', 'en'],
        ns: ['common'],
        resources,
        defaultNS: "common",
        fallbackNS: "common",
        // backend: {
        //     loadPath: process.env.REACT_APP_IAS_ENDPOINT + '/config/i18n/res/{{lng}}/{{ns}}',
        //     crossDomain: true
        // },

        keySeparator: ".", // we do use keys in form {t('messages.welcome')}
        // keySeparator: false, // we do not use keys in form {t('messages.welcome')}

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export const getTextByLanguage = (textEn: any, textNe: any): any => {
    switch (i18n.language) {
        case 'ne': return textNe;
        default: return textEn;
    }
}

export const timeoutLanguage = () => {
    switch (i18n.language) {
        case 'ne': return "सर्भरले प्रतिक्रिया दिन धेरै लामो समय लिइरहेको छ, कृपया केहि बेरमा पुन: प्रयास गर्नुहोस्!";
        default: return "Server is taking too long to respond, please try again in sometime!";
    }
}

export const noConnectionLanguage = () => {
    switch (i18n.language) {
        case 'ne': return "सर्भरले प्रतिक्रिया दिन धेरै लामो समय लिईरहेको छ, यो कम कनेक्टिभटी वा हाम्रो सर्भरहरूको साथ त्रुटि द्वारा हुन सक्छ। कृपया केहि बेरमा पुन: प्रयास गर्नुहोस्!";
        default: return "Server is taking too long to respond, this can be caused by either poor connectivity or an error with our servers. Please try again in a while!";
    }
}

export const noServerConnectionLanguage = () => {
    switch (i18n.language) {
        case 'ne': return "सर्भरले प्रतिक्रिया दिन धेरै लामो समय लिईरहेको छ, यो कम कनेक्टिभटी वा हाम्रो सर्भरहरूको साथ त्रुटि द्वारा हुन सक्छ। कृपया केहि बेरमा पुन: प्रयास गर्नुहोस्!";
        default: return "Server is taking too long to respond, this can be caused by either poor connectivity or an error with our servers. Please try again in a while!";
    }
}

const nepaliCount = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];
export const getNumberByLanguage = (enNumber: any) => {
    if (enNumber === null || enNumber === undefined) {
        return enNumber;
    }
    switch (i18n.language) {
        case 'ne': return enNumber.toLocaleString('hi').toString().split("").map((number: string) => nepaliCount[+number] ? nepaliCount[+number] : number);
        default: return enNumber.toLocaleString('hi');
    }
}

export const convertEngToNepNumber = (number: any) => {
    return number ? number.toString().split("").map((number: number) => nepaliCount[number] ? nepaliCount[+number] : number).join("") : number;
}
export const convertNepToEngNumber = (number: any) => {
    return number ? number.toString().split("").map((number: string) => nepaliCount.indexOf(number) > -1 ? nepaliCount.indexOf(number) : number).join("") : number;
}
export default i18n;