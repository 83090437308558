
import { defaultState } from 'store/helper/default-state';
import { apiList } from 'store/actionNames';
import { defaultReducer } from 'store/helper/default-reducer';
import { createDefaultAction } from 'store/helper/default-action';

export enum StatusType {
  active = 'active',
  inactive = 'inactive',
  all = 'all'
}

const obj = defaultState;
const apiDetails = apiList.internal.admin.ministryConfiguration.getAllMinistry;

export default function getAllMinistryReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;
  return defaultReducer(actionName, action, state);
}

export const getAllMinistryAction = (status: StatusType): any => async (dispatch) => {
  const newApiDetails = { ...apiDetails }
  newApiDetails.controllerName = newApiDetails.controllerName.replace("{status}", status)
  const resData = await createDefaultAction(newApiDetails, dispatch, null, "GET")
  return resData;
}
