import { combineReducers } from "redux";
import getGenderOnlyReportReducer from "./genderOnlyReport/getGenderOnlyReportAction";
import getAreaReportReducer from "./areaReport/getAreaReportAction";
import getProvinceReportReducer from "./provinceReport/getProvinceReportAction";
import getReligionReportReducer from "./religionReport/getReligionReportAction";
import getDistrictReportReducer from "./districtReport/getDistrictReportAction";
import getEmploymentStatusReportReducer from "./employmentStatusReport/getEmploymentStatusReportAction";
import getPhysicallyChallengedReportReducer from "./physicallyChallengedReport/getPhysicallyChallengedReportAction";
import getCasteReportReducer from "./casteReport/getCasteReportAction";
import getQuotaReportReducer from "./quotaReport/getQuotaReportAction";
import getMaritalStatusReportReducer from "./maritalStatusReport/getMaritalStatusReportAction";
import getMotherTongueReportReducer from "./motherTongueReport/getMotherTongueReportAction";
import getEducationQualificationReportReducer from "./educationQualificationReport/getEducationQualificationReportAction";
import getClassReportReducer from "./classReport/getClassReportAction";


const reportByGenderReducer = combineReducers({
    getGenderOnlyReportData: getGenderOnlyReportReducer,
    getAreaReportData: getAreaReportReducer,
    getProvinceReportData: getProvinceReportReducer,
    getReligionReportData: getReligionReportReducer,
    getDistrictReportData: getDistrictReportReducer,
    getEmploymentStatusReportData: getEmploymentStatusReportReducer,
    getPhysicallyChallengedReportData: getPhysicallyChallengedReportReducer,
    getCasteReportData: getCasteReportReducer,
    getQuotaReportData: getQuotaReportReducer,
    getMaritalStatusReportData: getMaritalStatusReportReducer,
    getMotherTongueReportData: getMotherTongueReportReducer,
    getEducationQualificationReportData: getEducationQualificationReportReducer,
    getClassReportData: getClassReportReducer
})

export default reportByGenderReducer;