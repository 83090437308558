import {defaultState} from '../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer';
import {createDefaultAction} from '../../../helper/default-action';
import {apiList} from '../../../actionNames';
import { LockUserProfileData } from '../../../../core/Protected/Admin/UserManagement/LockUnlock/LockProfile';

const obj = defaultState;
const apiDetails = apiList.internal.admin.lockUserProfile;

export default function lockUserProfileReducer(store={...obj}, action){
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const lockUserProfile = (reqData: LockUserProfileData) => async (dispatch) => {
    const resData = await createDefaultAction(apiDetails,dispatch, reqData, "POST");
    return resData;
}

