import { Action, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkAction } from 'redux-thunk';
import { rootReducer, RootState } from "./rootReducer";

const middleware = applyMiddleware(thunk);

export const store = createStore(rootReducer, composeWithDevTools(middleware));
export type AppDispatch = typeof store.dispatch

/**
 * Redux Thunk Action extended with ReturnType
 */
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export type Dispatch = typeof store.dispatch
