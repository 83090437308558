import { defaultReducer } from "../../helper/default-reducer";
import { createDefaultAction } from "../../helper/default-action";
import { apiList } from "../../actionNames";
import { defaultState } from "../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.experience.government.deleteGovernmentList


export default function deleteGovernmentDetailsReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const deleteGovernmentDetail = (
  requestBody: any
) => async dispatch => {
  
  const newApiDetails = { ...apiDetails }
    newApiDetails.controllerName = apiDetails.controllerName.replace(`{id}`,requestBody)
    
    return createDefaultAction(newApiDetails, dispatch, null, "DELETE");
};