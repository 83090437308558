import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import './App.scss';
import { ToastNotificationContainer } from './components/ToastNotifier/ToastNotifier';
import AuthService from './services/AuthService/AuthService';
import { geti18nLanguage, switchI18nLanguage } from './store/modules/i18n/i18n';
import { RootState } from './store/rootReducer';
import { appRoutes } from './utils/routes';
import PrivateRoute from './utils/routes/PrivateRoute';


function App() {

    const { i18nextData } = useSelector((state: RootState) => ({ i18nextData: state.i18nextData }), shallowEqual)
    const dispatch = useDispatch();

    useEffect(() => {
        // Initialize i18n during mount as dynamic import
        // Synchronizing i18 state with redux state
        import('./utils/i18n/i18n').then(i18n => {
            const persistedLanguage = geti18nLanguage();

            if (persistedLanguage && persistedLanguage !== i18nextData.languageType) {
                dispatch(switchI18nLanguage(persistedLanguage));
                i18n.default.changeLanguage(persistedLanguage);
            }
            else if (i18n.default.language !== i18nextData.languageType) {
                i18n.default.changeLanguage(i18nextData.languageType);
            }
        });
    }, [i18nextData.languageType, dispatch])


    // Disable Console
    useEffect(() => {
        // Disable Console during production
        if (process.env.NODE_ENV === 'production') {
           
            window.console.log = window.console.debug = window.console.info = window.console.error = function () { return false; }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Remove Old local storage data for redux and token
    useEffect(() => {
        try {
            localStorage.removeItem("persist:ZXRhdHMtZG5ldG5vcmYtY3Nwcw==");
            localStorage.removeItem("persist:d2VuLWV0YXRzLWRuZXRub3JmLWNzcHM=");
            localStorage.removeItem("access_token");
        } catch (e) {
            console.warn("Error removing lcoal items", e);
        }

    }, [])

    return (
        <>
            <HashRouter>
                <AuthService>
                    {({ isAuthenticated, currentPath, match }) => {
                        return isAuthenticated() ?
                            <PrivateRoute
                                appRoutes={
                                    appRoutes
                                        .filter((route) => route.type !== "unauthorized")
                                }
                            />
                            :
                            <PrivateRoute appRoutes={appRoutes.filter((route) => route.type && ["unauthorized", "open"].includes(route.type))}
                                redirectPath={[
                                    { from: "*", to: "/login" }
                                ]}
                            />
                    }}
                </AuthService>
            </HashRouter>

            <ToastNotificationContainer
                position="bottom-right"
                autoClose={4000}
                limit={6}
            />
        </>
    );
}

export default App;
