import { combineReducers } from 'redux';
import saveInvigilatorReducer from './addInvigilatorPost';
import getInvigilatorByIdReducer from './getInvigilatorById';
import getInvigilatorPostReducer from './getInvigilatorPost';


const invigilatorPostReducer=combineReducers({
    saveInvigilatorReducer:saveInvigilatorReducer,
    getInvigilatorPostReducer:getInvigilatorPostReducer,
    getInvigilatorByIdReducer:getInvigilatorByIdReducer
})

export default invigilatorPostReducer