import { defaultState } from "../../helper/default-state";
import { defaultReducer } from "../../helper/default-reducer";
import { createDefaultAction } from "../../helper/default-action";
import { apiList } from "../../actionNames";
import { ContactDetailsDataType } from "../../../core/Protected/Vacancy/MyProfile/Contact/Contact"

const obj = defaultState;
const apiDetails = apiList.psc.myProfile.saveContact

// Save Candidate Contact Details
export default function saveContactReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const saveContact = (
  requestBody: ContactDetailsDataType
) => async dispatch => {
    return createDefaultAction(apiDetails, dispatch, requestBody, "POST");
};