import { apiList } from "../../../actionNames";
import { createDefaultAction } from "../../../helper/default-action";
import { defaultReducer } from "../../../helper/default-reducer";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.scrutiny.getFiscalYear;

export default function getFiscalYearListReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const getFiscalYearList = () => dispatch => {
    const newApilist = { ...apiDetails }
    return createDefaultAction(newApilist, dispatch, null, "GET", true);
};