import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { defaultState } from "../../../helper/default-state";

const obj  = defaultState;
const apiDetails = apiList.internal.admin.assignExamCenter.reverseAllExamCenterById

export default function reverseAllExamCenterByIdReducer(store={...obj}, action) {
    const state = Object.assign({},store)
    const actionName = apiDetails.actionName
    return defaultReducer(actionName,action, state)
} 

export const reverseAllExamCenterByIdAction = (id:any) => async(dispatch) => {
    const newApidetails = {...apiDetails}
    newApidetails.controllerName = newApidetails.controllerName.replace("{id}",id)
    const res = await createDefaultAction(newApidetails,dispatch, null, "DELETE")
    return res
}