import { combineReducers } from 'redux';
import getVacancyReducer from './Vacancy/getVacancyList';
import filterVacancyReducer from './Vacancy/filterVacancyList';
import getVacancyDetailReducer from './Vacancy/getVacancyDetail';
import applyVaccancyReducer from './Vacancy/applyVacancy';
import getQualificationDataReducer from './Vacancy/getQualificationData';
import getPaymentValueReducer from './Vacancy/getPaymentValue';
import getApplicationReducer from './Vacancy/getApplication';
import uploadVoucherReducer from './Vacancy/uploadVoucher';
import getExamCenterReducer from './Vacancy/getExamCenter';
import filterMyApplicationReducer from './Vacancy/filterMyApplication';
import getApplicationDetailReducer from './Vacancy/getApplicationDetail';
import getPaymentAmountReducer from './Vacancy/getPaymentAmount';
import reApplyVaccancyReducer from './Vacancy/reApplyVacancy';
import mandatoryDocsReducer from './Vacancy/mandatoryDocs';
import getApprovedAdmitCardReducer from './Vacancy/getApprovedAdmitCard';
import getAllNoticeReducer from './Vacancy/getNotice';
import informationModalStateReducer from './informationModal';
import checkPaymentReducer from './Vacancy/checkPayment';

const externalReducer = combineReducers({
  getVacancy: getVacancyReducer,
  filterVacancy: filterVacancyReducer,
  getVacancyDetail: getVacancyDetailReducer,
  applyVaccancyReducer: applyVaccancyReducer,
  getQualificationDataReducer: getQualificationDataReducer,
  getPaymentValueReducer: getPaymentValueReducer,
  getApplication: getApplicationReducer,
  uploadVoucher: uploadVoucherReducer,
  getExamCenter: getExamCenterReducer,
  filterMyApplication: filterMyApplicationReducer,
  getApplicationDetail: getApplicationDetailReducer,
  getPaymentAmount: getPaymentAmountReducer,
  reApplyVaccancy: reApplyVaccancyReducer,
  mandatoryDocsReducer: mandatoryDocsReducer,
  getApprovedAdmitCardData: getApprovedAdmitCardReducer,
  getAllNotice: getAllNoticeReducer,
  informationModalStateData: informationModalStateReducer,
  checkPaymentData: checkPaymentReducer
});

export default externalReducer