import { defaultState } from "../../helper/default-state";
import { defaultReducer } from "../../helper/default-reducer";
import { createDefaultAction } from "../../helper/default-action";
import { apiList } from "../../actionNames";

const obj = defaultState;
const apiDetails = apiList.documents.getCandidateDetails

// Save Candidate Images Details
export default function getCandiateImageReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const getCandiateImage = (requestBody) => async dispatch => {
    const newApiDetails = { ...apiDetails }
    newApiDetails.controllerName = apiDetails.controllerName
        .replace(`{type}`, requestBody.type)
        .replace(`{ImageName}`, requestBody.imageName)
    return createDefaultAction(newApiDetails, dispatch, null, "GET");
};