import {defaultState} from '../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer';
import {createDefaultAction} from '../../../helper/default-action';
import {apiList} from '../../.././actionNames';

const obj = defaultState;
const apiDetails = apiList.external.vacancy.getPaymentValue;

export default function getPaymentValueReducer(store={...obj}, action){
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action,state);
}

export const getPaymentValueAction = (reqData:any):any=> async (dispatch)=>{
    const newApiDetails = {...apiDetails};
    newApiDetails.controllerName = newApiDetails.controllerName.replace('{id}',reqData)
    const resData = await createDefaultAction(newApiDetails, dispatch,null,"GET",true)
    return resData;
}
