import { apiList } from "store/actionNames";
import { createDefaultAction } from "store/helper/default-action";
import { defaultReducer } from "store/helper/default-reducer";
import { defaultState } from "store/helper/default-state";
import { phaseTypes } from "utils/constants";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.deletePhaseOneTableDataById

export interface FindFormulaPathVariable {
  tableId: string,
}

export default function deletePhaseOneTableDataByIdReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const deletePhaseOneTableDataById = (pathVariable: FindFormulaPathVariable, phase: phaseTypes
) => async dispatch => {
  const newApiDetails = { ...apiDetails };
  newApiDetails.controllerName = newApiDetails.controllerName.replace("{tableId}", pathVariable.tableId).replace("{phasetype}", phase)
  return createDefaultAction(newApiDetails, dispatch, null, "DELETE");
};