import { apiList } from "../../../../actionNames";
import { createDefaultAction } from "../../../../helper/default-action";
import { defaultReducer } from "../../../../helper/default-reducer";
import { defaultState } from "../../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.schedulePublishedAdvertisement;

export default function getSchedulePublishedAdvertisementReducer(
  store = { ...obj },
  action
) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;
  return defaultReducer(actionName, action, state);
}

export const getSchedulePublishedAdvertisement = () => async (dispatch) => {
  const resData = await createDefaultAction(apiDetails, dispatch, null, "GET");
  return resData;
};
