import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.ageLimit.findAllClassAndService

export default function findAllAgeReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store)
    const actionName = apiDetails.actionName
    return defaultReducer(actionName, action, state)
}

export const findAllClassAndServiceAction = () => async (dispatch) => {
    const res = await createDefaultAction(apiDetails, dispatch, null, "GET")
    return res
}