import { apiList } from '../../../actionNames';
import { createDefaultAction } from '../../../helper/default-action';
import { defaultReducer } from '../../../helper/default-reducer';
import { defaultState } from '../../../helper/default-state';

const obj = defaultState;
const apiDetails = apiList.internal.admin.filterOrGetAllExamCenter;
export interface FilterOrGetAllExamCenter {
    examCenterId: string;
    subExamCenter: string;
    status: string
}

export default function filterOrGetAllExamCenterReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const filterOrGetAllExamCenterAction = (reqData: FilterOrGetAllExamCenter) => async (dispatch) => {
    const newApiDetails = { ...apiDetails };
    newApiDetails.controllerName = newApiDetails.controllerName.replace('{examCenterId}', reqData.examCenterId)
        .replace("{subExamCenter}", reqData.subExamCenter).replace("{status}", reqData.status)
    return createDefaultAction(newApiDetails, dispatch, null, "GET")
}
