import { defaultState } from '../../helper/default-state';
import { defaultReducer } from '../../helper/default-reducer';
import { createDefaultAction } from '../../helper/default-action';
import { apiList } from '../../actionNames';
import { AppThunk, Dispatch } from 'store';

const obj = defaultState;
const apiDetails = apiList.internal.curriculumConfiguration.vacancyDistributionGenerate;

export default function vacancyDistributionGenerateReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const vacancyDistributionGenerateAction = (advtCode: string, distributionType: string): AppThunk<any> => async (dispatch: Dispatch) => {
    const newApi = { ...apiDetails }
    newApi.controllerName = newApi.controllerName.replace("{advtCode}", advtCode).replace("{distributionType}", distributionType)
    return createDefaultAction(newApi, dispatch, null, "GET")
}

