import { combineReducers } from 'redux'
import finalSaveReducer from './finalSave'
import getGeneratedAllocateListReducer from './get-generated-allocate-list'
import getAdvertisementListReducer from './getAdvertisementList'
import getAssignedExamCenterByIdReducer from './getAssignedExamCenterById'
import reverseAllExamCenterByIdReducer from './revertAssignedExamCenterById'
import saveAllocatedCandidateReducer from './save-allocated-candidate'
import saveTemporarlyReducer from './saveTemporary'

const AssigneExamCenter = combineReducers({
    getAdvertisementList: getAdvertisementListReducer,
    getAssignedExamCenterById: getAssignedExamCenterByIdReducer,
    saveTemporarly: saveTemporarlyReducer,
    reverseAllExamCenterById: reverseAllExamCenterByIdReducer,
    getGeneratedAllocateListData: getGeneratedAllocateListReducer,
    saveAllocatedCandidateData: saveAllocatedCandidateReducer,
    finalSave: finalSaveReducer
})

export default AssigneExamCenter