import { defaultState } from '../../../helper/default-state';
import { defaultReducer } from '../../../helper/default-reducer';
import { createDefaultAction } from '../../../helper/default-action';
import { apiList } from '../../../actionNames';

const obj = defaultState;
const apiDetails = apiList.internal.admin.combinePost.generateCombination;

export default function generateCombinationReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const generateCombinationAction = (reqData): any => async (dispatch) => {
    const resData = await createDefaultAction(apiDetails, dispatch, reqData, "POST");
    return resData;
}

