import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { phaseTypes } from 'utils/constants';

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.deletePhaseOneFormulaById

export interface FindFormulaPathVariable {
  formulaId: string,
}

export default function deletePhaseOneFormulaByIdReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const deletePhaseOneFormulaById = (pathVariable: FindFormulaPathVariable
  , phase: phaseTypes) => async dispatch => {
    const newApiDetails = { ...apiDetails };
    newApiDetails.controllerName = newApiDetails.controllerName.replace("{formulaId}", pathVariable.formulaId).replace("{phasetype}", phase)
    return createDefaultAction(newApiDetails, dispatch, null, "DELETE");
  };