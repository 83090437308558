import { apiList } from "../../../../../actionNames";
import { createDefaultAction } from "../../../../../helper/default-action";
import { defaultReducer } from "../../../../../helper/default-reducer";
import { defaultState } from "../../../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.getAllHeaderFormulaPhase4;



export default function getAllHeaderFormulaPhase4Reducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getAllHeaderFormulaPhase4 = (
) => async dispatch => {
  return createDefaultAction(apiDetails, dispatch, null, "GET");
};