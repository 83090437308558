import { combineReducers } from "redux";
import getDashboardListReducer from "./getDashboardDetails";
import adminDashboardCardReducer from "./getAdminDashboard";
import vacancyTableReducer from "./getAdminVacancyTable";


const dashboardReducer = combineReducers({
    getDashboardListReducer:getDashboardListReducer,
    vacancyTable:vacancyTableReducer,
    addminDashboardCard:adminDashboardCardReducer
})

export default dashboardReducer;