import { combineReducers } from "redux";
import saveCandiateImageReducer from "./documents";
import  getCandiateImage  from "./getDocuments";
import getCandidateDocsReducer from "./getCandidateDocs";

const saveCandiateReducer = combineReducers({
    saveCandiateImageReducer: saveCandiateImageReducer,
    getCandiateImage:getCandiateImage,
    getCandidateDocsReducer:getCandidateDocsReducer
})

export default saveCandiateReducer;