import { DefaultDispatchType } from '../redux.model';

export const createDispatchTypes = (actionName: string): DefaultDispatchType => {
    return {
        progressDispatch: actionName + "_PROGRESS",
        successDispatch: actionName + "_SUCCESS",
        failureDispatch: actionName + "_FAILURE",
        hideMessage: actionName + '_HIDE_MESSAGE',
        resetAll: actionName + '_RESET',
    }
}
