import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { saveDistributionPhaseOneType } from "./save-distribution-phase-one";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.saveDistributionPhaseOneAll

export default function saveDistributionPhaseOneAllReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const saveDistributionPhaseOneAll = (
  reqData: saveDistributionPhaseOneType[]
) => async dispatch => {
  return createDefaultAction(apiDetails, dispatch, reqData, "POST");
};