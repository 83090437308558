import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistribution.autoGenerateDistributionQuota

export default function autoGenerateDistributionQuotaReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const autoGenerateDistributionQuota = (
  requisitionId: string
) => async dispatch => {
  const newApiDetails = { ...apiDetails }
  newApiDetails.controllerName = newApiDetails.controllerName.replace(
    '{requisitionId}',
    requisitionId
  )
  return createDefaultAction(newApiDetails, dispatch, null, "GET");
};