import { createDefaultAction } from './../../../helper/default-action';
import { apiList } from './../../../actionNames/index';
import { defaultState } from './../../../helper/default-state';
import {defaultReducer} from './../../../helper/default-reducer'

const obj = defaultState
const apiDetail = apiList.internal.userManagement.userDetails;

export default function userDetailsReducer(store = {...obj}, action) {
    const state = Object.assign({},store)
    const actionName = apiDetail.actionName
    return defaultReducer(actionName,action,state)
}

export const userDetails = (masterId:any) => async dispatch => {
    const newApiDetails = {...apiDetail}
    newApiDetails.controllerName = newApiDetails.controllerName.replace("{masterId}",masterId)
    return createDefaultAction(newApiDetails,dispatch,null,"GET")
}
