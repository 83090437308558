import { combineReducers } from "redux";
import deleteCategoryIdPhase3Reducer from "./Category/deleteCategoryIdPhase3";
import fetchCategoryListPhase3Reducer from "./Category/fetchCategory";
import saveCategoryPhase3Reducer from "./Category/saveCategory";
import deleteFormulaByIdPhase3Reducer from "./FormulaValue/deleteFormulaById";
import findAllFormulaPhase3Reducer from "./FormulaValue/findAllFormula";
import getAllHeaderFormulaPhase3Reducer from "./FormulaValue/getAllHeader";
import saveFormulaPhase3Reducer from "./FormulaValue/saveFormula";
import deleteTableValueByIdPhase3Reducer from "./TableValue/deleteTableValue";
import findAllTableValuePhase3Reducer from "./TableValue/findAllTableValue";
import saveTableValuePhase3Reducer from "./TableValue/saveTableValue";

const phaseThreeReducer = combineReducers({
  findAllFormula: findAllFormulaPhase3Reducer,
  getAllHeader: getAllHeaderFormulaPhase3Reducer,
  saveFormula: saveFormulaPhase3Reducer,
  deleteFormula: deleteFormulaByIdPhase3Reducer,
  findAllTableValue: findAllTableValuePhase3Reducer,
  saveTableValue: saveTableValuePhase3Reducer,
  deleteTableValue: deleteTableValueByIdPhase3Reducer,
  saveCategoryValue: saveCategoryPhase3Reducer,
  fetchCategoryValue: fetchCategoryListPhase3Reducer,
  deleteCategoryValue: deleteCategoryIdPhase3Reducer

});

export default phaseThreeReducer;
