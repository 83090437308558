import Axios, { CancelTokenSource, Method } from 'axios';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import toast, { FailToast, SuccessToast } from '../../components/ToastNotifier/ToastNotifier';
import { makeApiRequest } from '../../services/ApiRequest/apiRequest';
import { apiDetailType, ResponseError } from '../../services/ApiRequest/apiRequest.model';
import TokenService from '../../services/AuthToken/authToken';
import { getTextByLanguage, noConnectionLanguage, noServerConnectionLanguage, timeoutLanguage } from '../../utils/i18n/i18n';
import { requestTimeout } from '../../utils/timeout/timeout';
import { RootState } from '../rootReducer';
import { createDispatchTypes } from './default-action-type';



let timeoutLanguageCount = 0;
let noServerConnectionLanguageCount = 0;
let noConnectionLanguageCount = 0;
let sessionExpiredLanguageCount = 0;
const axiosCancelSource = Axios.CancelToken.source();


export const createDefaultAction = async (apiDetails: apiDetailType, dispatch: ThunkDispatch<RootState, void, Action>, requestData: any, requestMethod: Method, disableToast?: boolean, cancelSource?: CancelTokenSource, params?: { [key: string]: any }, pathVariables?: { [key: string]: Primitive }) => {
    // Init Dispatch Types
    const dispatchTypes = createDispatchTypes(apiDetails.actionName);

    // Progress Dispatch
    dispatch({ type: dispatchTypes.progressDispatch, payload: null });

    // Check for path variables in controllername
    const sanitizedApiDetails = sanitizeController(apiDetails, pathVariables);

    const responseData = await makeApiRequest(sanitizedApiDetails, requestData, requestMethod, cancelSource || axiosCancelSource, params);
    // If Token Expired
    if ((responseData as ResponseError).isAxiosError && (responseData as ResponseError).response?.status === 401) {
        if (TokenService.getAccessToken()) {
            TokenService.clearToken();

            if (!sessionExpiredLanguageCount) {
                FailToast(getTextByLanguage("Your session has expired.", "तपाईको सत्रको समयावधि सकियो।"));
                sessionExpiredLanguageCount++;

                requestTimeout(() => {
                    sessionExpiredLanguageCount--;
                }, 500)
            }
        }
    }

    // Refresh Token Expired
    if ((responseData as ResponseError).logout) {
        TokenService.clearToken();
        // dispatch({ type: "USER_LOG_OUT", payload: null });
    }

    // Success Dispatch
    if ((responseData as ResponseError).data && !(responseData as ResponseError).isAxiosError) {
        dispatch({ type: dispatchTypes.successDispatch, payload: responseData.data, requestData: requestData });

        (!disableToast && requestMethod !== "GET") && SuccessToast(responseData.data?.message);
    }
    // Error Dispatch
    else if ((responseData as ResponseError).data && (responseData as ResponseError).isAxiosError) {
        dispatch({ type: dispatchTypes.failureDispatch, payload: responseData, requestData: requestData });
        if (responseData.data?.code === "INVALID_TOKEN") {
            // FailToast("Invalid refresh token (expired)");
        } else {
            FailToast(responseData.data?.message);
        }
    }

    // HideMessage Dispatch
    requestTimeout(() => {
        dispatch({ type: dispatchTypes.hideMessage, payload: null });
    }, 2000)


    // Axios Timeout
    if ((responseData as ResponseError).isAxiosError && (responseData.config as any)?.code === 'ECONNABORTED') {
        if (!timeoutLanguageCount) {
            toast.dismiss();
            FailToast(timeoutLanguage());
            timeoutLanguageCount++;

            requestTimeout(() => {
                timeoutLanguageCount--;
            }, 500)
        }
    }

    // No Server Connection
    if ((responseData as ResponseError).isAxiosError && (responseData as ResponseError).noconnection && (responseData as ResponseError).message === 'Server could not be reached') {
        if (!noServerConnectionLanguageCount) {
            toast.dismiss()
            FailToast(noServerConnectionLanguage());
            noServerConnectionLanguageCount++;

            requestTimeout(() => {
                noServerConnectionLanguageCount--;
            }, 500)
        }
    }
    // No Connection
    else if ((responseData as ResponseError).isAxiosError && (responseData as ResponseError).noconnection && (responseData.config as any)?.code !== 'ECONNABORTED') {
        if (!noConnectionLanguageCount) {
            toast.dismiss()
            FailToast(noConnectionLanguage());
            noConnectionLanguageCount++;

            requestTimeout(() => {
                noConnectionLanguageCount--;
            }, 500)
        }
    }

    return { ...responseData, data: { ...responseData.data, status: responseData.data?.status === 1 } };
};

function sanitizeController(apiDetail: apiDetailType, pathVariables?: { [key: string]: Primitive }) {
    return pathVariables && Object.keys(pathVariables).length ? {
        ...apiDetail,
        controllerName: Object.entries(pathVariables).reduce((acc, [key, value]) => acc = acc.replace(`{${key}}`, value.toString()), apiDetail.controllerName)
    }
        :
        apiDetail
}
