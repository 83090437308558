import {combineReducers} from 'redux';

import approveRequisitionReducer from './approve-requisition';
import listRequisitonPostByIdReducer from './get-requisition-by-id';
import getGroupSubgroupReducer from './get_group_subgroup_by_id';
import listRequisitionReducer from './list-requisition';
import listRequisitionDetailsReducer from './list-requisition-details';
import saveRequisitionReducer from './save-requisition';


const requisitionReducer = combineReducers({
  saveRequisitionData: saveRequisitionReducer,
  listRequisitionData: listRequisitionReducer,
  approveRequisitionData: approveRequisitionReducer,
  listRequisitionDetailsData: listRequisitionDetailsReducer,
  listRequisitonPostById: listRequisitonPostByIdReducer,
  getGroupSubgroup: getGroupSubgroupReducer,
});

export default requisitionReducer;
