import { defaultState } from "../../helper/default-state";
import { defaultReducer } from "../../helper/default-reducer";
import { createDefaultAction } from "../../helper/default-action";
import { apiList } from "../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.academicQualification.deleteQualification

export default function deleteQualificationReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const deleteQualification = (
  id: string
) => async dispatch => {
  const newApiDetails = {...apiDetails}
  newApiDetails.controllerName = newApiDetails.controllerName.replace(
    '{id}', id
  )
    return createDefaultAction(newApiDetails, dispatch, null, "DELETE");
};