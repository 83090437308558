import { combineReducers } from "redux";
import getTrainingListReducer from "./getTrainning";
import deleteTrainingListReducer from "./deleteTraining";
import editTrainingListReducer from "./editTraining";

const trainingReducer = combineReducers({
    trainingList: getTrainingListReducer,
    deleteTrainingList:deleteTrainingListReducer,
    editTrainingList:editTrainingListReducer

})

export default trainingReducer;