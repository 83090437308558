import { defaultState } from 'store/helper/default-state';
import { apiList } from 'store/actionNames';
import { defaultReducer } from 'store/helper/default-reducer';
import { createDefaultAction } from 'store/helper/default-action';
import { AppThunk } from 'store';


const obj = defaultState;
const apiDetails = apiList.internal.admin.ministryConfiguration.getMinistryByDistrict;



export default function getMinistryByDistrictReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;
  return defaultReducer(actionName, action, state);
}

export const getMinistryByDistrictAction = (pathVariables: { districtId: string | number }): AppThunk<Promise<any>> => async (dispatch) => {
  const resData = await createDefaultAction(apiDetails, dispatch, null, "GET", true, undefined, undefined, pathVariables);
  return resData;
}

