import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistribution.savePercentageDistribution

export default function savePercentageDistributionReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export interface savePercentDistDataType {
  requisitionId: number,
  quotaDetailsInPercentageDistributionDtoList : Array<{
    advertisementNo: any,
    quotaId: any,
    vacancy: any
  }>
}

export const savePercentageDistribution = (
  reqData: savePercentDistDataType
) => async dispatch => {
    return createDefaultAction(apiDetails, dispatch, reqData, "POST");
};