import { lazy } from "react";
import { PrivilegeModule } from "store/modules/init/init";

const ConfigurationPhase1 = lazy(
  () =>
    import(
      "../../core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase1/ConfigurationPhase1"
    )
);
const DistributionManualSetupPhase1 = lazy(
  () =>
    import(
      "../../core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase1/DistributionManualSetupPhase1"
    )
);
const VacancyFormulaSetupPhase1 = lazy(
  () =>
    import(
      "../../core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase1/VacancyFormulaSetupPhase1"
    )
);

const ConfigurationPhase3 = lazy(
  () =>
    import(
      "../../core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase3"
    )
);

const ConfigurationPhase4 = lazy(() => import("../../core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase4"))

const ConfigurationPhase2 = lazy(() => import('../../core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase2/ConfigurationPhase2'))
const DistributionManualSetupPhase2 = lazy(() => import('../../core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase2/DistributionManualSetupPhase2'))
const VacancyFormulaSetupPhase2 = lazy(() => import('../../core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase2/VacancyFormulaSetupPhase2'))
const ConfigurationPhase3DCategory = lazy(() => import("core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase3/ConfigurationPhase3DCategory"))
const ConfigurationPhase3DFormula = lazy(() => import("core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase3/ConfigurationPhase3DFormula"));
const ConfigurationPhase3DTable = lazy(() => import("core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase3/ConfigurationPhase3DTable"));

const ConfigurationPhase4DCategory = lazy(() => import("core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase4/ConfigurationPhase4DCategory"))
const ConfigurationPhase4DFormula = lazy(() => import("core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase4/ConfigurationPhase4DFormula"));
const ConfigurationPhase4DTable = lazy(() => import("core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase4/ConfigurationPhase4DTable"));

export const percentageDistributeConfigRoute = [
  {
    path: "/admin/distribution-formula-configuration-phase-1",
    component: ConfigurationPhase1,
    privilege: [PrivilegeModule.VDC],
    children: [
      {
        path: "/admin/distribution-formula-configuration-phase-1/manual/:configure",
        component: DistributionManualSetupPhase1,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/distribution-formula-configuration-phase-1/:configure",
        component: VacancyFormulaSetupPhase1,
        privilege: [PrivilegeModule.VDC],
      },
    ],
  },
  {
    path: "/admin/distribution-formula-configuration-phase-3",
    component: ConfigurationPhase3,
    privilege: [PrivilegeModule.VDC],
    children: [
      {
        path: "/admin/distribution-formula-configuration-phase-3/category-setup",
        component: ConfigurationPhase3DCategory,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/distribution-formula-configuration-phase-3/distribution-formula",
        component: ConfigurationPhase3DFormula,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/distribution-formula-configuration-phase-3/distribution-table",
        component: ConfigurationPhase3DTable,
        privilege: [PrivilegeModule.VDC],
      },
    ],
  },
  {
    path: "/admin/distribution-formula-configuration-phase-4",
    component: ConfigurationPhase4,
    privilege: [PrivilegeModule.VDC],
    children: [
      {
        path: "/admin/distribution-formula-configuration-phase-4/category-setup",
        component: ConfigurationPhase4DCategory,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/distribution-formula-configuration-phase-4/distribution-formula",
        component: ConfigurationPhase4DFormula,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/distribution-formula-configuration-phase-4/distribution-table",
        component: ConfigurationPhase4DTable,
        privilege: [PrivilegeModule.VDC],
      },
    ],
  },
  {
    path: "/admin/distribution-formula-configuration-phase-2",
    component: ConfigurationPhase2,
    privilege: [PrivilegeModule.VDC],
    children: [
      {
        path: "/admin/distribution-formula-configuration-phase-2/config",
        component: VacancyFormulaSetupPhase2,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/distribution-formula-configuration-phase-2/formula",
        component: DistributionManualSetupPhase2,
        privilege: [PrivilegeModule.VDC],
      }
    ]
  }
];

export const provinceLevelDistributionConfigRoute = [
  {
    path: "/admin/province/distribution-formula-configuration-phase-1",
    component: ConfigurationPhase1,
    privilege: [PrivilegeModule.VDC],
    children: [
      {
        path: "/admin/province/distribution-formula-configuration-phase-1/manual/:configure",
        component: DistributionManualSetupPhase1,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/province/distribution-formula-configuration-phase-1/:configure",
        component: VacancyFormulaSetupPhase1,
        privilege: [PrivilegeModule.VDC],
      },
    ],
  },
  {
    path: "/admin/province/distribution-formula-configuration-phase-3",
    component: ConfigurationPhase3,
    privilege: [PrivilegeModule.VDC],
    children: [
      {
        path: "/admin/province/distribution-formula-configuration-phase-3/category-setup",
        component: ConfigurationPhase3DCategory,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/province/distribution-formula-configuration-phase-3/distribution-formula",
        component: ConfigurationPhase3DFormula,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/province/distribution-formula-configuration-phase-3/distribution-table",
        component: ConfigurationPhase3DTable,
        privilege: [PrivilegeModule.VDC],
      },
    ],
  },
  {
    path: "/admin/province/distribution-formula-configuration-phase-4",
    component: ConfigurationPhase4,
    privilege: [PrivilegeModule.VDC],
    children: [
      {
        path: "/admin/province/distribution-formula-configuration-phase-4/category-setup",
        component: ConfigurationPhase4DCategory,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/province/distribution-formula-configuration-phase-4/distribution-formula",
        component: ConfigurationPhase4DFormula,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/province/distribution-formula-configuration-phase-4/distribution-table",
        component: ConfigurationPhase4DTable,
        privilege: [PrivilegeModule.VDC],
      },
    ],
  },
  {
    path: "/admin/province/distribution-formula-configuration-phase-2",
    component: ConfigurationPhase2,
    privilege: [PrivilegeModule.VDC],
    children: [
      {
        path: "/admin/province/distribution-formula-configuration-phase-2/config",
        component: VacancyFormulaSetupPhase2,
        privilege: [PrivilegeModule.VDC],
      },
      {
        path: "/admin/province/distribution-formula-configuration-phase-2/formula",
        component: DistributionManualSetupPhase2,
        privilege: [PrivilegeModule.VDC],
      }
    ]
  }
];

