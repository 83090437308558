import { combineReducers } from 'redux';
import saveInvigilatorReducer from './addInvigilator';
import getInvigilatorByIdReducer from './getInvigilatorById';
import getInvigilatorPostReducer from './getInvigilator';
import fetchInvigilatorListReducer from './fetchInvigilatorList';


const invigilatorPostReducer = combineReducers({
    saveInvigilatorReducer: saveInvigilatorReducer,
    getInvigilatorPostReducer: getInvigilatorPostReducer,
    getInvigilatorByIdReducer: getInvigilatorByIdReducer,
    fetchInvigilatorListData: fetchInvigilatorListReducer
})

export default invigilatorPostReducer