import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.getGroupSubGroupById;

export default function getGroupSubgroupReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getGroupSubgroupById = (classId: number, masterId: number) => async (dispatch) => {
  const newApiDetails = { ...apiDetails };
  newApiDetails.controllerName = newApiDetails.controllerName
    .replace("{classId}", classId.toString())
    .replace("{masterId}", masterId.toString());
  return createDefaultAction(newApiDetails, dispatch, null, "GET");
};
