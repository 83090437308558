import React, { ReactNode } from 'react'
import { connect } from 'react-redux'

import { RouteProps, withRouter, RouteComponentProps, match } from 'react-router-dom';
import * as History from 'history'

import { RootState } from '../../store/rootReducer';
import TokenService from '../AuthToken/authToken';

type Props = RouteComponentProps & RouteProps & ReduxStateProps & ReduxDispatchProps & {
    children(childProps: {currentPath: string, location?: History.Location, match: match<any>; isAuthenticated: () => boolean}): ReactNode
};
interface State {

};

/**
 * Manages user Authentication and routing processes
 */
class AuthService extends React.Component<Props, State> {
    /**
     * Checks whether user is authenticated
     */
    isAuthenticated = () => {
        // return TokenService.getAccessToken();
        return TokenService.getAccessToken();
    }

    render() {
        const {location, match, children} = this.props;

        return children({
            currentPath: location.pathname,
            location: location,
            match: match,
            isAuthenticated: this.isAuthenticated
        })
    }
}

export interface ReduxStateProps {
    loginData: string;
}

export interface ReduxDispatchProps {
}

const mapStateToProps = (state: RootState): ReduxStateProps => ({
    loginData: state.loginData.data
})

const mapDispatchToProps = (dispatch): ReduxDispatchProps => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthService))
