import { defaultState } from '../../helper/default-state';
import { defaultReducer } from '../../helper/default-reducer';
import { createDefaultAction } from '../../helper/default-action';
import { apiList } from '../../actionNames';
import { EducationLevelData } from '../../../core/Protected/Admin/MasterTabs/Education/EducationLevel';

const obj = defaultState;
const apiDetails = apiList.internal.admin.saveEducationLevel;

export default function saveEducationLevelReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const saveEducationLevelAction = (reqData: EducationLevelData) => async (dispatch) => {
    const resData = await createDefaultAction(apiDetails, dispatch, reqData, "POST");
    return resData;
}

