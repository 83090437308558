import { combineReducers } from "redux";
import districtListReducer from "./get-all-district";
import localBodyTypeListReducer from "./getAllLocalBodyType";
import provinceListReducer from "./getAllProvince";
import districtByProvinceIdReducer from "./getDistrictByProvinceId";
import localBodyByDistrictIdReducer from "./getLocalBodyByDistrictId";


const locationReducer = combineReducers({
    districtList: districtListReducer,
    provinceList: provinceListReducer,
    districtByProvinceId: districtByProvinceIdReducer,
    localBodyTypeList: localBodyTypeListReducer,
    localBodyByDistrictId: localBodyByDistrictIdReducer
})

export default locationReducer;