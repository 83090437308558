import { dataTypePhase3Table } from "core/Protected/Admin/PercentageDistributionConfiguration/ConfigurationPhase3/ConfigurationPhase3DTable";
import { apiList } from "../../../../../actionNames";
import { createDefaultAction } from "../../../../../helper/default-action";
import { defaultReducer } from "../../../../../helper/default-reducer";
import { defaultState } from "../../../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.saveTableValuePhase3;


export default function saveTableValuePhase3Reducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const saveTableValuePhase3 = (data: dataTypePhase3Table
) => async dispatch => {
  return createDefaultAction(apiDetails, dispatch, data, "POST");
};