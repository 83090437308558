import { createDefaultAction } from './../../../helper/default-action';
import { apiList } from './../../../actionNames/index';
import { defaultState } from './../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer';

const obj = defaultState
const apiDetail = apiList.internal.admin.voucher.getVoucher

export default function getVoucherReducer(store = {...obj},action) {
    const state = Object.assign({},store); 
    const actionName = apiDetail.actionName
    return defaultReducer(actionName,action,state)
}

export const getVoucherData = (masterId:any) => async dispatch => {
    const newApiDetail = {...apiDetail}
    newApiDetail.controllerName = newApiDetail.controllerName.replace("{masterId}",masterId)
    return createDefaultAction(newApiDetail,dispatch,null, "GET")
}