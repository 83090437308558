import {combineReducers} from 'redux';
import requisitionForExamSchedulingReducer from './getRequisitionForScheduling';
import skipExamSchedulingReducer from './skipExamScheduling';
import schedulingExamReducer from './schedulingExam'
import saveSchedulingExamReducer from './saveSchedulingExam'
import examSchedulingForAllApprovalReducer from './getAllExamSchedulingReview';
import reviewExamSchedulingReducer from './reviewExamScheduling';

const examSchedulingReducer = combineReducers({
  requisitionForExamScheduling: requisitionForExamSchedulingReducer,
  skipExamSchedulingData: skipExamSchedulingReducer,
  examScheduling: schedulingExamReducer,
  saveExamScheduling: saveSchedulingExamReducer,
  examSchedulingForAllApproval:examSchedulingForAllApprovalReducer,
  reviewExamSchedulingReducer:reviewExamSchedulingReducer
});

export default examSchedulingReducer;
