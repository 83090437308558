import { combineReducers } from "redux";
import lockUserVoucherReducer from './lock-user-voucher';
import getVoucherReducer from './getVoucher'
import LockUnlocVoucherkReducer from './lockUnlockVoucher';
import getVoucherEditDataReducer from './getVoucherEditData';
import getVoucherImageReducer from "./getVoucherImage";


const lockUnlockVoucherReducer = combineReducers({
  getVoucher: getVoucherReducer,
  lockUnlockVoucher: LockUnlocVoucherkReducer,
  getVoucherEdit: getVoucherEditDataReducer,
  lockUserVouchers:lockUserVoucherReducer,
  getVoucherImageData:getVoucherImageReducer
});

export default lockUnlockVoucherReducer