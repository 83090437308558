import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState
const apiDetails = apiList.internal.admin.assignExamCenter.getAssignedExamCenterById

export default function getAssignedExamCenterByIdReducer(store={...obj}, action) {
    const state = Object.assign({},store)
    const actionName = apiDetails.actionName
    return defaultReducer(actionName,action,state)
}

export const getAssignedExamCenterByIdAction = (id:any) => async(dispatch) => {
    const newApiDetails = {...apiDetails}
    newApiDetails.controllerName = newApiDetails.controllerName.replace("{id}",id)
    return createDefaultAction(newApiDetails,dispatch,null,"GET")
}