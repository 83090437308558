import { apiList } from "../../actionNames";
import { createDispatchTypes } from "../../helper/default-action-type";


export interface i18nextReduxState {
    languageType: string;
}

const i18nextActions = {
    LANG_CHANGE_INIT: 'I18N_INIT',
    LANG_CHANGE_SUCCESS: 'I18N_SUCCESS',
    LANG_CHANGE_FAIL: 'I18N_FAIL'
}

const i18nextObject: i18nextReduxState = {
    languageType: 'ne',
};

const seti18nLanguage = (language: string) => {
    try {
        return localStorage.setItem(btoa(btoa("internationalization")), btoa(btoa(language)))
    } catch (e) {

    }
}

export const geti18nLanguage = () => {
    try {
        return atob(atob(localStorage.getItem((btoa(btoa("internationalization")))) || ""));
    } catch (e) {

        return ""
    }
}

export const switchI18nLanguage = (payload: string) => async dispatch => {
    const apiDetails = apiList.local.i18n;

    // Init Dispatch Types
    const dispatchTypes = createDispatchTypes(apiDetails.actionName);

    seti18nLanguage(payload);
    return dispatch({ type: dispatchTypes.successDispatch, payload: payload });
}

export default function i18nextReducer(store: i18nextReduxState = { ...i18nextObject }, action): i18nextReduxState {
    const state = Object.assign({}, store);
    switch (action.type) {
        case i18nextActions.LANG_CHANGE_SUCCESS: {
            return {
                ...state,
                languageType: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}