import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.scrutiny.getscrutiny;

export default function getScrutinyListReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const getScrutinyList = (requestData) => dispatch => {
    const newApilist={...apiDetails}
    newApilist.controllerName=newApilist.controllerName.replace("{pageLength}",requestData.pageLength).replace("{pageNumber}",requestData.pageNumber)
    return createDefaultAction(newApilist, dispatch, null, "GET", true);
};