import {defaultState} from '../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer';
import {createDefaultAction} from '../../../helper/default-action';
import {apiList} from '../../../actionNames';

const obj = defaultState;
const apiDetails = apiList.internal.curriculumConfiguration.deletePaper;

export default function deletePaperReducer(
    store = { ...obj },
    action
) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}


export const deletePaperAction = (
    requestData: any
):any => async (dispatch) => {
    const newApiDetails = { ...apiDetails }
    newApiDetails.controllerName = newApiDetails.controllerName.replace(`{id}`, requestData)
    const responseData = await createDefaultAction(
        newApiDetails,
        dispatch,
        null,
        'DELETE',
    );
    return responseData;
};

