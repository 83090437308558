import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.examScheduling.saveExamScheduling

export default function saveSchedulingExamReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const saveSchedulingExam = (reqData:any):any => async dispatch => {
  return createDefaultAction(apiDetails,dispatch,reqData,"POST")

}