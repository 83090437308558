import {defaultState} from '../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer';
import {createDefaultAction} from '../../../helper/default-action';
import {apiList} from '../../../actionNames';

import {FilterPaperConfigData} from "../../../../core/Protected/Admin/PaperConfiguration/PaperConfiguration";

const obj = defaultState;
const apiDetails = apiList.internal.curriculumConfiguration.filterPaper;

export default function filterPaperConfigReducer(store={...obj}, action){
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const filterPaperConfig = (reqData: FilterPaperConfigData) => async (dispatch)=>{
    const newApiDetails = {...apiDetails, controllerName: apiDetails.controllerName.replace("{classId}", reqData.classId).replace("{postId}", reqData.postId).replace("{masterDivId}", reqData.masterDivId)}
    const resData = await createDefaultAction(newApiDetails,dispatch, reqData, "GET");
    return resData;
}

