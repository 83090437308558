import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.scrutiny.getIndividualUserDetails;

export default function getIndividualUserDetailsReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const getindividualUserList = (userId: any) => dispatch => {
    const newApidetails = { ...apiDetails }
    newApidetails.controllerName = newApidetails.controllerName.replace("{id}", userId)

    return createDefaultAction(newApidetails, dispatch, null, "GET", true);
};