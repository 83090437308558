import { combineReducers } from 'redux';
import getAdvtCodeDetailsReducer from './getAdvtCodeList';
import getAttendenceValuesReducer from './getAttendanceListValues';
import getDetailFromAdvtCodeReducer from './getDetailFromAdvtCode';
import getExamCenterByAdvtCodeReducer from './getExamCenterByAdvtCode';
import getExamCenterDetailsReducer from './getExamCenters';
import getGateListReducer from './getGateListValues';
import getRoomListReducer from './getRoomListValues';



const reportReducer = combineReducers({
    getAdvtCodeDetails: getAdvtCodeDetailsReducer,
    getExamCenterDetails: getExamCenterDetailsReducer,
    getAttendenceValuesReducer: getAttendenceValuesReducer,
    getGateListReducer: getGateListReducer,
    getRoomListReducer: getRoomListReducer,
    getDetailFromAdvtCodeReducer: getDetailFromAdvtCodeReducer,
    getExamCenterByAdvtCodeData: getExamCenterByAdvtCodeReducer
})
export default reportReducer