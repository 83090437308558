import { apiList } from "store/actionNames";
import { createDefaultAction } from "store/helper/default-action";
import { defaultReducer } from "store/helper/default-reducer";
import { defaultState } from "store/helper/default-state";
import { phaseTypes } from "utils/constants";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.saveDistributionPhaseOneTable

export default function saveDistributionPhaseOneTableReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export interface saveDistributionPhaseOneTableType {
  id?: number | string,
  categoryId: number,
  vacancyNumber: number,
  percentage: number,
  actualValue: number,
  decimalValue: number,
  classId?: number,
  serviceId?: number | null
}

export const saveDistributionPhaseOneTable = (
  reqData: saveDistributionPhaseOneTableType, phase: phaseTypes
) => async dispatch => {
  const newApiDetails = { ...apiDetails };
  newApiDetails.controllerName = newApiDetails.controllerName.replace("{phasetype}", phase)
  return createDefaultAction(newApiDetails, dispatch, reqData, "POST");
};