import { ResponseData } from "../../services/ApiRequest/apiRequest.model";
import { Action, DefaultState } from "../redux.model";
import { defaultState } from "./default-state";

export function defaultReducer(actionName: string, action: Action<ResponseData>, state: DefaultState): DefaultState {
    switch (action.type) {
        case actionName + "_PROGRESS": {
            return {
                ...state,
                isFetching: true,
                isFailed: false,
                status: 100,
                isSuccess: false,
            };
        }

        case actionName + "_SUCCESS": {
            return {
                ...state,
                status: action.payload.status ? 1 : 0,
                isFetching: false,
                isFailed: false,
                data: action.payload.data,
                message: action.payload.message,
                showMessage: true,
                isSuccess: true,
            };
        }

        case actionName + "_FAILURE": {
            return {
                ...state,
                status: action.payload?.status ? 1 : 0,
                isFetching: false,
                isFailed: true,
                // data: action.payload?.data,
                data: null,
                message: action.payload?.message,
                showMessage: true,
                isSuccess: false,
            };
        }

        case actionName + "_HIDE_MESSAGE": {
            return {
                ...state,
                isFetching: false,
                isFailed: false,
                showMessage: false,
            };
        }

        case actionName + "_RESET": {
            return { ...defaultState };
        }

        case actionName:
            return {
                ...state,
                status: action.payload.status ? 1 : 0,
                isFetching: false,
                isFailed: false,
                data: action.payload,
                message: action.payload.message,
                showMessage: true,
                isSuccess: false
            }

        default: {
            return state;
        }
    }
};