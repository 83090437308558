import { combineReducers } from "redux";
import { default as removeOrRestoreAdvertisementReducer, default as scrutinyAllocationByAdvertisementReducer } from "./allocateCandiateByadvertisementCode";
import allocatedCandiateDetailsReducer from "./allocatedCandiateDetails";
import allocationForScrutinyReducer from "./allocateForScrutiny";
import downloadAdvertisementDataReducer from './downloadAdvertisementReport';
import downloadScrutinyDataReducer from './downloadReport';
import finalizeScrutinyDataReducer from './finalizeScrutiny';
import scrutinyUserAdvertisementReducer from './getAdvertisementDetails';
import getAdvertisementCodeReducer, { getAdvertisementCodeWithFiscalYearListReducer } from "./getAdvetisementCode";
import { getAdvtCodeByFiscalYearReducer } from './getAdvtCodeByFiscalYear';
import allocatedCandiateWithPaginationReducer from "./getAllocatedCandidateWithPagination";
import getFiscalYearListReducer from './getFixcalYear';
import getIndividualUserDetailsReducer from './getIndividualUserDetails';
import getScrutinyDataReducer from "./getReport";
import getScrutinyAllocationListReducer from "./getScrutinyAllocationList";
import getScrutinyListReducer from "./getScrutinyList";
import individualScrutinyAllocationReducer from './individualScrutinyAllocation';
import scrutinyReallocationByAdvertisementReducer from './reallocatingUser';
import scrutinyAllocationDetailsReducer from "./scrutinyAllocationDetails";
import getScrutinyAllocationFilterReducer from './scrutinyAllocationFilter';
import getScrutinyFilterReducer from './scrutinyFilter';
import validateFormStatusReducer from "./validateFomStatus";
import reportByGenderReducer from "./reportByGender";

const scrutinyReducer = combineReducers({
    getScrutinyList: getScrutinyListReducer,
    getScrutinyAllocationList: getScrutinyAllocationListReducer,
    scrutinyAllocationDetails: scrutinyAllocationDetailsReducer,
    allocationForScrutiny: allocationForScrutinyReducer,
    scrutinyAllocationByAdvertisement: scrutinyAllocationByAdvertisementReducer,
    allocatedCandiateDetails: allocatedCandiateDetailsReducer,
    validateFormStatusReducer: validateFormStatusReducer,
    allocatedCandiateWithPagination: allocatedCandiateWithPaginationReducer,
    removeOrRestoreAdvertisement: removeOrRestoreAdvertisementReducer,
    getScrutinyData: getScrutinyDataReducer,
    getAdvertisementCode: getAdvertisementCodeReducer,
    downloadScrutinyDataExcel: downloadScrutinyDataReducer,
    scrutinyReallocationByAdvertisement: scrutinyReallocationByAdvertisementReducer,
    finalizeScrutinyDataReducer: finalizeScrutinyDataReducer,
    scrutinyUserAdvertisementData: scrutinyUserAdvertisementReducer,
    individualScrutinyAllocationReducer: individualScrutinyAllocationReducer,
    getScrutinyAllocationFilterReducer: getScrutinyAllocationFilterReducer,
    downloadAdvertisementData: downloadAdvertisementDataReducer,
    getScrutinyFilterReducer: getScrutinyFilterReducer,
    getFiscalYearList: getFiscalYearListReducer,
    getIndividualUserDetails: getIndividualUserDetailsReducer,
    getAdvertisementCodeWithFiscalYearListData: getAdvertisementCodeWithFiscalYearListReducer,
    getAdvtCodeByFiscalYear: getAdvtCodeByFiscalYearReducer,
    reportByGenderReducer: reportByGenderReducer
})

export default scrutinyReducer;