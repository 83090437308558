import { apiList } from "../../../../../actionNames";
import { createDefaultAction } from "../../../../../helper/default-action";
import { defaultReducer } from "../../../../../helper/default-reducer";
import { defaultState } from "../../../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.saveCategoryPhase4;

export interface ISaveCategory {
    id?: number
    categoryNameEn: string,
    categoryNameNp: string
}

export default function saveCategoryPhase4Reducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const saveCategoryPhase4Action = (data: ISaveCategory
) => async dispatch => {
    return createDefaultAction(apiDetails, dispatch, data, "POST");
};