import { apiList } from "../../../actionNames";
import { createDefaultAction } from "../../../helper/default-action";
import { defaultReducer } from "../../../helper/default-reducer";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.preExaminationConfig.roomAllocation.getExamCenterByAdvtCode;
export default function getExamCenterByAdvtCodeReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const getExamCenterByAdvtCodeAction = (advtCode: string) => (dispatch) => {
    const newDetails = { ...apiDetails }
    newDetails.controllerName = newDetails.controllerName.replace("{advtCode}", advtCode)
    return createDefaultAction(newDetails, dispatch, null, "POST");
};