import { defaultState } from "../../helper/default-state";
import { defaultReducer } from "../../helper/default-reducer";
import { createDefaultAction } from "../../helper/default-action";
import { apiList } from "../../actionNames";
import { LoginData } from "../../../core/Public/Login/Login";
import TokenService from "../../../services/AuthToken/authToken";
import { createDispatchTypes } from "../../helper/default-action-type";

const obj = defaultState;
const apiDetails = apiList.oauth.login;

export default function loginReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const loginAction = (requestData: LoginData) => async dispatch => {
    const loginData = await createDefaultAction(apiDetails, dispatch, requestData, "POST", true);

    if(loginData.data && loginData.data?.access_token && typeof loginData.data?.access_token === "string"){
        const dispatchTypes = createDispatchTypes(apiDetails.actionName);
        dispatch({ type: dispatchTypes.successDispatch, payload: {status: 1, data: loginData.data?.access_token}});
        
        TokenService.setToken(loginData.data.access_token);
        TokenService.setRefreshToken(loginData.data.refresh_token);
    }

    return loginData;
};