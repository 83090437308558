import {defaultState} from '../../helper/default-state';
import {defaultReducer} from '../../helper/default-reducer';
import {createDefaultAction} from '../../helper/default-action';
import {apiList} from '../.././actionNames';

const obj = defaultState;
const apiDetails = apiList.internal.admin.getClass;

export default function getClassByIdReducer(store={...obj}, action){
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action,state);
}

export const getClassByIdAction = (reqData:any):any=> async (dispatch)=>{
    const newApiDetails = {...apiDetails};
    newApiDetails.controllerName = newApiDetails.controllerName.replace('{id}',reqData.id)
    const resData = await createDefaultAction(newApiDetails, dispatch,null,"GET")
    return resData;
}
