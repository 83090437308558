import { apiList } from "../../actionNames";
import { createDefaultAction } from "../../helper/default-action";
import { defaultReducer } from "../../helper/default-reducer";
import { defaultState } from "../../helper/default-state";

/**List of available Privilege */
export enum PrivilegeModule {
    /**Requisition */
    RQ = "RQ",
    /**Percentage Distribution */
    PD = "PD",
    /**Exam Scheduling */
    ES = "ES",
    /**Publish Advertisement */
    PA = "PA",
    /**Master Configuration */
    MC = "MC",
    /**Scrutiny Allocation */
    SA = "SA",
    /**Scrutiny Candidate */
    SC = "SC",
    /**User Management */
    UM = "UM",
    /**Duplicate Admit-Card */
    DA = "DA",
    SD = "SD",
    PUM = "PUM",
    VLU = "VLU",
    LUP = "LUP",
    PM = "PM",
    /**User Exam Ban */
    UEB = "UEB",
    /**Age Limit Configuration */
    ALC = "ALC",
    VDH = "VDH",
    CPM = "CPM",
    /**Update Payment  */
    UP = "UP",
    /**Vacancy Distribution Configuration */
    VDC = "VDC",

    /**Pre-Exam Configuration */
    PEC = "PEC",
    /**Exam paper configuration */
    EXPC = "EXPC",
    /**Academic Qualification */
    AQ = "AQ",
    /**Notice */
    NO = "NO",
    /**Post Exam Configuration */
    POEC = "POEC"


}

/**List of available Privilege Actions*/
export interface Privilege {
    approve: boolean
    create: boolean
    delete: boolean
    edit: boolean
    view: boolean
    modulename: PrivilegeModule;
}

export type PrivilegeData = { [key in PrivilegeModule]: Privilege }[]

const obj = defaultState;
const apiDetails = apiList.oauth.init;

export default function initPrivilegeReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const initPrivilege = () => async dispatch => {
    const privilegeData = createDefaultAction(apiDetails, dispatch, null, "GET", true);
    return privilegeData;
};