import { FailToast } from "../../components/ToastNotifier/ToastNotifier";
import { getTextByLanguage } from "../../utils/i18n/i18n";

interface AuthTokenService {
    setToken: Function;
    getAccessToken: Function;
    getRefreshToken: Function;
    clearToken: Function;
    setRefreshToken: Function;
}


const [at, rt] = [btoa(btoa("access_token")), btoa(btoa("refresh_token"))];
let sessionExpiredLanguageCount = 0;

const encodeToken = (token: string) => {
    try {
        const tokenWithBrowserData = JSON.stringify({ tkvrt: token });
        const tokenWithBrowserDataEncoded = btoa(tokenWithBrowserData);
        const tokenWithBrowserDataEncodedSplit = [tokenWithBrowserDataEncoded.substring(0, 20), tokenWithBrowserDataEncoded.substring(20)].reverse().join("");

        return tokenWithBrowserDataEncodedSplit;

    } catch (e) {

        return token;
    }
}
const decodeToken = (token: string) => {
    if (!token) return "";

    try {
        const tokenWithBrowserDataEncodedSplit = [token.substring(0, token.length - 20), token.substring(token.length - 20)].reverse().join("");
        const tokenWithBrowserData = atob(tokenWithBrowserDataEncodedSplit);
        const { tkvrt } = JSON.parse(tokenWithBrowserData);

        return tkvrt;

    } catch (e) {


        clearToken();
        if (!sessionExpiredLanguageCount) {
            FailToast(getTextByLanguage("Your session has expired.", "तपाईको सत्रको समयावधि सकियो।"));
            sessionExpiredLanguageCount++;
        }
        return token;
    }
}


function setToken(tokenObj: any) {
    try {
        localStorage.setItem(at, process.env.NODE_ENV === "development" ? tokenObj : encodeToken(tokenObj));
    }
    catch (e) {

    }
}
function setRefreshToken(tokenObj: any) {
    try {
        localStorage.setItem(rt, process.env.NODE_ENV === "development" ? tokenObj : encodeToken(tokenObj));
    }
    catch (e) {

    }
}

function getAccessToken(): string {
    let accessToken = "";
    try {
        accessToken = process.env.NODE_ENV === "development" ? localStorage.getItem(at) : decodeToken(localStorage.getItem(at) || "");
    }
    catch (e) {

    }
    return accessToken;
}

function getRefreshToken(): string {
    let refreshToken = "";
    try {
        refreshToken = process.env.NODE_ENV === "development" ? localStorage.getItem(rt) : decodeToken(localStorage.getItem(rt) || "");
    }
    catch (e) {

    }
    return refreshToken;
}

function clearToken() {
    localStorage.removeItem(at);
    localStorage.removeItem(rt);
}

const TokenService: AuthTokenService = {
    setToken: setToken,
    getAccessToken: getAccessToken,
    getRefreshToken: getRefreshToken,
    clearToken: clearToken,
    setRefreshToken: setRefreshToken
};
export default TokenService;
