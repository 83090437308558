import { combineReducers } from "redux";
import deleteCategoryIdPhase4Reducer from "./Category/deleteCategoryPhase4";
import fetchCategoryListPhase4Reducer from "./Category/fetchCateforyPhase4";
import saveCategoryPhase4Reducer from "./Category/saveCategoryPhase4";
import deleteFormulaByIdPhase4Reducer from "./FormulaValue/deleteFormulaById";
import findAllFormulaPhase4Reducer from "./FormulaValue/findAllFormula";
import getAllHeaderFormulaPhase4Reducer from "./FormulaValue/getAllHeader";
import saveFormulaPhase4Reducer from "./FormulaValue/saveFormula";
import deleteTableValueByIdPhase4Reducer from "./TableValue/deleteTableValue";
import findAllTableValuePhase4Reducer from "./TableValue/findAllTableValue";
import saveTableValuePhase4Reducer from "./TableValue/saveTableValue";


const phaseFourReducer = combineReducers({
    findAllFormula: findAllFormulaPhase4Reducer,
    getAllHeader: getAllHeaderFormulaPhase4Reducer,
    saveFormula: saveFormulaPhase4Reducer,
    deleteFormula: deleteFormulaByIdPhase4Reducer,
    findAllTableValue4: findAllTableValuePhase4Reducer,
    saveTableValue: saveTableValuePhase4Reducer,
    deleteTableValue: deleteTableValueByIdPhase4Reducer,
    saveCategoryList: saveCategoryPhase4Reducer,
    fetchCategoryList: fetchCategoryListPhase4Reducer,
    deleteCategoryList: deleteCategoryIdPhase4Reducer,
});

export default phaseFourReducer;
