import { defaultState } from 'store/helper/default-state';
import { apiList } from 'store/actionNames';
import { defaultReducer } from 'store/helper/default-reducer';
import { createDefaultAction } from 'store/helper/default-action';
import { AppThunk } from 'store';

export interface saveMinistyRequestData {
    id: any,
    ministryOfficeName: string,
    ministyOfficeNameNp: string,
    districtId: number
}

const obj = defaultState;
const apiDetails = apiList.internal.admin.ministryConfiguration.saveMinistryByDistrict;



export default function saveMinistryByDistrictReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const saveMinistryByDistrictAction = (reqData: saveMinistyRequestData): AppThunk<Promise<any>> => async (dispatch) => {
    const resData = await createDefaultAction(apiDetails, dispatch, reqData, "POST");
    return resData;
}

