import { defaultState } from '../../helper/default-state';
import { defaultReducer } from '../../helper/default-reducer';
import { createDefaultAction } from '../../helper/default-action';
import { apiList } from '../.././actionNames';

const obj = defaultState;
const apiDetails = apiList.internal.admin.getClass;

export default function getClassReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const getClassAction = (): any => async (dispatch) => {
    const resData = await createDefaultAction(apiDetails, dispatch, null, "GET")
    return resData;
}
