import { defaultState } from "../../helper/default-state";
import { defaultReducer } from "../../helper/default-reducer";
import { createDefaultAction } from "../../helper/default-action";
import { apiList } from "../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.getLocalBodyByDistrictId

export default function localBodyByDistrictIdReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export const getLocalBodyByDistrictId = (
  districtId: string
) => async dispatch => {
  const newApiDetails = { ...apiDetails}
  newApiDetails.controllerName = newApiDetails.controllerName.concat(
     districtId
  )
    const res =  createDefaultAction(newApiDetails, dispatch, null, "GET");
    return res
};