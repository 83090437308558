import { apiList } from "../../../actionNames";
import { createDefaultAction } from "../../../helper/default-action";
import { defaultReducer } from "../../../helper/default-reducer";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.distributionLog.getDistributionLog;

export interface RequestParams {
  fiscalYear?: string,
  localBody?: string,
  ministryOffice?: string,
  district?: string,
  distributionType?: string;
  pageSize: number,
  pageNo: number
}

export default function getDistributionLogReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getDistributionLogCard = (id: number, reqParams?: RequestParams) => async (dispatch) => {
  const newApiDetails = { ...apiDetails }
  console.log(id, 'curriculumId');
  newApiDetails.controllerName = newApiDetails.controllerName
    .replace("{id}", id.toString())

  return createDefaultAction(newApiDetails, dispatch, null, "GET", true, undefined, reqParams);
};
