import { apiList } from "../../../../../actionNames";
import { createDefaultAction } from "../../../../../helper/default-action";
import { defaultReducer } from "../../../../../helper/default-reducer";
import { defaultState } from "../../../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.findallTableValuePhase3;


export default function findAllTableValuePhase3Reducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const findallTableValuePhase3 = (distributionType: string) => async dispatch => {
  const newApiDetails = { ...apiDetails }
  newApiDetails.controllerName = newApiDetails.controllerName.replace("{distributionType}", distributionType)
  return createDefaultAction(newApiDetails, dispatch, null, "GET");
};