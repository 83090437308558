import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistribution.generateAdvNumber

export default function generateAdvNumberReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;

    return defaultReducer(actionName, action, state);
}

export interface generateAdvDataType {
  requisitionId: number,
  quotaDetailsInPercentageDistributionDtoList : Array<{advertisementNo: any}>
}

export const generateAdvertisementNumber = (
  reqData:generateAdvDataType
) => async dispatch => {
    return createDefaultAction(apiDetails, dispatch, reqData, "POST", true);
};