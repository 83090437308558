import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState;
const apiDetails = apiList.internal.admin.ageLimit.findById

export default function findByIdReducer(store = { ...obj }, action) {
    const state = Object.assign({}, store)
    const actionName = apiDetails.actionName
    return defaultReducer(actionName, action, state)
}

export const findByIdAction = (classId: string, serviceId: string) => async (dispatch) => {
    const newApiDetails = { ...apiDetails }
    newApiDetails.controllerName = newApiDetails.controllerName.replace("{classId}", classId).replace("{serviceId}", serviceId)
    const res = await createDefaultAction(newApiDetails, dispatch, null, "GET")
    return res
}