import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";

const obj = defaultState;
const apiDetails = apiList.internal.admin.scrutiny.getIndividualScrutinyData;

export default function individualScrutinyAllocationReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const individualScrutinyAllocation = (data: any) => (dispatch) => {
  return createDefaultAction(apiDetails, dispatch, data, "POST");
};
