import { apiList } from './../../../actionNames/index';
import { createDefaultAction } from './../../../helper/default-action';
import { defaultState } from './../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer'

const obj = defaultState
const apiDetail = apiList.internal.admin.voucher.getVoucherEditData;

export default function getVoucherEditDataReducer(store={...obj},action) {
    const state = Object.assign({}, store);
    const actionName = apiDetail.actionName;
    return defaultReducer(actionName, action, state);
}

export const getVoucherEditData = () => async dispatch => {
    return createDefaultAction(apiDetail,dispatch, null,"GET")
}