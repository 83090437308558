import { lazy } from "react";
import { route } from "./route.model";


const ReportByGenderIndex = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/index"));
const GenderOnlyReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/GenderOnlyReport"));

const AreaReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/AreaWiseReport"));

const ProvinceWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/ProvinceWiseReport"))

const ReligionWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/ReligionWiseReport"))

const DistrictWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/DistrictWiseReport"))

const EmploymentStatusWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/EmploymentStatusWiseReport"))

const PhysicallyChallengedWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/PhysicallyChallengedWiseReport"))

const CasteWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/CasteWiseReport"))

const QuotaWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/QuotaWiseReport"))

const MaritalStatusWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/MaritalStatusWiseReport"))

const MotherTongueWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/MotherTongueWiseReport"))

const EducationQualificationWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/EducationQualificationWiseReport"))

const ClassWiseReport = lazy(() => import("core/Protected/Admin/Scrutiny/ReportByGender/ClassWiseReport"))

export const reportByGenderConfigurationConfigRoute: route[] = [
    {
        path: "/admin/scrutiny/report-by-gender-basic-configuration",
        component: ReportByGenderIndex,
        children: [
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/gender-only",
                component: GenderOnlyReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/area",
                component: AreaReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/province",
                component: ProvinceWiseReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/religion",
                component: ReligionWiseReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/district",
                component: DistrictWiseReport
            }, 
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/employment-status",
                component: EmploymentStatusWiseReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/physically-challenged",
                component: PhysicallyChallengedWiseReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/caste",
                component: CasteWiseReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/quota",
                component: QuotaWiseReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/marital-status",
                component: MaritalStatusWiseReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/mother-tongue",
                component: MotherTongueWiseReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/education-qualification",
                component: EducationQualificationWiseReport
            },
            {
                path: "/admin/scrutiny/report-by-gender-basic-configuration/class",
                component: ClassWiseReport
            }
            
        ]
    }
]