import { combineReducers } from "redux";
import  deleteGovernmentDetailsReducer  from "./deleteGovernementList";
import deleteNonGovernmentDetailsReducer from "./deleteNonGovernmentList";
import saveNonGovernmentDetailsReducer from "./saveNonGovernmentList";
import saveGovernmentDetailsReducer from "./saveGovernmentList";
import getNonGovernmentListReducer from "./getNonGovernmentList";
import getGovernmentListReducer from "./getGovernmentList";
import editNonGovernmentDetailsReducer from "./editNonGovernmentList";
import editGovernmentDetailsReducer from "./editGovenmentList";



const experienceReducer = combineReducers({
    deleteGovernmentDetails:deleteGovernmentDetailsReducer,
    deleteNonGovernmentDetails:deleteNonGovernmentDetailsReducer,
    editGovernmentDetails:editGovernmentDetailsReducer,
    editNonGovernmentDetails:editNonGovernmentDetailsReducer,
    getGovernmentList:getGovernmentListReducer,
    getNonGovernmentList:getNonGovernmentListReducer,
    saveGovernmentDetails:saveGovernmentDetailsReducer,
    saveNonGovernmentDetails:saveNonGovernmentDetailsReducer

})

export default experienceReducer;