import {defaultState} from '../../../helper/default-state';
import {createDefaultAction} from '../../../helper/default-action';
import {apiList} from '../../../../store/actionNames/index';
import {defaultReducer} from '../../../helper/default-reducer';

const obj = defaultState;
const apiDetails = apiList.external.vacancy.getPaymentValue;

export default function getPaymentAmountReducer(store = {...obj}, action){
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const getPaymentAmountAction = (reqData:any) => async(dispatch)=>{
    const resData = await createDefaultAction(apiDetails, dispatch, reqData, "POST", true);
    return resData;
}
