import {defaultState} from '../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer';
import {createDefaultAction} from '../../../helper/default-action';
import {apiList} from '../../../actionNames';

const obj = defaultState;
const apiDetails = apiList.internal.userManagement.searchUserList;

export default function searchUserListReducer(store={...obj}, action){
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const searchUserListAction = (reqData: any):any=>  (dispatch)=>{
    const newApiDetails = { ...apiDetails }
    newApiDetails.controllerName = newApiDetails.controllerName.replace(`{}` , reqData)
    const responseData =  createDefaultAction(
        newApiDetails,
        dispatch,
        null,
        "GET",
    );
    return responseData;
}
    
