import { combineReducers } from 'redux';
import addClassReducer from './AddAdminClass';
import advertisementReducer from './AdminEbpCode/advertisement';
import findAllAgeReducer from './AgeLimit/findAllAge';
import findByIdReducer from './AgeLimit/findById';
import AssigneExamCenter from './assignExamCenter';
import addCasteGroupReducer from './Caste Group/addCasteGroup';
import getCasteGroupReducer from './Caste Group/getCasteGroup';
import getAllSamuhaBargaReducer from './Caste/getAllSamuhaBarga';
import getCasteReducer from './Caste/getCaste';
import generateCombinationReducer from './CombinePost/generateCombination';
import addPaperReducer from './Curriculum Configuration/addPaper';
import deletePaperReducer from './Curriculum Configuration/deletePaper';
import filterPaperConfigReducer from './Curriculum Configuration/filterPaperConfig';
import findPaperConfigByIdReducer from './Curriculum Configuration/findPaperConfigById';
import deleteDataReducer from './deleteData';
import addDisabilityReducer from './Disability/addDisability';
import getDisabilityReducer from './Disability/getDisability';
import addDistrictReducer from './District/AddDistrict';
import getDistrictReducer from './District/GetDistrict';
import getDistrictByIdReducer from './District/GetDistrictById';
import getProvinceReducer from './District/GetProvince';
import ExamCenterReducer from './examCenter';
import examSchedulingReducer from './examScheduling';
import fullUserDetailsReducer from './FullUserDetails/userDetails';
import getClassReducer from './GetAllClass';
import quotaListReducer from './getAllQuota';
import getClassByIdReducer from './GetClassById';
import addInternalUserReducer from './Internal User Management/addInternalUser';
import addModuleReducer from './Internal User Management/addModule';
import addRoleReducer from './Internal User Management/addRole';
import addRoleModuleReducer from './Internal User Management/addRoleModule';
import getAllInternalUserReducer from './Internal User Management/getAllInternalUser';
import getAllModulesReducer from './Internal User Management/getAllModules';
import getAllRolesReducer from './Internal User Management/getAllRoles';
import getAllRolesForListingReducer from './Internal User Management/getAllRolesForListing';
import getModulesByRoleReducer from './Internal User Management/getModulesByRole';
import getRolesByIdReducer from './Internal User Management/getRolesById';
import saveInternalUserPasswordReducer from './Internal User Management/saveInternalUserPassword';
import searchModuleReducer from './Internal User Management/searchModule';
import invigilatorPostReducer from './InvigilatorPost';
import addLocalBodyTypeReducer from './Local Body Type/addLocalBodyType';
import getLocalBodyTypeReducer from './Local Body Type/getLocalBodyType';
import addLocalBodyReducer from './Local Body/addLocalBody';
import getLocalBodyByIdReducer from './Local Body/getLocalBody';
import lockUnlockReducer from './LockUnlockProfile';
import addMasterConfigReducer from './Master Configuration/addMasterConfig';
import getMasterConfigReducer from './Master Configuration/getMasterConfig';
import ministryConfigurationReducer from './MinistryConfiguration/index';
import addMotherTongueReducer from './Mother Tongue/addMotherTongue';
import getLanguageReducer from './Mother Tongue/getLanguage';
import deleteNoticeReducer from './Notice/deleteNotice';
import getAdminNoticeReducer from './Notice/getNotice';
import saveNoticeReducer from './Notice/PostNotice';
import saveOrganizationReducer from './organization/addOrganization';
import getOrganizationReducer from './organization/getOrganization';
import addPaymentReducer from './Payment/addPayment';
import getPaymentReducer from './Payment/getPayment';
import percentageDistributionReducer from './PercentageDistribution';
import percentageDistributionConfigReducer from './percentageDistrtibutionConfiguration';
import addPostReducer from './Post/AddPost';
import getPostReducer from './Post/GetAllPost';
import addQuotaReducer from './Quota/AddQuota';
import getQuotaReducer from './Quota/GetQuota';
import addReligionReducer from './Religion/addReligion';
import getReligionReducer from './Religion/getReligion';
import addMasterDataReducer from './Service/AddService';
import getMasterDivisionReducer from './Service/GetAllMasterDivision';
import getMasterDataReducer from './Service/GetService';
import addSubjectReducer from './Subject/AddSubject';
import getSubjectReducer from './Subject/GetAllSubject';
import userDetailsReducer from './userDetails/userDetails';
import vacancyDistributionGenerateReducer from './vacancyDistributionGenerate';
import lockUnlockVoucherReducer from './voucher';

const adminReducer = combineReducers({
  addClass: addClassReducer,
  getClass: getClassReducer,
  getClassById: getClassByIdReducer,
  addPost: addPostReducer,
  getPost: getPostReducer,
  addSubject: addSubjectReducer,
  getSubject: getSubjectReducer,
  addMasterData: addMasterDataReducer,
  getMasterData: getMasterDataReducer,
  percentageDistribution: percentageDistributionReducer,
  addDistrict: addDistrictReducer,
  addMotherTongue: addMotherTongueReducer,
  quotaList: quotaListReducer,
  examScheduling: examSchedulingReducer,
  getLanguageData: getLanguageReducer,
  getMasterDivision: getMasterDivisionReducer,
  addMasterConfig: addMasterConfigReducer,
  getMasterConfig: getMasterConfigReducer,
  getDistrict: getDistrictReducer,
  getDistrictById: getDistrictByIdReducer,
  getCaste: getCasteReducer,
  addReligion: addReligionReducer,
  getRelgion: getReligionReducer,
  addDisability: addDisabilityReducer,
  getDisability: getDisabilityReducer,
  deleteData: deleteDataReducer,
  addLocalBodyType: addLocalBodyTypeReducer,
  getLocalBodyType: getLocalBodyTypeReducer,
  addLocalBody: addLocalBodyReducer,
  getLocalBodyId: getLocalBodyByIdReducer,
  addCaste: addClassReducer,
  getProvince: getProvinceReducer,
  addPaper: addPaperReducer,
  filterPaperConfigData: filterPaperConfigReducer,
  getCasteGroup: getCasteGroupReducer,
  addCasteGroup: addCasteGroupReducer,
  advertisementData: advertisementReducer,
  addPayment: addPaymentReducer,
  getPayment: getPaymentReducer,
  lockUnlockData: lockUnlockReducer,
  findPaperConfigById: findPaperConfigByIdReducer,
  deletePaper: deletePaperReducer,
  getAllInternalUser: getAllInternalUserReducer,
  addInternalUser: addInternalUserReducer,
  getAllRoles: getAllRolesReducer,
  getAllModules: getAllModulesReducer,
  addModuleData: addModuleReducer,
  searchModuleData: searchModuleReducer,
  getAllRolesForListingData: getAllRolesForListingReducer,
  addRoles: addRoleReducer,
  getRolesById: getRolesByIdReducer,
  saveUserPassword: saveInternalUserPasswordReducer,
  addRoleModulesData: addRoleModuleReducer,
  getModulesByRoleData: getModulesByRoleReducer,
  addOrganization: saveOrganizationReducer,
  getOrganizations: getOrganizationReducer,
  lockUnlockVoucher: lockUnlockVoucherReducer,
  userDetailsData: userDetailsReducer,
  addQuotaReducer: addQuotaReducer,
  getQuota: getQuotaReducer,
  examCenter: ExamCenterReducer,
  invigilatorPostDetails: invigilatorPostReducer,
  assigneExamCenter: AssigneExamCenter,
  getAdminNotice: getAdminNoticeReducer,
  saveNoticeData: saveNoticeReducer,
  deleteNotice: deleteNoticeReducer,
  fullUserDetailsData: fullUserDetailsReducer,
  percentageDistributionConfigData: percentageDistributionConfigReducer,
  ministryConfigurationData: ministryConfigurationReducer,
  generateCombination: generateCombinationReducer,
  findAllAge: findAllAgeReducer,
  findByIdData: findByIdReducer,
  getAllSamuhaBargaData: getAllSamuhaBargaReducer,
  vacancyDistributionReportData: vacancyDistributionGenerateReducer
});

export default adminReducer;
