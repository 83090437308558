import { apiList } from "../../actionNames";
import { createDispatchTypes } from "../../helper/default-action-type";


export interface changePasswordReduxState { 
    enabled: boolean;
}

const changePasswordAction = {
    INIT: 'CHANGE_PASSWORD_MODAL_INIT',
    SUCCESS: 'CHANGE_PASSWORD_MODAL_SUCCESS',
    FAIL: 'CHANGE_PASSWORD_MODAL_FAIL'
}

const changePasswordObject: changePasswordReduxState = {
    enabled: false,
};

export const initChangePassword = (payload: boolean) => async dispatch => {
    const apiDetails = apiList.local.changePasswordModal;

    // Init Dispatch Types
    const dispatchTypes = createDispatchTypes(apiDetails.actionName);
    
    return dispatch({ type: dispatchTypes.successDispatch, payload: payload});
}

export default function changePasswordReducer(store: changePasswordReduxState = { ...changePasswordObject }, action): changePasswordReduxState {
    const state = Object.assign({}, store);
    switch (action.type) {
        case changePasswordAction.SUCCESS: {
            return {
                ...state,
                enabled: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}