import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { defaultState } from "../../../helper/default-state";

const obj = defaultState
const apiDetails = apiList.internal.admin.assignExamCenter.getAdvertisementList

export default function getAdvertisementListReducer(store={...obj}, action) {
    const state = Object.assign({},store)
    const actionName = apiDetails.actionName
    return defaultReducer(actionName,action,state)
}

export const getAdvertisementListAction = () => async(dispatch) => {
    return createDefaultAction(apiDetails,dispatch,null,"GET")
}