import {defaultState} from '../../../helper/default-state';
import {defaultReducer} from '../../../helper/default-reducer';
import {createDefaultAction} from '../../../helper/default-action';
import {apiList} from '../../../actionNames';

const obj = defaultState;
const apiDetails = apiList.internal.admin.notice.deleteNotice;

export default function deleteNoticeReducer(store={...obj}, action){
    const state = Object.assign({}, store);
    const actionName = apiDetails.actionName;
    return defaultReducer(actionName, action, state);
}

export const deleteNoticeAction = (id:any):any=> async (dispatch)=>{
    const newApiDetails = { ...apiDetails }
    newApiDetails.controllerName = apiDetails.controllerName.replace("{id}",id)
    const resData = await createDefaultAction(newApiDetails,dispatch, null, "DELETE");
    return resData;
}

