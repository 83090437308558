import { combineReducers } from "redux";
import getBasicInfoReducer from "./getBasicInfo";
import getPersonalDetailsReducer from "./getPersonalDetails";
import saveContactReducer from "./saveContact";
import savePersonalDetailsReducer from "./savePersonalDetails";
import getContactDetailsReducer from "./getContact"
import extraDetailsReducer from "./getExtra";
import saveExtraDetailsReducer from "./saveExtra"
import samuhaBargaReducer from "./getsamuhaBarga";
import saveSamuhaBargaReducer from "./saveSamuhaBarga";
import deleteSamuhaBargaReducer from "./deleteSamuhaBarga";
import getUserCanEditCheckReducer from "./getIfUserCanEditProfile";

const myProfileReducer = combineReducers({
  savePersonalDetailsData: savePersonalDetailsReducer,
  getPersonalDetailsData: getPersonalDetailsReducer,
  getBasicInfoData: getBasicInfoReducer,
  saveContact: saveContactReducer,
  getContactDetails: getContactDetailsReducer,
  extraDetailsData: extraDetailsReducer,
  saveExtraData: saveExtraDetailsReducer,
  samuhaBargaData: samuhaBargaReducer,
  saveSamuhaBargaData: saveSamuhaBargaReducer,
  deleteSamuhaBargaData: deleteSamuhaBargaReducer,
  getUserCanEditCheckData: getUserCanEditCheckReducer,
})

export default myProfileReducer;