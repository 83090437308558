import { combineReducers } from 'redux';
import deletePhaseOneFormulaByIdReducer from './delete-phase-one-formula-by-id';
import deletePhaseOneTableDataByIdReducer from './delete-phase-one-table-data-by-id';
import fetchAssignHeaderForPhasesReducer from './fetchAssignHeaderForPhases';
import findAllFormulaByClassIdAndServiceIdReducer from './find-all-formula-by-classId-and-serviceId';
import findAllPhaseSavedTableDataAlternateReducer from './find-all-phase-saved-table-data-alternate';
import findAllSavedTableDataByClassIdAndServiceIdReducer from './find-all-saved-table-data-by-classId-serviceId';
import getAllAsignedHeadersPhaseOneReducer from './get-all-assigned-headers-phase1-by-classId-serviceId';
import getAllFormulaHeadersReducer from './get-all-formula-header';
import getPhaseOneSavedClassAndServiceReducer from './get-phase-one-saved-class-and-service';
import getVacancyLimitPhase1ByClassServiceReducer from './get-vacancy-limit-phase1-by-class-service';
import phaseAutoGenerateTableValueReducer from './phase-auto-generate-table-value';
import saveDistributionPhaseOneReducer from './save-distribution-phase-one';
import saveDistributionPhaseOneAllReducer from './save-distribution-phase-one-all';
import saveDistributionPhaseOneTableReducer from './save-distribution-phase-one-table';
import saveVacancyLimitPhaseOneReducer from './save-vacancy-limit-phase1';

const percentageDistributionConfigReducer = combineReducers({
  saveDistributionPhaseOneData: saveDistributionPhaseOneReducer,
  saveDistributionPhaseOneAllData: saveDistributionPhaseOneAllReducer,
  getAllFormulaHeadersData: getAllFormulaHeadersReducer,
  fetchAssignHeaderForPhases: fetchAssignHeaderForPhasesReducer,
  findAllFormulaByClassIdAndServiceIdData: findAllFormulaByClassIdAndServiceIdReducer,
  deletePhaseOneFormulaByIdData: deletePhaseOneFormulaByIdReducer,
  getPhaseOneSavedClassAndServiceData: getPhaseOneSavedClassAndServiceReducer,
  getAllAsignedHeadersPhaseOneData: getAllAsignedHeadersPhaseOneReducer,
  saveDistributionPhaseOneTableData: saveDistributionPhaseOneTableReducer,
  findAllSavedTableDataByClassIdAndServiceIdData: findAllSavedTableDataByClassIdAndServiceIdReducer,
  deletePhaseOneTableDataByIdData: deletePhaseOneTableDataByIdReducer,
  getVacancyLimitPhase1ByClassServiceData: getVacancyLimitPhase1ByClassServiceReducer,
  saveVacancyLimitPhaseOneData: saveVacancyLimitPhaseOneReducer,
  phaseAutoGenerateTableValueData: phaseAutoGenerateTableValueReducer,
  findAllPhaseSavedTableDataAlternateData: findAllPhaseSavedTableDataAlternateReducer
})

export default percentageDistributionConfigReducer;