import Axios, {
    AxiosBasicCredentials, AxiosError,
    AxiosRequestConfig, AxiosResponse, CancelTokenSource,
    CancelTokenStatic,
    Method
} from "axios";

import { apiList } from "../../store/actionNames";
import TokenService from "../AuthToken/authToken";
import {
    apiDetailType, customRequestData, GrantType, RequestHeader, ResponseData,
    ResponseError
} from "./apiRequest.model";

interface RequestParam {
    [key: string]: any;
}

const basicAuth: AxiosBasicCredentials = {
    username: "clientId",
    password: "secret",
};

const getGrantType = (): GrantType => {
    return { key: "grant_type", value: "password" };
};

let isAlreadyFetchingAccessToken = false;
let apiSubscribers: ((token: string) => Promise<any>)[] = [];

function onAccessTokenFetched(access_token) {
    apiSubscribers = apiSubscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback: (token: string) => Promise<any>) {
    apiSubscribers.push(callback);
}



// Cancel a request using a cancel token.
const cancelToken: CancelTokenStatic = Axios.CancelToken;
const source: CancelTokenSource = cancelToken.source();

export const makeApiRequest = (
    apiDetails: apiDetailType,
    requestData: any,
    requestMethod: Method,
    cancelSource?: CancelTokenSource,
    params?: RequestParam
) => {

    // API URL
    const url: string = process.env.REACT_APP_SPSC_ENDPOINT as string;

    const access_token: string = TokenService.getAccessToken();

    const headers: RequestHeader = getRequestHeaders(apiDetails, access_token);
    const transformedRequestData: customRequestData = transformRequestData(
        apiDetails,
        requestData,
        basicAuth
    );


    let axiosReqParams: AxiosRequestConfig = {
        url: apiDetails.controllerName,
        method: requestMethod,
        baseURL: url,
        headers: headers,
        ...transformedRequestData,
        timeout: 60 * 3 * 1000,
        cancelToken: cancelSource ? cancelSource.token : source.token,
    };

    if (params) {
        axiosReqParams = {
            ...axiosReqParams,
            params: params,
        };
    }

    if (apiDetails.actionName === "AUTHFILE") {
        axiosReqParams.responseType = "blob";
    }

    if (apiDetails.actionName === "DOWLOAD_PUBLIC_NOTICE") {
        axiosReqParams.responseType = "blob"
    }


    Axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (
                error.response?.data?.error === "invalid_token" &&
                error.response?.config?.url !==
                url + apiList.oauth.refreshToken.controllerName
            ) {
                return await requestAccessToken(error, apiDetails);
            }
            return Promise.reject(error)
        }
    );

    return Axios.request(axiosReqParams)
        .then((response: AxiosResponse<ResponseData>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            return manageErrorResponse(error, apiDetails);
        });
};

const getRequestHeaders = (apiDetails: apiDetailType, access_token: string) => {
    let headers = {};
    switch (apiDetails.actionName) {
        case "SUBJECT-DOCUMENT-SAVE":
        case "SAVE_NOTICE":
        case "UPLOAD_DOCUMENT":
            headers = {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + access_token,
            };
            break;
        case "GET_ALL_DISTRICTS":
        case "FORGOT_PASSWORD":
        case "RESET_PASSWORD":
        case "CHANGE_PASSWORD_NO_TOKEN":
        case "REQUEST_NEW_PASSWORD":
        case "GET_ALL_NOTICE_WITHOUT_LOGIN":
        case "DOWLOAD_PUBLIC_NOTICE":
        case "REGISTER":
            headers = {
                "Content-Type": "application/json"
            };
            break;
        default: {
            headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + access_token,
            };
            break;
        }
    }
    return headers;
};

const transformRequestData = (
    apiDetails: apiDetailType,
    requestData: any,
    basicAuth: AxiosBasicCredentials
) => {
    const transformedRequestData: customRequestData = { data: requestData };
    switch (apiDetails.actionName) {
        case "LOGIN":
            const grant_type = getGrantType();
            transformedRequestData.auth = basicAuth;
            transformedRequestData.data = getFormData(requestData);
            transformedRequestData.data.append(grant_type.key, grant_type.value);
            break;
        case "SUBJECT-DOCUMENT-SAVE":
        case "SAVE_NOTICE":
        case "UPLOAD_DOCUMENT":
        case "SAVE_EDUCATION_LIST":
        case "EDIT_EDUCATION_LIST":
        case "SAVE_TRAINNING_LIST":
        case "EDIT_TRAINING_LIST":
        case "SAVE_PROFESSIONAL_LIST":
        case "EDIT_PROFESSIONAL_LIST":
        case "SAVE_NON_GOVERNMENT_LIST":
        case "EDIT_NON_GOVERNMENT_LIST":
        case "EDIT_GOVERNMENT_LIST":
        case "SAVE_GOVERNMENT_LIST":
        case "DOCUMENTS_UPLOAD":
        case "SAVE-REQUISITION":
        case "UPLOAD_VOUCHER":
        case "ADD_INTERNAL_USER":
            transformedRequestData.data = getFormData(requestData);
            break;
        default:
            transformedRequestData.data = requestData;
            break;
    }

    return transformedRequestData;
};

function getFormData(requestData: { [key: string]: any }) {
    const formData = new FormData();
    for (const data in requestData) {
        formData.append(data, requestData[data]);
    }
    return formData;
}

interface OriginalRequestResponse extends AxiosResponse {
    _retry?: boolean;
}

const requestAccessToken = (error: AxiosError, apiDetails) => {
    //work here
    const originalRequest = error.config;
    let retryOriginalRequest;

    const initAddToQueue = () => {
        if (isAlreadyFetchingAccessToken) {
            retryOriginalRequest =
                new Promise((resolve) => {
                    addSubscriber(async () => {

                        originalRequest.headers["Authorization"] = "Bearer " + TokenService.getAccessToken();
                        const originalRequestResponse: OriginalRequestResponse = await Axios(originalRequest);

                        if (originalRequestResponse) {
                            originalRequestResponse._retry = true;
                        }
                        return resolve(originalRequestResponse);
                    });
                });
        }
    };

    const responseError: any = error.response;

    if (
        responseError?.status === 401 &&
        responseError.data.error === 'invalid_token'
    ) {

        const url: string = process.env.REACT_APP_SPSC_ENDPOINT as string;


        const localRkData = TokenService.getRefreshToken();
        const requestUrl = url + apiList.oauth.refreshToken.controllerName

        if (!isAlreadyFetchingAccessToken) {

            isAlreadyFetchingAccessToken = true;
            const requestBody = new FormData()
            requestBody.append('grant_type', 'refresh_token')
            requestBody.append('refresh_token', localRkData)

            return Axios
                .request({
                    url: requestUrl,
                    method: 'POST',
                    auth: basicAuth,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: requestBody
                })
                .then(res => {

                    TokenService.setToken(res.data.access_token)
                    TokenService.setRefreshToken(res.data.refresh_token)

                    initAddToQueue();
                    isAlreadyFetchingAccessToken = false;
                    onAccessTokenFetched(res.data.access_token);
                    return retryOriginalRequest;

                    //generate previous request again taking new token
                })
                .catch(err => {

                    console.log(err, 'errorrrr')
                    return manageErrorResponse({ ...err, logout: true }, apiDetails)
                });
        }
        initAddToQueue();
        return retryOriginalRequest;
    }

    return Promise.reject(error);

};

const manageErrorResponse = (error: any, apiDetails: apiDetailType) => {
    const errorResponse: ResponseError = {
        message: "Error",
        data: null,
        status: error.code || 400,
        // noconnection: false,
        // config: error.config,
        // isAxiosError: error.isAxiosError,
    };

    errorResponse.message = error.message; // Something happened in setting up the request that triggered an Error

    error.logout && (errorResponse.logout = error.logout);
    if (error.response) {
        errorResponse.response = error.response; // The server responded with a status code and data

        if (apiDetails.actionName !== "AUTHFILE") {
            errorResponse.data = error.response.data; // The server responded with a status code and data
        }
        if (apiDetails.actionName !== "DOWLOAD_PUBLIC_NOTICE") {
            errorResponse.data = error.response.data;
        }
    } else if (error.request) {
        errorResponse.message = "Server could not be reached."; // No response was received
        errorResponse.noconnection = true;
    }

    errorResponse.config = error.config; // Request Params Configs
    errorResponse.isAxiosError = error.isAxiosError; //If Axios Error

    return errorResponse;
};
