import { AnyAction, combineReducers } from "redux";
import TokenService from "../services/AuthToken/authToken";
import advertisementReducer from "./modules/Admin/AdminEbpCode/advertisement";
import groupDetailsReducer from "./modules/Admin/GroupDetails";
import searchRoleListReducer from "./modules/Admin/Internal User Management/searchUserByRole";
import searchUserListReducer from "./modules/Admin/Internal User Management/searchUserList";
import invigilatorPostReducer from "./modules/Admin/Invigilator";
import reportReducer from "./modules/Admin/Report";
import requisitionReducer from "./modules/Admin/Requisition";
import examSchedulingReducer from "./modules/Admin/examScheduling";
import adminReducer from './modules/Admin/index';
import invigilatorAllocationReducer from "./modules/Admin/invigilatorAllocation";
import percentageDistributionLog from './modules/Admin/percentageDistributionLog';
import phaseThreeReducer from "./modules/Admin/percentageDistrtibutionConfiguration/ConfigurationPhase3";
import phaseFourReducer from "./modules/Admin/percentageDistrtibutionConfiguration/ConfigurationPhase4";
import roomAllocationReducer from "./modules/Admin/roomAllocation";
import scrutinyReducer from "./modules/Admin/scrutiny";
import lockUnlockVoucherReducer from "./modules/Admin/voucher";
import getNoticeWithoutLoginReducer from "./modules/BeforeLogin";
import dashboardReducer from "./modules/Dashboard";
import saveCandiateReducer from "./modules/Documents";
import EdcuationReducer from "./modules/Education";
import experienceReducer from "./modules/Experience";
import externalReducer from "./modules/External";
import myProfileTabReducer from "./modules/MyProfileTab/MyProfileTab";
import getProfessionalListReducer from "./modules/Professional";
import trainingReducer from "./modules/Training";
import academicQualificationReducer from "./modules/academicQualification";
import applicationStatus from "./modules/applicationStatus";
import changeUserPasswordReducer from "./modules/changepassword/changePasswordUser";
import changeUserNoTokenPasswordReducer from "./modules/changepassword/changePasswordUserNoToken";
import changePasswordReducer from "./modules/changepassword/changepassword";
import requestNewPasswordReducer from "./modules/changepassword/request-new-password";
import requestResetPasswordReducer from "./modules/changepassword/request-reset-password";
import resetPasswordReducer from "./modules/changepassword/reset-password";
import i18nextReducer from "./modules/i18n/i18n";
import initPrivilegeReducer from "./modules/init/init";
import locationReducer from "./modules/location";
import loginReducer from "./modules/login/login";
import myProfileReducer from "./modules/myProfile";
import otherReducer from "./modules/other";
import registerReducer from "./modules/register/register";


export const appReducer = combineReducers({
    i18nextData: i18nextReducer,
    adminData: adminReducer,
    loginData: loginReducer,
    registerData: registerReducer,
    locationData: locationReducer,
    educationData: EdcuationReducer,
    myProfileData: myProfileReducer,
    trainingData: trainingReducer,
    professionalData: getProfessionalListReducer,
    requisitionData: requisitionReducer,
    otherData: otherReducer,
    academicQualification: academicQualificationReducer,
    experienceData: experienceReducer,
    groupDetailsReducer: groupDetailsReducer,
    saveCandiateUpload: saveCandiateReducer,
    externalData: externalReducer,
    changePasswordData: changePasswordReducer,
    changeUserPasswordData: changeUserPasswordReducer,
    advertisementData: advertisementReducer,
    myProfileTab: myProfileTabReducer,
    requestResetPasswordData: requestResetPasswordReducer,
    resetPasswordData: resetPasswordReducer,
    requestNewPasswordData: requestNewPasswordReducer,
    dashboardDetails: dashboardReducer,
    changeUserNoTokenPasswordData: changeUserNoTokenPasswordReducer,
    scrutinyData: scrutinyReducer,
    initPrivilegeData: initPrivilegeReducer,
    searchUserList: searchUserListReducer,
    searchRoleListReducer: searchRoleListReducer,
    lockUnlockVoucherReducer: lockUnlockVoucherReducer,
    examScheduling: examSchedulingReducer,
    roomAllocationDetails: roomAllocationReducer,
    invigilatorAllocationData: invigilatorAllocationReducer,
    reportData: reportReducer,
    applicationStatus: applicationStatus,
    getNoticeWithoutLoginData: getNoticeWithoutLoginReducer,
    ConfigurationPhase3: phaseThreeReducer,
    ConfigurationPhase4: phaseFourReducer,
    invigilatorPostData: invigilatorPostReducer,
    percentageDistributionLog
});
export type RootState = ReturnType<typeof appReducer>;
type TState = ReturnType<typeof appReducer> | undefined;

export const rootReducer = (state: TState, action: AnyAction) => {
    if (action.type === "USER_LOG_OUT") {
        state = undefined;
        try {
            // TokenService.clearToken();
        } catch (err) {
            console.error("Token Removal Error", err);
        }
    }

    return appReducer(state, action);
};

export const logoutAction = () => {
    try {
        TokenService.clearToken();
    } catch (err) {
        console.error("LogOut Error", err);
    }

    return { type: "USER_LOG_OUT", payload: {} };
};
