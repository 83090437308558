import { defaultState } from "../../../helper/default-state";
import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { phaseTypes } from "utils/constants";

const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.getAllFormulaHeaders

export default function getAllFormulaHeadersReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getAllFormulaHeaders = (phase: phaseTypes
) => async dispatch => {
  const newApiDetails = { ...apiDetails };
  newApiDetails.controllerName = newApiDetails.controllerName.replace("{phasetype}", phase)
  return createDefaultAction(newApiDetails, dispatch, null, "GET");
};