import { apiList } from "store/actionNames";
import { createDefaultAction } from "store/helper/default-action";
import { defaultReducer } from "store/helper/default-reducer";
import { defaultState } from "store/helper/default-state";
import { phaseTypes } from "utils/constants";
import { FindFormulaParams } from "./find-all-formula-by-classId-and-serviceId";


const obj = defaultState;
const apiDetails = apiList.internal.admin.percentageDistributionConfig.getAllAsignedHeadersPhaseOne


export default function getAllAsignedHeadersPhaseOneReducer(store = { ...obj }, action) {
  const state = Object.assign({}, store);
  const actionName = apiDetails.actionName;

  return defaultReducer(actionName, action, state);
}

export const getAllAsignedHeadersPhaseOne = (params: FindFormulaParams, phase: phaseTypes
) => async dispatch => {
  const newApiDetails = { ...apiDetails };
  return createDefaultAction(newApiDetails, dispatch, null, "GET", true, undefined, params, { phasetype: phase });
};