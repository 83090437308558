import { defaultReducer } from "../../../helper/default-reducer";
import { createDefaultAction } from "../../../helper/default-action";
import { apiList } from "../../../actionNames";
import { defaultState } from "../../../helper/default-state";

const obj  = defaultState;
const apiDetails = apiList.internal.admin.assignExamCenter.saveTemporarly

export default function saveTemporarlyReducer(store={...obj}, action) {
    const state = Object.assign({},store)
    const actionName = apiDetails.actionName
    return defaultReducer(actionName,action, state)
} 

export const saveTemporarlyAction = (data:any) => async(dispatch) => {
    const res = await createDefaultAction(apiDetails,dispatch, data, "POST")
    return res
}